import React from "react";
import "./device-drivers.css";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const DeviceDrivers = ({data}) => {

    const deviceDrivers = data?.software?.deviceDrivers;

    if (typeof deviceDrivers === "undefined") {
        return (
            <div className="system-info-page device-drivers">
                <div className="system-overview device-drivers-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Device Drivers</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                <ContentPreloader theme="danger" forContent="each" text="Please wait while Device Drivers is being loaded..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const driversArray = deviceDrivers;

    //console.log(driversArray)

    return (
        <div className="system-info-page device-drivers">
            <div className="system-overview device-drivers-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">Device Drivers</div>
                </div>
                <div>
                    <div className="system-info-row position-relative text-orange">
                        <div className="drive-number-no-margin me-3 align-self-center system-info-th text-orange">
                            #
                        </div>
                        <div className="drive-number-no-margin device-in-loop-parts system-info-th font-italic">
                            <div className="text-orange">
                                Driver
                            </div>
                            <div className="text-orange">
                                Publisher
                            </div>
                            <div className="text-orange">
                                Version
                            </div>
                            <div className="text-orange">
                                Name
                            </div>
                            <div className="text-orange">
                                INF Name
                            </div>
                            <div className="text-orange">
                                Install Date
                            </div>
                        </div>
                    </div>
                    {driversArray && Array.isArray(driversArray) && driversArray.map((driver, driveri) =>
                        <div className="system-info-row position-relative" key={driveri}>
                            <div className="drive-number align-self-center system-info-td">
                                {driveri + 1}
                            </div>
                            <div className="device-in-loop-parts system-info-td">
                                <div className="text-success">
                                    {driver?.DeviceName}
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{driver?.DeviceName}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{driver?.DriverProviderName}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{driver?.DriverVersion}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{driver?.InfName}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{driver?.DriverDate}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DeviceDrivers;
