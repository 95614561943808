import React, {useEffect, useRef, useState} from "react";
import "./content.css";
import {Routes, Route, Link} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Tracker from "./Tracker/Tracker";
import {useDispatch, useSelector} from "react-redux";
import {FaChevronLeft} from "react-icons/fa";
import Products from "./Products/Products";
import Results from "./Results/Results";
import SystemInfo from "./Results/SystemInfoPages/SystemInfo";
import SystemOverview from "./Results/SystemInfoPages/SystemOverview/SystemOverview";
import EnabledDevices from "./Results/SystemInfoPages/EnabledDevices/EnabledDevices";
import DisabledDevices from "./Results/SystemInfoPages/DisabledDevices/DisabledDevices";
import InstalledApplications from "./Results/SystemInfoPages/InstalledApplications/InstalledApplications";
import InstalledHotfixes from "./Results/SystemInfoPages/InstalledHotfixes/InstalledHotfixes";
import DeviceDrivers from "./Results/SystemInfoPages/DeviceDrivers/DeviceDrivers";
import SystemServices from "./Results/SystemInfoPages/SystemServices/SystemServices";
import RunningProcesses from "./Results/SystemInfoPages/RunningProcesses/RunningProcesses";
import OsConfiguration from "./Results/SystemInfoPages/OsConfiguration/OsConfiguration";
import Events from "./Results/SystemInfoPages/Events/Events";
import QTW from "./Results/TestResults/QTW/QTW";
import WSTP from "./Results/TestResults/WSTP/WSTP";
import UAV from "./Results/TestResults/UAV/UAV";
import Settings from "./Settings/Settings";
import Relax from "./Relax/Relax";
import Downloads from "./Downloads/Downloads";
import Feedback from "./Feedback/Feedback";
import Guide from "./Guide/Guide";
import About from "./About/About";
import QrStyle from "./QrStyle/QrStyle";
import HealthcheckReport from "./Results/TestResults/HealthcheckReport/HealthcheckReport";
import SystemEvalReport from "./Results/TestResults/SystemEvalReport/SystemEvalReport";
import GPUTestResults from "./Results/TestResults/Interactive/GPUTestResults/GPUTestResults";
import KeyboardTestResults from "./Results/TestResults/Interactive/KeyboardTestResults/KeyboardTestResults";
import MouseTestResults from "./Results/TestResults/Interactive/MouseTestResults/MouseTestResults";
import CheckupTestResults from "./Results/TestResults/CheckupTestResults/CheckupTestResults";
import AnalysisTestResults from "./Results/TestResults/AnalysisTestResults/AnalysisTestResults";
import Devices from "./Results/Devices/Devices";
import Licenses from "./Results/Licenses/Licenses";
import Sessions from "./Results/Sessions/Sessions";
import PublicResults from "./PublicResults/PublicResults";

const Content = ({sizes, canUseSizes}) => {
    const [loading, setLoading] = useState(true);
    const globalState = useSelector(state => state?.global)
    const resultData = useSelector(state => state?.results?.result)
    const testData = useSelector(state => state?.results?.test)

    const NoMatch = () => {
        return (
            <div>
                <div className="d-flex page-main-heading mb-2">
                    <h2 className="m-0 mx-3 mt-1 text-uppercase">Page doesn't exist</h2>
                </div>
                <p className="mx-3">
                    <Link to="/" className="btn btn-light"><FaChevronLeft className="text-secondary me-3" />Go to the Dashboard</Link>
                </p>
            </div>
        );
    }

    // useEffect(() => {
    //     dispatch(sessions({
    //         quickData: {
    //             cpu: results?.result?.results?.UXD?.NEW_SYSTEM_OVERVIEW?.PROCESSOR,
    //             gpu: results?.result?.results?.UXD?.HARDWARE_INVENTORY?.GPU
    //         },
    //         result: results?.result?.results?.UXD,
    //         report: reports?.result?.results
    //     }));
    // }, []);

    useEffect(() => {
        const timeout = setTimeout(() => { setLoading(false) }, 1000);
        return () => clearTimeout(timeout);
    }, [globalState]);


    if (loading) {
        return (
            <div className="main-content main-content-loading">
                Loading...
            </div>
        )
    }

    return (
        <div className="content" style={{width: canUseSizes ? `calc(100vw - ${globalState?.asideClosed ? "132px" : "312px"})` : ""}}>

            <Routes>
                <Route path="/">
                    <Route index element={
                        <Dashboard />}
                    />
                    <Route path="/dashboard" element={
                        <Dashboard />}
                    />
                    <Route path="/qrstyle" element={
                        <QrStyle />}
                    />
                    <Route path="/about" element={
                        <About />}
                    />
                    <Route path="/tracker" element={
                        <Tracker />}
                    />
                    <Route path="/products" element={
                        <Products />}
                    />
                    <Route path="/settings" element={
                        <Settings />}
                    />
                    <Route path="/downloads" element={
                        <Downloads />}
                    />
                    <Route path="/relax" element={
                        <Relax />}
                    />
                    <Route path="/feedback" element={
                        <Feedback />}
                    />
                    <Route path="/guide/:id" element={
                        <Guide />}
                    />
                    <Route path="/results" element={
                        <Results />}
                    >
                        <Route path="/results/:licenseID" element={
                            <Devices />}
                        />
                        <Route path="/results/:licenseID/:deviceID" element={
                            <Sessions />}
                        />

                        <Route path="/results/:licenseID/:deviceID/:sessionID/*" element={
                            <SystemInfo />}
                        >
                            <Route path="qtw" element={
                                <QTW data={testData} />}
                            />
                            <Route path="wstp" element={
                                <WSTP data={testData} />}
                            />
                            <Route path="uav" element={
                                <UAV data={testData} />}
                            />
                            <Route path="analysis-results" element={
                                <AnalysisTestResults deviceData={resultData} testData={testData} />}
                            />
                            <Route path="checkup-results" element={
                                <CheckupTestResults deviceData={resultData} testData={testData} />}
                            />
                            <Route path="interactive-results" element={
                                <div>
                                    <GPUTestResults testData={testData} />
                                    <KeyboardTestResults testData={testData} />
                                    <MouseTestResults testData={testData} />
                                </div>}
                            />
                            <Route path="healthcheck" element={
                                <HealthcheckReport deviceData={resultData} testData={testData} />}
                            />
                            <Route path="system-eval" element={
                                <SystemEvalReport deviceData={resultData} testData={testData} />}
                            />
                            <Route path="system-overview" element={
                                <SystemOverview data={resultData} />}
                            />
                            <Route path="enabled-devices" element={
                                <EnabledDevices data={resultData} />}
                            />
                            <Route path="disabled-devices" element={
                                <DisabledDevices data={resultData} />}
                            />
                            <Route path="hardware-inventory" element={
                                <SystemOverview data={resultData} />}
                            />
                            <Route path="installed-applications" element={
                                <InstalledApplications data={resultData} />}
                            />
                            <Route path="installed-hotfixes" element={
                                <InstalledHotfixes data={resultData} />}
                            />
                            <Route path="device-drivers" element={
                                <DeviceDrivers data={resultData} />}
                            />
                            <Route path="system-services" element={
                                <SystemServices data={resultData} />}
                            />
                            <Route path="running-processes" element={
                                <RunningProcesses data={resultData} />}
                            />
                            <Route path="os-configuration" element={
                                <OsConfiguration data={resultData} />}
                            />
                            <Route path="application-failures" element={
                                <Events title="Application Failures" id="application-failures" data={resultData} />}
                            />
                            <Route path="unexpected-shutdowns" element={
                                <Events title="Unexpected Shutdowns" id="unexpected-shutdowns" data={resultData} />}
                            />
                        </Route>
                    </Route>
                    <Route path="/public-results/:sessionID/*" element={
                        <PublicResults />}
                    >
                        <Route path="qtw" element={
                            <QTW data={testData} />}
                        />
                        <Route path="wstp" element={
                            <WSTP data={testData} />}
                        />
                        <Route path="uav" element={
                            <UAV data={testData} />}
                        />
                        <Route path="analysis-results" element={
                            <AnalysisTestResults deviceData={resultData} testData={testData} />}
                        />
                        <Route path="checkup-results" element={
                            <CheckupTestResults deviceData={resultData} testData={testData} />}
                        />
                        <Route path="interactive-results" element={
                            <div>
                                <GPUTestResults testData={testData} />
                                <KeyboardTestResults testData={testData} />
                                <MouseTestResults testData={testData} />
                            </div>}
                        />
                        <Route path="healthcheck" element={
                            <HealthcheckReport deviceData={resultData} testData={testData} />}
                        />
                        <Route path="system-eval" element={
                            <SystemEvalReport deviceData={resultData} testData={testData} />}
                        />
                        <Route path="system-overview" element={
                            <SystemOverview data={resultData} />}
                        />
                        <Route path="enabled-devices" element={
                            <EnabledDevices data={resultData} />}
                        />
                        <Route path="disabled-devices" element={
                            <DisabledDevices data={resultData} />}
                        />
                        <Route path="hardware-inventory" element={
                            <SystemOverview data={resultData} />}
                        />
                        <Route path="installed-applications" element={
                            <InstalledApplications data={resultData} />}
                        />
                        <Route path="installed-hotfixes" element={
                            <InstalledHotfixes data={resultData} />}
                        />
                        <Route path="device-drivers" element={
                            <DeviceDrivers data={resultData} />}
                        />
                        <Route path="system-services" element={
                            <SystemServices data={resultData} />}
                        />
                        <Route path="running-processes" element={
                            <RunningProcesses data={resultData} />}
                        />
                        <Route path="os-configuration" element={
                            <OsConfiguration data={resultData} />}
                        />
                        <Route path="application-failures" element={
                            <Events title="Application Failures" id="application-failures" data={resultData} />}
                        />
                        <Route path="unexpected-shutdowns" element={
                            <Events title="Unexpected Shutdowns" id="unexpected-shutdowns" data={resultData} />}
                        />
                    </Route>
                    <Route path="*" element={
                        <NoMatch />}
                    />
                </Route>
            </Routes>
        </div>
    );
}

export default Content;


