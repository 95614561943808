import "./guide.css";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Spinner} from "react-bootstrap";

const Guide = () => {
    const params = useParams();
    const [data, setData] = useState();
    useEffect(() => {
        axios({
            method: "get",
            url: `https://uxdcloud.com/api/helpdesk-chapters/${params?.id}`,
        }).then(res => {
            console.log(res)
            setData(res?.data)
        }).catch(err => console.log(err))
    }, [])
    console.log(data)

    if (!data) {
        return <div className="mx-3 text-center h-100 position-relative">
            <div className="position-absolute top-50 start-50 translate-middle">
                <Spinner animation="border" />
            </div>
        </div>
    }

    if (data?.document && data?.document?.length === 0) {
        return <div className="">
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Guide for {params?.id}</h2>
            </div>
            <div className="alert alert-info mt-2 mx-3">
                {data?.messages && data?.messages?.map((message, messagei) =>
                        <span className={`guide-message-${messagei} me-2`}>
                    {message}
                </span>
                )}
            </div>
        </div>
    }

    return (
        <div className="guide guide-document-styles">
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Guide for {params?.id}</h2>
            </div>
            <div className="mx-3">
                {data?.chapters?.map((res, key) =>
                    <article className="user-guide-chapter" key={key}>
                        {res?.title}
                        <div>
                            {res?.sections?.map((section, isection) =>
                                <div key={section?.id + "-" + isection}>
                                    {section?.title}
                                    <div dangerouslySetInnerHTML={{__html: section?.text}} />
                                </div>
                            )}
                        </div>
                    </article>
                )}
            </div>
        </div>
    )
}

export default Guide;
