export function GoogleMap({title, address}) {
    if (!address) return <div></div>;
    return (
        <iframe
            title={title ?? "Ultra-X, Inc."}
            width="100%"
            height="500px"
            style={{border: 0}}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyARUQNWOfdS0vC2WvhYs6woFY-QgMob1CY&q=${address}`}
            placeholder={address}
        />
    );
}