import React from "react";
import {useSelector} from "react-redux";
import {FaCheck, FaInfoCircle, FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";

const GPUTestResults = ({testData}) => {
    //console.log("INTERACTIVE: ", JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.interactive?.gpu?.tests);
    let gpu = [];
    try {
        gpu = JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.interactive?.gpu;
    } catch (e) {
        return <div className="custom-info custom-info-light mb-3">
            <FaInfoCircle className="me-2" /> GPU Interactive Test was not performed
        </div>
    }

    const determineState = () => {
        console.log(gpu?.tests?.filter(gp => gp?.result === "initial"), gpu?.tests?.filter(gp => gp?.result === "initial")?.length);

        if (!gpu) return false;
        if (gpu?.tests?.filter(gp => gp?.result === "initial")?.length === gpu?.tests?.length) return false;
        return true;
    }

    if (!determineState()) {
        return (
            <div className="custom-info custom-info-light mb-3">
                <FaInfoCircle className="me-2" /> GPU Interactive Test was not performed
            </div>
        )
    }

    const result = (test) => {
        if (test?.result === "initial") return;

        return <div>
            <b className="text-secondary">{test?.title}</b>
            : {test?.result === "passed" ?
            <FaCheck className="text-success" /> :
            <FaTimes className="text-danger" />}
        </div>
    }

    return (
        <div className="mb-3">
            <div className="analysis-test-results">
                <div className="analysis-test-results-heading">
                    <div className="align-self-center m-0">GPU Test Result(s)</div>
                    <div className="align-self-center">

                    </div>
                </div>

                <hr className="mt-0" />
                {gpu && gpu?.tests.map((test, testi) =>
                    <div key={test?.title + "-" + testi}>
                        {result(test)}
                    </div>
                )}
            </div>
        </div>
    )
}

export default GPUTestResults;