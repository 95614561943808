import React from "react";
import "./enabled-devices.css";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const EnabledDevices = ({data}) => {

    const enabledDevices = data?.software?.allDevices;
    if (typeof enabledDevices === "undefined") {
        return (
            <div className="system-info-page enabled-devices-content">
                <div className="system-overview installed-applications-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Enabled Devices</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <ContentPreloader theme="danger" forContent="each" text="Please wait while Enabled Devices is being loaded..." />
                    </div>
                </div>
            </div>
        )
    }

    if (enabledDevices !== undefined && Object.keys(enabledDevices)?.length === 0) {
        return (
            <div className="system-info-page enabled-devices-content">
                <div className="system-overview installed-applications-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Enabled Devices</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div>There are no enabled devices...</div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <React.Fragment>
            <div className="system-info-page">
                <div className="system-overview enabled-devices-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Enabled Devices</div>
                    </div>
                    <div className="system-info-page-content">
                        {enabledDevices && Array.isArray(Object.keys(enabledDevices)) && Object.keys(enabledDevices).map((category) =>
                            <div className="system-info-page-content-block block-w100" key={`${category}`}>
                                <div className="system-info-page-content-block-heading">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + `/assets/images/tests/results_icons/device-manager.png`} className="me-3" alt="" />
                                    </div>
                                    <div>
                                        <div className="h22">{category}</div>
                                        <b className="text-primary">{enabledDevices[category]?.length} Devices</b>
                                    </div>
                                </div>
                                <hr />
                                {enabledDevices[category] && Array.isArray(enabledDevices[category]) && enabledDevices[category].map((device, devicei) =>
                                    <div key={`${device?.name}-${devicei}`}>
                                        <div className="system-info-row position-relative border-orange">
                                            <div className="drive-number align-self-center">
                                                {devicei + 1}
                                            </div>
                                            <div>
                                                <div className="enabled-device-description">

                                                </div>
                                                <div className="text-orange">
                                                    <b className="me-2">Name:</b>
                                                    <span className="monospaced-value">{device.Name}</span>
                                                </div>
                                                <div className="text-orange">
                                                    <b className="me-2">Description:</b>
                                                    <span className="monospaced-value">{device.Description}</span>
                                                </div>
                                                <div className="text-orange">
                                                    <b className="me-2">Status:</b>
                                                    <span className="monospaced-value">{device.Status}</span>
                                                </div>
                                                <div className="text-orange">
                                                    <b className="me-2">PNPDeviceID:</b>
                                                    <span className="monospaced-value">{device.PNPDeviceID}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EnabledDevices;