import React from "react";
import "./running-processes.css";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {formatBytes, getLocalStorageItem} from "../../../../Other/Helper";


const RunningProcesses = ({data}) => {

    const runningProcesses = data?.software?.runningProcesses;

    if (typeof runningProcesses === "undefined") {
        return (
            <div className="system-info-page running-processes">
                <div className="system-overview running-processes-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Running Processes</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                <ContentPreloader theme="danger" forContent="each" text="Please wait while Running Processes is being loaded..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const processesArray = runningProcesses;

    //console.log(processesArray)

    return (
        <div className="system-info-page running-processes">
            <div className="system-overview running-processes-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">Running Processes</div>
                </div>
                <div>
                    <div className="system-info-row position-relative text-orange">
                        <div className="drive-number-no-margin me-3 align-self-center system-info-th text-orange">
                            #
                        </div>
                        <div className="drive-number-no-margin device-in-loop-parts system-info-th font-italic">
                            <div className="text-orange">
                                Name
                            </div>
                            <div className="text-orange">
                                ID
                            </div>
                            <div className="text-orange">
                                Priority
                            </div>
                            <div className="text-orange">
                                Physical Memory Usage
                            </div>
                            <div className="text-orange">
                                Paged System Memory Size
                            </div>
                            <div className="text-orange">
                                Paged Memory Size
                            </div>
                        </div>
                    </div>
                    {processesArray && Array.isArray(processesArray) && processesArray.map((driver, driveri) =>
                        <div className="system-info-row position-relative" key={driveri}>
                            <div className="drive-number align-self-center system-info-td">
                                {driveri + 1}
                            </div>
                            <div className="device-in-loop-parts system-info-td">
                                <div className="text-success">
                                    {driver?.Name}
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{driver?.Id}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{driver?.Priority}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{formatBytes(driver?.WorkingSet64)}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{formatBytes(driver?.PagedSystemMemorySize64)}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{formatBytes(driver?.PagedMemorySize64)}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default RunningProcesses;
