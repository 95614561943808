import "./downloads.css";
import {FaApple, FaCloudDownloadAlt, FaLinux, FaWindows} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_URL} from "../../Constants";


const downloadsContentArray = [
    {
        name: "Windows",
        icon:
            <FaWindows size="35" className="me-3" />,
        downloads: [],
    },
    {
        name: "Apple",
        icon:
            <FaApple size="35" className="me-3" />,
        downloads: [],
    },
    {
        name: "Linux",
        icon:
            <FaLinux size="35" className="me-3" />,
        downloads: []
    }
]

String.prototype.hashCode = function () {
    let hash = 0,
        i,
        chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash;
};

const Downloads = () => {
    const baseURL = BASE_URL;
    const phpRoute = "downloads/";

    const [isLoading, setIsLoading] = useState(true);
    const [downloads, setDownloads] = useState(downloadsContentArray);

    useEffect(async () => {
        axios(baseURL + phpRoute,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            }
        )
            .then(res => {
                setDownloads(downloads?.map(platform => {
                    let downloadsDB = res?.data?.filter(item => item?.platform?.toLowerCase() === platform?.name?.toLowerCase())
                    return {
                        ...platform,
                        downloads: downloadsDB
                    }
                }))
            })
            .catch(err => console.log(err))
            .finally(() => setTimeout(() => setIsLoading(false), 1000));
    }, []);

    const downloadsContent = (downloads) => {
        if (downloads?.length === 0) return <div className="downloads-table-td-no-data text-center">
            <i className="text-success">There are no downloads for this platform...</i>
        </div>
        return downloads?.map((dow, dowi) =>
            <div className="downloads-table-td" key={`downloads-table-td-${dowi}`}>
                <div className="download-table-td-parent">
                    <div className="downloads-table-th-item-alt">Name</div>
                    <div className="downloads-table-td-item">{dow?.name}</div>
                </div>
                <div className="download-table-td-parent">
                    <div className="downloads-table-th-item-alt">Date</div>
                    <div className="downloads-table-td-item">{dow?.date_updated}</div>
                </div>
                <div className="download-table-td-parent">
                    <div className="downloads-table-th-item-alt">Version</div>
                    <div className="downloads-table-td-item">{dow?.version}</div>
                </div>
                <div className="download-table-td-parent">
                    <div className="downloads-table-th-item-alt">Hash</div>
                    <div className="downloads-table-td-item">{dow?.hash}</div>
                </div>
                <div className="download-table-td-parent">
                    <div className="downloads-table-th-item-alt">Action</div>
                    <div className="downloads-table-td-item">
                        <a className="btn btn-success btn-sm" target="_blank" href={dow?.link}>DOWNLOAD</a>
                    </div>
                </div>
                <div className="download-table-td-parent">
                    <div className="downloads-table-th-item-alt">Size</div>
                    <div className="downloads-table-td-item">{dow?.size}</div>
                </div>
            </div>
        )

    }

    if (isLoading) {
        return (
            <div className="downloads placeholder-glow">
                <div className="d-flex page-main-heading mb-2">
                    <h2 className="m-0 mx-3 mt-1 text-uppercase">Downloads</h2>
                </div>
                <div className="px-3">
                    <hr className="m-0" />
                    <div className="mt-3">
                        {downloads?.map((os, osi) =>
                            <div className="donwloads-section" key={`download-table-${osi}`}>
                                <h4>
                                    {os?.icon} {os?.name}
                                </h4>
                                <div className="downloads-table">
                                    <div className="downloads-table-th">
                                        <div className="downloads-table-th-item">Name</div>
                                        <div className="downloads-table-th-item">Date</div>
                                        <div className="downloads-table-th-item">Version</div>
                                        <div className="downloads-table-th-item">Hash</div>
                                        <div className="downloads-table-th-item">Action</div>
                                        <div className="downloads-table-th-item">Size</div>
                                    </div>

                                    <div className="downloads-table-td">
                                        <div className="download-table-td-parent">
                                            <div className="downloads-table-th-item-alt">Name</div>
                                            <div className="downloads-table-td-item">
                                                <div className="disabled placeholder col-7 bg-dark" />
                                            </div>
                                        </div>
                                        <div className="download-table-td-parent">
                                            <div className="downloads-table-th-item-alt">Date</div>
                                            <div className="downloads-table-td-item">
                                                <div className="disabled placeholder col-6 bg-dark" />
                                            </div>
                                        </div>
                                        <div className="download-table-td-parent">
                                            <div className="downloads-table-th-item-alt">Version</div>
                                            <div className="downloads-table-td-item">
                                                <div className="disabled placeholder col-3 bg-dark" />
                                            </div>
                                        </div>
                                        <div className="download-table-td-parent">
                                            <div className="downloads-table-th-item-alt">Hash</div>
                                            <div className="downloads-table-td-item">
                                                <div className="disabled placeholder col-6 bg-dark" />
                                            </div>
                                        </div>
                                        <div className="download-table-td-parent">
                                            <div className="downloads-table-th-item-alt">Action</div>
                                            <div className="downloads-table-td-item">
                                                <div className="btn btn-success btn-sm disabled placeholder col-6" />
                                            </div>
                                        </div>
                                        <div className="download-table-td-parent">
                                            <div className="downloads-table-th-item-alt">Size</div>
                                            <div className="downloads-table-td-item">
                                                <div className="disabled placeholder col-2 bg-dark" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="downloads">
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Downloads</h2>
            </div>
            <div className="px-3">
                <hr className="m-0" />
                <div className="mt-3">

                    {downloads?.map((os, osi) =>
                        <div className="donwloads-section" key={`download-table-${osi}`}>
                            <h4>
                                {os?.icon} {os?.name}
                            </h4>
                            <div className="downloads-table">
                                <div className="downloads-table-th">
                                    <div className="downloads-table-th-item">Name</div>
                                    <div className="downloads-table-th-item">Date</div>
                                    <div className="downloads-table-th-item">Version</div>
                                    <div className="downloads-table-th-item">Hash</div>
                                    <div className="downloads-table-th-item">Action</div>
                                    <div className="downloads-table-th-item">Size</div>
                                </div>
                                {downloadsContent(os?.downloads)}
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

export default Downloads;