import React from "react";
import "./os-configuration.css";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const OsConfiguration = ({data}) => {

    const osConfiguration = data?.software?.environmentVariables;
    if (typeof osConfiguration === "undefined") {
        return (
            <div className="system-info-page os-configuration">
                <div className="system-overview os-configuration-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Os Configuration</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                <ContentPreloader theme="danger" forContent="each" text="Please wait while OS Configurations is being loaded..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const osConfigArray = osConfiguration;

    //console.log(osConfigArray)

    return (
        <div className="system-info-page os-configuration">
            <div className="system-overview os-configuration-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">Os Configuration</div>
                </div>
                <div>
                    <div className="system-info-row position-relative text-orange">
                        <div className="drive-number-no-margin me-3 align-self-center system-info-th text-orange">
                            #
                        </div>
                        <div className="drive-number-no-margin device-in-loop-parts system-info-th font-italic">
                            <div className="text-orange">
                                Name
                            </div>
                            <div className="text-orange">
                                Details
                            </div>
                        </div>
                    </div>
                    {osConfigArray && Array.isArray(osConfiguration) && osConfigArray.map((conig, conigi) =>
                        <div className="system-info-row position-relative" key={conigi}>
                            <div className="drive-number align-self-center system-info-td">
                                {conigi + 1}
                            </div>
                            <div className="device-in-loop-parts system-info-td">
                                <div className="text-success">
                                    {conig?.Name}
                                </div>

                                <div className="text-orange">
                                    {conig?.Value && conig?.Value.split("").length > 60 ?
                                        <OverlayTrigger
                                            key="left"
                                            placement="left"
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>
                                                    <div className="text" style={{
                                                        maxWidth: "350px",
                                                        wordWrap: "break-word"
                                                    }}
                                                    >
                                                        <span className="monospaced-value">{conig?.Value}</span>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <button className="btn btn-success btn-sm">Details</button>
                                        </OverlayTrigger>
                                        :
                                        <span className="monospaced-value">{conig?.Value}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OsConfiguration;