import React from "react";
import "./disabled-devices.css";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const DisabledDevices = ({data}) => {

    const disabledDevices = data?.software?.disabledDevices;
    if (typeof disabledDevices === "undefined") {
        return (
            <div className="system-info-page disabled-devices-content">
                <div className="system-overview installed-applications-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Disabled Devices</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <ContentPreloader theme="danger" forContent="each" text="Please wait while Disabled Devices is being loaded..." />
                    </div>
                </div>
            </div>
        )
    }

    if (disabledDevices !== undefined && disabledDevices?.length === 0) {
        return (
            <div className="system-info-page disabled-devices-content">
                <div className="system-overview installed-applications-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Disabled Devices</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div>There are no disabled devices...</div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <React.Fragment>
            <div className="system-info-page">
                <div className="system-overview disabled-devices-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Disabled Devices</div>
                    </div>
                    <div>
                        {disabledDevices && Array.isArray(disabledDevices) && disabledDevices.map((device, devicei) =>
                            <div className="" key={`${device?.name}-${devicei}`}>
                                <div className="system-info-row position-relative border-orange">
                                    <div className="drive-number align-self-center">
                                        {devicei + 1}
                                    </div>
                                    <div>
                                        <div className="disabled-device-description">

                                        </div>
                                        <div className="text-orange">
                                            <b className="me-2">Name:</b>
                                            <span className="monospaced-value">{device.Name}</span>
                                        </div>
                                        <div className="text-orange">
                                            <b className="me-2">Description:</b>
                                            <span className="monospaced-value">{device.Description}</span>
                                        </div>
                                        <div className="text-orange">
                                            <b className="me-2">Status:</b>
                                            <span className="monospaced-value">{device.Status}</span>
                                        </div>
                                        <div className="text-orange">
                                            <b className="me-2">PNPDeviceID:</b>
                                            <span className="monospaced-value">{device.PNPDeviceID}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DisabledDevices;