import React, {useEffect} from "react";
import "./aside.css";
import {useSelector} from "react-redux";

import {ReactComponent as LogoLight} from "../../assets/images/uxdiscovery-logo-portal-light.svg";
import {ReactComponent as LogoDark} from "../../assets/images/uxdiscovery-logo-portal-dark.svg";
import {ReactComponent as SmallLogoLight} from "../../assets/images/logo-short-light.svg";
import {ReactComponent as SmallLogoDark} from "../../assets/images/logo-short-dark.svg";
import {FaSearch} from "react-icons/fa";

import AsideMenu from "./AsideMenu/AsideMenu";
import {BASE_URL} from "../Constants";

const baseURL = BASE_URL;

const Aside = () => {
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)

    return (
        <aside>
            <div className="aside-wrapper">
                <div className={`aside aside-closed-${globalState?.asideClosed}`}>
                    <div className="aside-header">
                        {
                            globalState?.asideClosed ?
                                globalState?.theme === "dark" ?
                                    <SmallLogoDark height="100%" width="100%" /> :
                                    <SmallLogoLight height="100%" width="100%" />
                                :
                                globalState?.theme === "dark" ?
                                    <LogoDark height="100%" width="100%" /> :
                                    <LogoLight height="100%" width="100%" />
                        }
                    </div>

                    <div className="admin-company-logo text-center py-1">

                        <img src={globalState?.asideClosed ? baseURL + "assets/images/user/company/" + loginState?.company_data?.logo : baseURL + "assets/images/user/company/" + loginState?.company_data?.logo} alt="" />
                    </div>

                    <div className="aside-content px-3">
                        <hr className="mt-0" />

                        <div className={`search-bar ${globalState?.asideClosed ? "d-none" : ""}`}>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Search..." aria-label="Menu Search..." aria-describedby="button-addon2" />
                                <button className="btn" type="button" id="button-addon2">
                                    <FaSearch />
                                </button>
                            </div>
                        </div>

                        <AsideMenu />
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Aside;