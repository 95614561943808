import React from 'react';
import "./products.css";
import {useSelector} from "react-redux";
import InnerProduct from "./innerProduct/innerProduct";

const Products = () => {
    const loginState = useSelector(state => state?.login);
    return (
        <div>
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Products</h2>
            </div>

            <div className="mx-3 mb-3">
                <div className="products-inner-product-card-hodler">
                    <InnerProduct expirationDate="N/A" altID="uxd" />
                    <InnerProduct expirationDate="N/A" altID="usbcreator" />
                    <InnerProduct expirationDate="N/A" altID="uxportal" />
                </div>
            </div>

            <div className="mx-3">
                {loginState?.product_info?.with_kit && typeof loginState?.product_info?.kit !== "undefined" ?
                    <div>
                        <h3 className={`text-${loginState?.product_info?.kit?.color}`}>
                            {loginState?.product_info?.kit?.name}
                        </h3>
                        <hr />

                        <div className="products-inner-product-card-hodler">
                            {loginState?.product_info?.with_kit && Object.values(loginState?.product_info?.with_kit).map((product, i) =>
                                <InnerProduct key={i} expirationDate={product['date_expiration']} altID={product['alt_id']} />
                            )}
                        </div>
                    </div>
                    :
                    ""
                }
            </div>

            <div className="mx-3 my-3">
                <h3 className="my-3">Individual Products</h3>
                <hr />
                <div className="products-inner-product-card-hodler">
                    {loginState?.product_info?.outside_kit && Object.values(loginState?.product_info?.outside_kit).filter(item => item.frontliner !== 0).map((product, i) =>
                        <InnerProduct key={i} expirationDate={product['date_expiration']} altID={product['alt_id']} />)}
                </div>
            </div>
        </div>
    );
}

export default Products;
