import React, {useEffect, useRef, useState} from "react";
import "./main.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header/Header";
import Aside from "./Aside/Aside";
import Footer from "./Footer/Footer";
import Content from "./Content/Content";
import {useDispatch, useSelector} from "react-redux";
import {global} from "../Redux/Actions/global";
import Login from "./Authentication/Login/Login";
import Alerts from "./Other/Alerts/Alerts";
import PlaceholderRandomizer from "./Other/PlaceholderRandomizer";
import Logout from "./Authentication/Logout/Logout";
import {useLocation, useParams} from "react-router-dom";

const Main = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global);
    const loginState = useSelector(state => state?.login);

    useEffect(() => {
        dispatch(global({
            theme: "light",
            asideClosed: false,
        }))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (globalState?.length === 0) {
            dispatch(global({
                theme: "light",
                asideClosed: false,
            }))
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState])


    const mainContainerHeight = useRef();
    const state = useSelector(state => state)
    const [sizes, setSizes] = useState({
        opened: 300,
        closed: 120
    });
    useEffect(() => {
        if (pathnames[0] === "public-results") {
            setSizes({
                opened: 0,
                closed: 0
            });
            return;
        }
        //console.log(mainContainerHeight?.current?.clientHeight, window.innerHeight)
        if (mainContainerHeight?.current?.clientHeight > window.innerHeight) {
            setSizes({
                opened: 317,
                closed: 137
            });
            return;
        }
        setSizes({
            opened: 300,
            closed: 120
        });
    }, [state]);

    const pathnames = location.pathname.split('/').filter((x) => x);
    if (pathnames[0] === "public-results") {
        return <Content sizes={sizes} canUseSizes={false} />;
    }

    if (!loginState?.logged_in) {
        return <div className="main-container">
            <Alerts />
            <Login />
        </div>
    }

    return (
        <div className={`main-container p-0 ${globalState?.theme}-theme`}>
            <div className="main-content d-flex" ref={mainContainerHeight}>
                <Logout />
                <Login />
                <PlaceholderRandomizer />
                <Alerts />
                <Aside />
                <main>
                    <Header />
                    <Content sizes={sizes} canUseSizes={true} />
                    <Footer />
                </main>
            </div>
        </div>
    )
}

export default Main;