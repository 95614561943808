import React, {useEffect, useRef, useState} from 'react';
import "./puzzle-15-timer.css";

import {GiAlarmClock} from "react-icons/gi";
import {MdPauseCircleFilled, MdPlayCircleFilled} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {puzzle15} from "../../../../../Redux/Actions/relax";

const playIcon =
    <MdPlayCircleFilled size="25" />;
const pauseIcon =
    <MdPauseCircleFilled size="25" />;

// Timer
const Timer = () => {
    const dispatch = useDispatch();
    const relaxState = useSelector(state => state?.relax)
    const puzzle15State = useSelector(state => state?.relax?.puzzle15)
    const [timer, setTimer] = useState(puzzle15State?.currentTimer ?? 0);
    const saveTimer = useRef(timer);
    const saveBoard = useRef(puzzle15State?.board);

    useEffect(() => {
        if (puzzle15State?.status === "win") {
            dispatch(puzzle15({
                ...relaxState,
                puzzle15: {
                    ...puzzle15State,
                    board: saveBoard?.current,
                    isPaused: true,
                    currentTimer: timer
                }
            }))
            setTimer(0)
        }
    }, [puzzle15State?.status]);
    useEffect(() => {
        saveTimer.current = timer;
    }, [timer]);
    useEffect(() => {
        return () => {
            dispatch(puzzle15({
                ...relaxState,
                puzzle15: {
                    ...puzzle15State,
                    board: saveBoard?.current,
                    isPaused: true,
                    currentTimer: saveTimer?.current,
                    interval: 0
                }
            }))
            clearInterval(puzzle15State?.interval)
        };
    }, []);
    useEffect(() => {
        saveBoard.current = puzzle15State?.board;
        if (typeof puzzle15State?.board === "undefined") return;
        if (typeof puzzle15State?.timer !== "undefined") return;
        dispatch(puzzle15({
            ...relaxState,
            puzzle15: {
                ...puzzle15State,
                timer: 0,
                currentTimer: 0,
                isPaused: true,
                interval: 0,
            }
        }))
    }, [puzzle15State?.board]);
    useEffect(() => {
        if (typeof puzzle15State?.isPaused === "undefined" || puzzle15State?.isPaused) return;
        const intervalID = setInterval(() => {
            setTimer(prevTime => prevTime + 1);
        }, 1000);

        dispatch(puzzle15({
            ...relaxState,
            puzzle15: {
                ...puzzle15State,
                interval: intervalID,
                timer: timer
            }
        }))

        return () => clearInterval(intervalID);
    }, [puzzle15State?.isPaused]);

    const toggleGame = () => {
        //if the game is paused, do this:
        if (puzzle15State?.isPaused) {
            dispatch(puzzle15({
                ...relaxState,
                puzzle15: {
                    ...puzzle15State,
                    isPaused: false,
                    timer: puzzle15State?.currentTimer
                }
            }))
        }

        //If the game is running, do this:
        if (!puzzle15State?.isPaused) {
            if (puzzle15State?.interval) {
                clearInterval(puzzle15State?.interval);
            }
            dispatch(puzzle15({
                ...relaxState,
                puzzle15: {
                    ...puzzle15State,
                    isPaused: true,
                    currentTimer: timer
                }
            }))
        }
    }
    const toggleGameButton = () => {
        let icon = typeof puzzle15State?.isPaused === "undefined" || puzzle15State?.isPaused ? playIcon : pauseIcon;
        let text = typeof puzzle15State?.isPaused === "undefined" || puzzle15State?.isPaused ? "Resume" : "Pause";
        if (!timer) text = "Start";

        return (
            <React.Fragment>{icon} {text}</React.Fragment>
        )
    }
    const formatTimer = (time = 0) => {
        const min = 60;
        const hour = min * 60;

        function padNumber(number) {
            return (number < 10) ? '0' + number.toString() : number.toString();
        }

        return `${padNumber(Math.floor(time / hour))}:${padNumber(Math.floor(time / min % min))}:${padNumber(time % min)}`;
    }

    return (
        <div className="puzzle15-timer">
            <div className="btn btn-sm btn-puzzle15-timer" onClick={toggleGame}>
                {toggleGameButton()}
            </div>
            <div className={`btn btn-sm puzzle15-timer-timer ${typeof puzzle15State?.isPaused === "undefined" || puzzle15State?.isPaused ? "paused" : ""}`}>
                <GiAlarmClock size="25px" /> {formatTimer(timer)}
            </div>
        </div>

    );
}

export default Timer
