import React, {useEffect, useState} from "react";
import "./user-login-sessions.css"
import axiosRetry from "axios-retry";
import axios from "axios";
import {BASE_URL} from "../../../Constants";
import {useDispatch, useSelector} from "react-redux";
import {userLoginSessions} from "../../../../Redux/Actions/about";
import LogSessionOut from "./LogSessionOut/LogSessionOut";
import moment from "moment/moment";
import {RiComputerFill} from "react-icons/ri";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const baseURL = BASE_URL;
const phpRoute = "user-active-sessions/";

const retries = 10;
const UserLoginSessions = () => {
    const dispatch = useDispatch();
    const [retried, setRetried] = useState(0);
    const loginState = useSelector(state => state?.login)
    const aboutState = useSelector(state => state?.about)
    const localData = JSON.parse(localStorage.getItem("loginData"));

    useEffect(() => {
        axiosRetry(axios, {
            retries: retries,
            shouldResetTimeout: true,
            retryCondition: (_error) => true, // retry no matter what
            retryDelay: (retryDelay) => {
                setRetried(retried => retried + 1)
                //return retryDelay * 1000;
                return 1000;
            }
        });
        axios({
                url: baseURL + phpRoute,
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                data: JSON.stringify({
                    email: loginState?.email,
                })
            }
        ).then(res => {
            if (res?.data?.error) {
                console.log("Server:", res?.data?.error)
            } else {
                dispatch(userLoginSessions({
                    userSessionData: res?.data
                }))
            }
            //console.error(res?.data);
        }).catch((err) => {
            console.log("About Retried:", retried)
            console.error("Can not login: ", err);
        })
    }, []);

    const timeDiff = (dateUpdated) => {
        let now = moment();
        let state = {du: dateUpdated, te: now}

        let du = moment(state.du);
        let te = moment(state.te);
        let diff = te.diff(du);

        let diffFormatted = moment.utc(diff).format("HH:mm:ss");
        let autoLogoutDate = moment(now).add(24, 'hours').subtract(diff);

        // console.log("#########################################");
        // console.log("Last login was " + diffFormatted + " ago at", dateUpdated)
        // console.log("Auto Logout.js date:", autoLogoutDate.format("YYYY-MM-DD HH:mm:ss"))
        // console.log(moment.utc(autoLogoutDate.diff(now)).format("HH:mm:ss"));
        return moment.utc(autoLogoutDate.diff(now)).format("HH:mm");
    }
    const FancyButton = ({children, ...props}) => (
        <div {...props}>
            {children}
        </div>
    );
    return (
        <div className="user-login-sessions">
            <div className="user-login-sessions-h">Login Sessions:</div>
            <div className="user-login-session user-login-session-table-h">
                <div className="user-login-session-number ps-2">
                    #
                </div>
                <div>
                    Session ID
                </div>
                <div>
                    Last Login
                </div>
                <div>
                    Auto logout in
                </div>
                <div>

                </div>
            </div>
            {aboutState && aboutState?.userSessionData?.map((session, i) =>
                <div className="user-login-session" key={session?.id + session?.hash}>
                    <div className="user-login-session-number ps-2">
                        {i + 1}
                        <span className="current-session">
                            {localData?.hash === session?.hash ?
                                <OverlayTrigger overlay={(
                                    <Tooltip id="this-device">This Device</Tooltip>)} placement="right"
                                >
                                    <span>
                                        <RiComputerFill />
                                    </span>
                                </OverlayTrigger> : ""}
                        </span>
                    </div>
                    <div>
                        <div className="user-login-session-table-th-item-alt">
                            Session ID
                        </div>
                        <div className="ellipsis">
                            {session?.hash}
                        </div>
                    </div>
                    <div>
                        <div className="user-login-session-table-th-item-alt">
                            Last Login
                        </div>
                        <div className="ellipsis">
                            {session?.date_updated}
                        </div>
                    </div>
                    <div>
                        <div className="user-login-session-table-th-item-alt">
                            Auto logout in
                        </div>
                        <div className="ellipsis">
                            {timeDiff(session?.date_updated)}
                        </div>
                    </div>
                    <div>
                        <div></div>
                        <LogSessionOut hash={session?.hash} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default UserLoginSessions;