import React, {useEffect, useState} from "react";
import "./tagline.css";
import {global} from "../../../../Redux/Actions/global";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {TaglineUpdate} from "../../../../Redux/Actions/settings";
import axios from "axios";
import {BASE_URL} from "../../../Constants";

const baseURL = BASE_URL;
const phpRoute = "uportal-settings/";

const Tagline = () => {
    const dispatch = useDispatch();
    const settingsState = useSelector(state => state?.settings);
    const loginState = useSelector(state => state?.login);
    const globalState = useSelector(state => state?.global);
    const [loading, setLoading] = useState(false);

    const [taglineStatus, setTaglineStatus] = useState(false);

    useEffect(() => {
        if (!taglineStatus) return;
        const timeout = setTimeout(() => {
            setLoading(false);
            dispatch(global({
                alerts: [
                    ...globalState?.alerts ?? "",
                    {
                        id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                        type: settingsState?.tagline_update_color,
                        title: "Tagline",
                        message: settingsState?.tagline_update_message,
                        show: true,
                        dismissible: true,
                        date: new Date(),
                    }
                ]
            }))
            setTaglineStatus(false)
        }, 2000);
        return () => clearTimeout(timeout);
    }, [taglineStatus]);

    const handleTagline = (e) => {
        dispatch(TaglineUpdate({
            tagline: e?.target?.value
        }));
    }
    const updateTagline = () => {
        setLoading(true)
        axios({
            method: 'post',
            url: baseURL + phpRoute + "?lang=" + localStorage.getItem("language"),
            data: JSON.stringify({
                email: loginState?.email,
                type: "update_tagline",
                tagline_body: settingsState?.tagline
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => {
            dispatch(TaglineUpdate({
                tagline_update_message: res?.data?.message,
                tagline_update_color: res?.data?.color
            }));
        }).finally(() => {
            setTaglineStatus(true);
        })
    }

    return (
        <div className="tagline">
            <textarea className="form-control" id="tagline" onChange={handleTagline} value={settingsState?.tagline ? settingsState?.tagline : ""} />
            <button className={`btn btn-secondary`} onClick={updateTagline} disabled={!!loading}>
                {loading ?
                    <Spinner animation="grow" variant="light" size="sm" /> : ""} Save Tagline {loading ?
                <Spinner animation="grow" variant="light" size="sm" /> : ""}
            </button>
            <div id="emailHelp" className="form-text text-muted tagline-explanation">
                <span className="fst-italic mx-1">If left empty, it will say "Hello {settingsState?.full_name ? settingsState?.full_name.split(" ")[0] : "unknown"}"</span>
            </div>
        </div>
    )
}

export default Tagline;
