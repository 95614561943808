import globalReducer from './global';
import TrackerReducer from "./tracker";
import dashboardReducer from "./dashboard";
import loginReducer from "./login";
import productsReducer from "./products";
import resultsReducer from "./results";
import settingsReducer from "./settings";
import relaxReducer from "./relax";
import {combineReducers} from 'redux';
import aboutReducer from "./about";

const allReducers = combineReducers({
    global: globalReducer,
    tracker: TrackerReducer,
    dashboard: dashboardReducer,
    login: loginReducer,
    products: productsReducer,
    results: resultsReducer,
    settings: settingsReducer,
    relax: relaxReducer,
    about: aboutReducer
});

// export default allReducers;
//To clear whole store on logout we added rootReducer below
const rootReducer = (state, action) => {
    if (action.type === 'SET_LOGOUT_STATUS') {
        return allReducers(undefined, action)
    }

    return allReducers(state, action)
}
export default rootReducer;