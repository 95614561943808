import "./header-drop-down.css";

const HeaderDropDown = ({text}) => {
    return (
        <div className="header-drop-down">
            {text}
        </div>
    )
}

export default HeaderDropDown;