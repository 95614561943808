import React from "react";
import {FaCheck, FaInfoCircle, FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const KeyboardTestResults = ({testData}) => {
    //console.log("INTERACTIVE: ", JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.interactive?.keyboard);
    let keyb = [];
    try {
        keyb = JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.interactive?.keyboard;
    } catch (e) {
        return <div className="custom-info custom-info-light mb-3">
            <FaInfoCircle className="me-2" /> Keyboard Interactive Test was not performed
        </div>
    }

    if (!keyb) {
        return (
            <div className="custom-info custom-info-light mb-3">
                <FaInfoCircle className="me-2" /> Keyboard Interactive Test was not performed
            </div>
        )
    }
    console.log(keyb?.types?.find(item => item?.results?.length > 0)?.finalResult)
    return (
        <div className="mb-3">
            <div className="analysis-test-results">
                <div className="analysis-test-results-heading mb-3">
                    <div className="align-self-center m-0">Keyboard Test Result(s)</div>
                </div>
                <div>
                    <b className="text-secondary">
                        Keyboard Test:
                    </b>
                    {keyb?.types?.find(item => item?.results?.length > 0)?.finalResult === "passed" ?
                        <FaCheck className="text-success" /> :
                        <FaTimes class="text-danger" />}
                </div>
            </div>
        </div>
    )
}

export default KeyboardTestResults;