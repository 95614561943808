const globalReducer = (state = [], action) => {
    switch (action.type) {
        case 'DASHBOARD':
            return {
                ...state, ...action.data
            }
        case 'CHART':
            return {
                ...state, ...action.data
            }
        case 'DASH_TASK_PRIORITY_LIST':
            return {
                ...state, ...action.data
            }
        default:
            return state;
    }
};

export default globalReducer;