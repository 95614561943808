import React, {useEffect, useState} from "react";
import "./avatar.css"

const Avatar = ({user, showName = true}) => {
    const [userData, setUserData] = useState();
    const [color, setColor] = useState("");

    useEffect(() => {
        if (userData) return;
        setUserData(user);
        setColor("#" + Math.floor(Math.random() * 16777215).toString(16));
    }, [user]);


    const avatar = () => {
        if (!userData?.admin_id && !userData?.settings) {
            return <span className="user-avatar-badge badge bg-secondary">
                N/A
            </span>
        }

        let settings = JSON.parse(userData?.settings);

        if (!settings?.avatar) {
            return <div className="user-avatar user-avatar-text text-uppercase" style={{backgroundColor: color, color: getContrastYIQ(color)}}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    {avatarInitials(userData?.full_name)}
                </div>
            </div>;
        }

        return <div className="user-avatar align-self-center" style={{backgroundImage: `url(${settings?.avatar})`}} />
    }


    const avatarInitials = (name) => {
        let array = name.split(" ");
        let newString = "";
        for (let i = 0; i <= array?.length - 1; i++) {
            newString += array[i][0];
        }
        return newString;
    }

    const getContrastYIQ = (hexcolor) => {
        hexcolor = hexcolor.replace("#", "");
        let r = parseInt(hexcolor.substring(0, 2), 16);
        let g = parseInt(hexcolor.substring(2, 2), 16);
        let b = parseInt(hexcolor.substring(4, 2), 16);
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    return (
        <React.Fragment>
            <div className="text-capitalize">
                {avatar(userData)}
            </div>
            <div>
                {showName ? userData?.full_name : ""}
            </div>
        </React.Fragment>
    )
}

export default Avatar;