import React, {useEffect, useState} from "react";
import "./sessions.css";
import {ImQrcode} from "react-icons/im";
import {MdOutlineCopyAll} from "react-icons/md";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Link, useParams} from "react-router-dom";
import {breadcrumbs, licenses, sessions} from "../../../../Redux/Actions/results";
import {BASE_URL} from "../../../Constants";
import {global, placeholderRandomizer} from "../../../../Redux/Actions/global";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import DownloadQr from "./QRCode/DownloadQr/DownloadQr";
import {PiClockCountdownDuotone} from "react-icons/pi";

const baseURL = BASE_URL;
const phpRoute2 = "device-sessions/";

const SessionsList = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)
    const resultsState = useSelector(state => state?.results)
    const {licenseID, deviceID} = useParams();

    const retrieveData = () => {
        axios({
            method: 'post',
            url: baseURL + phpRoute2 + "?lang=en",
            data: JSON.stringify({
                email: loginState?.email,
                device_id: resultsState?.breadcrumbs?.selectedDevice,
                filter: resultsState?.filters
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => {
            console.log("SESSION LIST: ", res.data)
            dispatch(sessions({sessions: res?.data}));
        }).catch(err => console.log(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (resultsState?.filters) return;
        dispatch(licenses({
            filters: {
                ordering: "orderByDateDesc",
                filter: "all"
            }
        }));
    }, []);

    const sessionsMajorTestsRandomizer = () => {
        let tests = [];
        let testTypes = ["warning", "success", "danger"]
        let amount = Math.round(Math.random() * 2)
        for (let i = 0; i <= amount; i++) {
            tests.push({
                id: i,
                type: testTypes[Math.round(Math.random() * 2)]
            });
        }
        return tests;
    }
    const sessionsArrayRandomizer = (min, max) => {
        let array = [];
        for (let i = min; i <= max; i++) {
            array.push({
                id: i,
                tests: sessionsMajorTestsRandomizer()
            })
        }
        return array;
    }

    const filterDevicesCount = () => {
        try {
            return resultsState?.devices?.find((device) => device?.id === resultsState?.breadcrumbs?.selectedDevice)?.count
        } catch (e) {
            return 3;
        }
    }

    useEffect(() => {
        dispatch(placeholderRandomizer({
            placeholderRandomizer: {
                ...globalState?.placeholderRandomizer,
                sessions: sessionsArrayRandomizer(1, filterDevicesCount(), 3, 3, 8),
            }
        }))

        if (!resultsState?.breadcrumbs?.selectedDevice) return;
        setLoading(true)
        let timeout = setTimeout(() => {
            retrieveData();
        }, 1000);
        return () => clearTimeout(timeout);
    }, [resultsState?.breadcrumbs?.selectedDevice, resultsState?.filters])

    const onCopyUrl = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "success",
                    title: "Clipboard",
                    message: "Public URL was copied to clipboard!",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }

    const sessionsListPreLoader = () => {
        let sessions = globalState?.placeholderRandomizer?.sessions;
        return sessions && sessions?.map((session, isession) =>
            <div className="d-flex align-items-center justify-content-between sessions-list-session-row" key={isession}>
                <div className="sessions-list-session-items sessions-list-session-items-numbers">
                    {isession + 1}
                </div>
                <div className="">
                    <PiClockCountdownDuotone size={25} />
                </div>
                <div className="sessions-list-session-items sessions-list-session-items-qr">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="sessions-list-session-items sessions-list-session-items-public-url">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="sessions-list-session-items">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="sessions-list-session-items sessions-list-session-items-global-status">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="sessions-list-session-items">
                    <div className="sessions-list-major-tests">
                        {session?.tests?.map(item =>
                            <div key={item?.id} className={`btn btn-${item?.type} btn-sm disabled placeholder`} style={{width: "33.33%"}} />
                        )}
                    </div>
                </div>
                <div className="sessions-list-session-items">
                    <a href="#" tabIndex="-1" className="btn btn-secondary btn-sm  disabled placeholder container-fluid" />
                </div>
            </div>
        )
    }
    const sessionsList = () => {
        return typeof resultsState?.sessions !== "undefined" && resultsState?.sessions?.map((session, isession) =>
            <div className="d-flex align-items-center justify-content-between sessions-list-session-row" key={isession}>
                <div className="sessions-list-session-items sessions-list-session-items-numbers">
                    {isession + 1}
                </div>
                <div className="">
                    <PiClockCountdownDuotone size={25} />
                </div>
                <div className="sessions-list-session-items sessions-list-session-items-qr">
                    <DownloadQr hash={session?.id} />
                </div>
                <div className="sessions-list-session-items sessions-list-session-items-public-url">
                    <CopyToClipboard text={`https://portal.uxd.com/public-results/${session?.id}/system-overview`} onCopy={onCopyUrl}>
                        <div className="copy-clipboard">
                            Public URL <MdOutlineCopyAll size="23" />
                        </div>
                    </CopyToClipboard>
                </div>
                <div className="sessions-list-session-items">
                    {session?.date_generated}
                </div>
                {/*<div className="sessions-list-session-items sessions-list-session-items-global-status">*/} {/*    {globalStatus(session)}*/} {/*</div>*/} {/*<div className="sessions-list-session-items">*/} {/*    {majorTests(session)}*/} {/*</div>*/}
                <div className="sessions-list-session-items">
                    <Link onClick={() => dispatch(breadcrumbs({
                        breadcrumbs: {
                            ...resultsState?.breadcrumbs,
                            selectedSession: session?.id
                        }
                    }))} className="btn btn-secondary btn-sm container-fluid"
                          to={`/results/${licenseID}/${deviceID}/${session?.id}/system-overview`}
                    >Details </Link>
                </div>
            </div>
        )
    }

    return (
        <div className="sessions-list">
            <div className="mx-3 sessions-list-inner">
                <div className="d-flex align-items-center justify-content-between sessions-list-session-row sessions-list-session-row-head">
                    <div className="sessions-list-session-items sessions-list-session-items-numbers">
                        #
                    </div>
                    <div className="">
                        Icon
                    </div>
                    <div className="sessions-list-session-items sessions-list-session-items-qr">
                        QR
                    </div>
                    <div className="sessions-list-session-items sessions-list-session-items-public-url">
                        Public Url
                    </div>
                    <div className="sessions-list-session-items">
                        Date Created
                    </div>
                    {/*<div className="sessions-list-session-items sessions-list-session-items-global-status">*/} {/*    Global Status*/} {/*</div>*/} {/*<div className="sessions-list-session-items">*/} {/*    Major Test(s) Performed*/} {/*</div>*/}
                    <div className="sessions-list-session-items">
                        Actions
                    </div>
                </div>
                {loading && resultsState?.breadcrumbs?.selectedLicense ? sessionsListPreLoader() : sessionsList()}
            </div>
        </div>
    )
}

export default SessionsList;