const resultsReducer = (state = [], action) => {
    switch (action.type) {
        case 'RESULTS_MENU':
            return {
                ...state, ...action.data
            }
        case 'LICENSES':
            return {
                ...state, ...action.data
            }
        case 'PULL_DEVICE_DATA':
            return {
                ...state, ...action.data
            }
        case 'PULL_REPORTS_DATA':
            return {
                ...state, ...action.data
            }
        case 'PULL_SESSION_DATA':
            return {
                ...state, ...action.data
            }
        case 'REPORT':
            return {
                ...state, ...action.data
            }
        case 'BREADCRUMBS':
            return {
                ...state, ...action.data
            }
        default:
            return state;
    }
};

export default resultsReducer;