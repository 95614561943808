export const initSettings = (InitData) => {
    return {
        type: 'INIT_UPORTAL_SETTINGS',
        data: InitData
    };
};

export const TaglineUpdate = (TaglineUpdate) => {
    return {
        type: 'UPDATE_TAGLINE',
        data: TaglineUpdate
    };
};

export const LogoUpdate = (LogoUpdate) => {
    return {
        type: 'UPDATE_LOGO',
        data: LogoUpdate
    };
};