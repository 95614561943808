export const HandleInputs = (e) => {
    switch (e?.target?.name) {
        case 'email':
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return {
                name: e?.target?.name,
                value: e?.target?.value,
                valid: re.test(e?.target?.value)
            };
        case 'password':
            return {
                name: e?.target?.name,
                value: e?.target?.value,
                valid: e?.target?.value?.length >= 4
            };
        case 'remember':
            return {
                name: e?.target?.name,
                value: e?.target?.checked ? "checked" : "unchecked",
                valid: e?.target?.checked
            };
        default:
            return {
                name: e?.target?.name,
                value: e?.target?.value,
                valid: e?.target?.value
            };
    }
}