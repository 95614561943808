import React, {useEffect, useState} from "react";
import "./licenses.css";
import {BASE_URL} from "../../../Constants";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {FaTimes} from "react-icons/fa";
import {breadcrumbs, licenses} from "../../../../Redux/Actions/results";
import {BsKeyFill} from "react-icons/bs";
import Avatar from "../../../Other/Avatar/Avatar";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {placeholderRandomizer} from "../../../../Redux/Actions/global";
import {Link, useParams} from "react-router-dom";
import {decodeID, encodeID} from "../../../Other/Helper";

const baseURL = BASE_URL;
const phpRoute = "user-licenses/";
const phpCountRoute = "user-licenses-count/";
const Licenses = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)
    const licensesState = useSelector(state => state?.results?.licenses)
    const resultsState = useSelector(state => state?.results)
    const [needle, setNeedle] = useState("");
    const [loading, setLoading] = useState(true);
    const {licenseID} = useParams();

    const licenseCols = (cols, colMin = 1, colMax = 8) => {
        let colsArray = [];
        for (let c = 1; c <= cols; c++) {
            colsArray.push({
                length: Math.round(Math.random() * (colMax - colMin) + colMin),
            })
        }
        return colsArray;
    }
    const licenseArrayRandomizer = (min, max, cols, colMin = 1, colMax = 8) => {
        let array = [];
        for (let i = min; i <= max; i++) {
            array.push({
                id: i,
                cols: licenseCols(cols, colMin, colMax)
            })
        }
        return array;
    }
    const retrieveCountData = () => {
        axios({
            method: 'post',
            url: baseURL + phpCountRoute + "?lang=en",
            data: JSON.stringify({
                email: loginState?.email
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => {
            //console.log(res?.data)
            dispatch(placeholderRandomizer({
                placeholderRandomizer: {
                    ...globalState?.placeholderRandomizer,
                    licenses: licenseArrayRandomizer(1, res?.data?.license_count, 3, 3, 8),
                }
            }))
        })
            .catch(err => console.log(err))
            .finally(() => {
                let timeout = setTimeout(() => {
                    retrieveData();
                }, 1000);
            })
    }

    const retrieveData = () => {
        axios({
            method: 'post',
            url: baseURL + phpRoute + "?lang=en",
            data: JSON.stringify({
                email: loginState?.email
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => dispatch(licenses({
            licenses: res.data
        })))
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        retrieveCountData();
        setLoading(true)
    }, []);


    const handleChange = (e) => {
        setNeedle(e?.target?.value)
    }

    if (loading) {
        return (
            <div className="licenses placeholder-glow">
                <div className={`licenses-license-list-holder`}>
                    <div className={`licenses-search-bar`}>
                        <div className="input-group mb-3">
                            <input onChange={e => handleChange(e)} type="text" className="form-control" value={needle} placeholder="Search..." aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button className="btn" type="button" id="button-addon2" onClick={() => setNeedle("")}>
                                Clear <FaTimes />
                            </button>
                        </div>
                    </div>
                    <small className="licenses-licenses-left">
                        <span className="placeholder col-1" />
                    </small>
                    <div className={`licenses-filtered `}>
                        <div className={`licenses-filtered-license licenses-filtered-license-heading d-flex gap-5`}>
                            <div className="licenses-license-content licenses-license-numbers-fixed-size fst-italic fw-bold">
                                #
                            </div>
                            <div className="licenses-license-content licenses-license-icons-fixed-size">
                                <BsKeyFill size="25" />
                            </div>
                            <div className="licenses-license-content licenses-license-usage-fixed-size">Usage</div>
                            <div className="licenses-license-content">Name</div>
                            <div className="licenses-license-content">Admin</div>
                            <div className="licenses-license-content">Actions</div>
                        </div>

                        {globalState?.placeholderRandomizer?.licenses && globalState?.placeholderRandomizer?.licenses?.map((license, ilicense) =>
                            <div className={`licenses-filtered-license d-flex gap-5`} key={ilicense}>

                                <div className="licenses-license-content  licenses-license-numbers-fixed-size fst-italic fw-bold">
                                    {ilicense + 1}
                                </div>
                                <div className="licenses-license-content  licenses-license-icons-fixed-size">
                                    <BsKeyFill size="25" />
                                </div>
                                {license?.cols?.map((col, icol) =>
                                    <React.Fragment key={icol}>
                                        <div className={`licenses-license-content ${icol === 0 ? "licenses-license-usage-fixed-size" : ""}`}>
                                            <span className={`placeholder col-${col?.length}`} />
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="licenses-license-content d-flex gap-1 align-items-lg-start">
                                    <div className="btn btn-primary disabled placeholder col-6" />
                                    <div className="btn btn-warning disabled placeholder col-6" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="licenses">
            <div className={`licenses-license-list-holder`}>
                <div className={`licenses-search-bar`}>
                    <div className="input-group mb-3">
                        <input onChange={e => handleChange(e)} type="text" className="form-control" value={needle} placeholder="Search..." aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn" type="button" id="button-addon2" onClick={() => setNeedle("")}>
                            Clear <FaTimes />
                        </button>
                    </div>
                </div>
                <small className="licenses-licenses-left">
                    Licenses Used
                    <span className="ms-2 fw-bold">
                        {loginState?.product_info?.allowed_usbs_left}/{loginState?.product_info?.allowed_usbs_total}
                    </span>
                </small>
                <div className={`licenses-filtered `}>
                    <div className={`licenses-filtered-inner`}>
                        <div className={`licenses-filtered-license licenses-filtered-license-heading d-flex gap-5`}>
                            <div className="licenses-license-content licenses-license-numbers-fixed-size fst-italic fw-bold">
                                #
                            </div>
                            <div className="licenses-license-content licenses-license-icons-fixed-size">
                                <BsKeyFill size="25" />
                            </div>
                            <div className="licenses-license-content licenses-license-usage-fixed-size">Usage</div>
                            <div className="licenses-license-content">Name</div>
                            <div className="licenses-license-content">Admin</div>
                            <div className="licenses-license-content">Actions</div>
                        </div>

                        {licensesState && Array.isArray(licensesState) && licensesState?.filter(license => license?.custom_name?.toLowerCase().includes(needle?.toLowerCase()) || license?.usb_id?.toLowerCase()?.includes(needle?.toLowerCase()))?.map((license, ilicense) =>
                            <Link className={`licenses-filtered-license d-flex gap-5 ${resultsState?.breadcrumbs?.selectedLicense === license?.license_hash ? "licenses-selected-license" : ""}`} key={ilicense} onClick={() => dispatch(breadcrumbs({
                                breadcrumbs: {selectedLicense: license?.license_hash}
                            }))}
                                  to={`/results/${encodeID(license?.id, 10)}`}
                            >
                                <div className="licenses-license-content licenses-license-numbers-fixed-size fst-italic fw-bold">
                                    {ilicense + 1}
                                </div>
                                <div className="licenses-license-content licenses-license-icons-fixed-size">
                                    <BsKeyFill size="25" />
                                </div>
                                <div className="licenses-license-content licenses-license-usage-fixed-size">
                                    {license?.usage ?? 0}
                                </div>
                                <div className="licenses-license-content">
                                    {license?.custom_name}
                                </div>
                                <div className="licenses-license-content d-flex align-items-start gap-1">
                                    <Avatar user={license?.admin} />
                                </div>
                                <div className="licenses-license-content d-flex gap-1">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip placement="top">
                                                <div>Not Allowed</div>
                                            </Tooltip>}
                                    >
                                        <div className="btn btn-primary col-6 ellipsis">Assign Admin</div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip placement="top">
                                                <div>Not Allowed</div>
                                            </Tooltip>}
                                    >
                                        <div className="btn btn-warning col-6 ellipsis">Deactive</div>
                                    </OverlayTrigger>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Licenses;