import React from "react";
import UserLoginSessions from "./UserLoginSessions/UserLoginSessions";


const About = () => {
    return (
        <div>
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">About</h2>
            </div>

            <div className="mx-3">
                <UserLoginSessions />
            </div>
        </div>
    )
}

export default About;