import React, {useEffect, useState} from "react";
import "./relax-win.css";
import {useDispatch, useSelector} from "react-redux";
import {puzzle15} from "../../../../Redux/Actions/relax";

const RelaxWin = () => {
    const dispatch = useDispatch();
    const relaxState = useSelector(state => state?.relax);
    const puzzle15State = useSelector(state => state?.relax?.puzzle15);
    const status = useSelector(state => state?.relax?.puzzle15?.status);
    const directions = ["+", "-"]

    const height = 350;
    const width = 400;
    const speeds = [50, 100, 145, 150, 180, 190, 200]

    const playAgain = () => {
        dispatch(puzzle15({
            ...relaxState,
            puzzle15: {
                ...puzzle15State,
                status: null,
                timer: 0,
                currentTimer: 0
            }
        }));
    }

    const generateDots = () => {
        let arr = [];
        for (let i = 0; i < 100; i++) {
            arr.push({
                x: Math.floor(Math.random() * width),
                y: Math.floor(Math.random() * height),
                xMove: directions[Math.floor(Math.random() * directions.length)],
                yMove: directions[Math.floor(Math.random() * directions.length)],
                size: 10,
                bgColor: `rgb(${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)})`,
                speed: speeds[Math.floor(Math.random() * speeds.length)]
            })
        }
        return arr;
    }

    const initDots = generateDots();
    const [dots, setDots] = useState(initDots);

    useEffect(() => {
        let filter = dots?.filter(dot => typeof dot?.x === "undefined");
        console.log(filter)
        if (filter?.length === 0) return;
        setDots(generateDots())
    }, [dots]);


    useEffect(() => {
        const interval = setInterval(updateDots, 1000)
        return () => clearInterval(interval);
    }, [status])

    const modifyDot = (dot, line, move) => {
        if (line === "x" && move) {
            if (dot.x + dot.size >= width) return dot.xMove = "-"
            if (dot.x <= 0) return dot.xMove = "+"
        }
        if (line === "y" && move) {
            if (dot.y + dot.size >= height) return dot.yMove = "-"
            if (dot.y <= 0) return dot.yMove = "+"
        }
        if (line === "x" && !move) {
            if (dot.xMove === "+") return dot.x += dot.speed
            if (dot.xMove === "-") return dot.x -= dot.speed
        }
        if (line === "y" && !move) {
            if (dot.yMove === "+") return dot.y += dot.speed
            if (dot.yMove === "-") return dot.y -= dot.speed
        }
    }

    const updateDots = () => {
        setDots(dots?.map(dot => {
            return {
                ...dot,
                x: modifyDot(dot, "x", false),
                y: modifyDot(dot, "y", false),
                xMove: modifyDot(dot, "x", true),
                yMove: modifyDot(dot, "y", true),
            }
        }))
        //console.log(dots)
    }
    const formatTimer = (time = 0) => {
        const min = 60;
        const hour = min * 60;

        function padNumber(number) {
            return (number < 10) ? '0' + number.toString() : number.toString();
        }

        return `${padNumber(Math.floor(time / hour))}:${padNumber(Math.floor(time / min % min))}:${padNumber(time % min)}`;
    }
    return (
        <div className={`relax-win ${status === "win" ? "d-block" : "d-none"}`}>
            <div className="relax-win-word text-center">
                WIN!
                <br />{formatTimer(puzzle15State?.currentTimer)}
                <br />
                <div className="btn btn-success" onClick={playAgain}>Play Again</div>
            </div>
            {dots && dots?.map((dot, index) =>
                <div key={`dot-${index}`} style={{
                    top: dot?.y + "px",
                    left: dot?.x + "px",
                    width: dot?.size,
                    height: dot?.size,
                    backgroundColor: dot?.bgColor
                }} className="win-particle-dots"
                />
            )}
        </div>
    )
}

export default RelaxWin;