import React from "react";
import "./analysis-test-results.css";
import {FaCheck, FaExclamationCircle, FaExclamationTriangle, FaInfoCircle, FaStepForward, FaTimes} from "react-icons/fa";
import {useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import AnalysisTestResultsInnterDetails from "./AnalysisTestResultsInnterDetails/AnalysisTestResultsInnterDetails";

const AnalysisTestResults = ({deviceData, testData}) => {
    //console.log("ANALYSIS: ", JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.analysis);
    let analysis = [];
    try {
        analysis = JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.analysis;
    } catch (e) {
        return <div className="custom-info custom-info-light mb-3">
            <FaInfoCircle className="me-2" /> Mouse Interactive Test was not performed
        </div>
    }


    // const diagnostics = useSelector((state) => state?.diagnostics);
    // const analysis = diagnostics?.analysis

    const deviceStatusGenerator = (device) => {
        if (device?.status === "canceled") {
            return <div className="text-muted">
                Canceled <FaExclamationTriangle />
            </div>
        }
        if (device?.status === "done") {
            if (device?.result) {
                return <div className="text-success">
                    <b>Passed</b>
                    <FaCheck className="ms-2" />
                </div>
            } else {
                return <div className="text-danger">
                    <b>Failed</b>
                    <FaExclamationCircle className="ms-2" />
                </div>
            }
        }
        if (device?.status === "inProgress") {
            return <div>
                In Progress <Spinner className="ms-1" animation="border" size="sm" variant="primary" />
            </div>
        }
        if (device?.selected && device?.status !== "inProgress") {
            return <div>
                Selected
                <span className="ms-1 analysis-test-results-device-checkbox analysis-test-results-device-checkbox-checked">
                    <FaCheck />
                </span>
            </div>
        }
        if (!device?.selected) {
            return <div className="text-muted font-italic">
                Skipped <FaStepForward className="ms-1" />
            </div>
        }
    }

    if (!analysis?.devices) {
        return (
            <div className="custom-info custom-info-light">
                <FaInfoCircle className="me-2" /> Please run <Link className="btn-analysis" to="/analysis">Analysis</Link> to generate report
            </div>
        )
    }

    const downloadJSONFile = async () => {
        const fileName = "analysis-results";
        const json = JSON.stringify(analysis?.results);
        const blob = new Blob([json], {type: 'application/json'});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="analysis-test-results">
            <div className="analysis-test-results-heading d-flex justify-content-between">
                <div className="align-self-center m-0">Analysis Test Results</div>
                <div>Time Elapsed: {analysis?.latestTestDuration}</div>
                {/*<div className="align-self-center">*/} {/*    <div onClick={() => downloadJSONFile()} className="btn btn-success btn-sm py-0 mx-1">*/} {/*        Save as JSON*/} {/*    </div>*/} {/*    <div className="btn btn-success btn-sm py-0">*/} {/*        Save as XML*/} {/*    </div>*/} {/*</div>*/}
            </div>

            <hr className="mt-0" />
            {analysis?.devices?.map((device, dIndex) =>
                <div key={dIndex} className="analysis-test-results-device">
                    <div className="analysis-test-results-device-heading">
                        <div className="analysis-test-results-device-heading-part">
                            <img src={process.env.PUBLIC_URL + `/assets/images/icons/${device?.icon}.svg`} alt="" />
                        </div>
                        <div className="analysis-test-results-device-heading-part">
                            <h4>{device?.title}</h4>
                        </div>
                        <div className="analysis-test-results-device-heading-part">
                            {deviceStatusGenerator(device)}
                        </div>
                    </div>
                    <div>
                        <AnalysisTestResultsInnterDetails device={device} analysis={analysis} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AnalysisTestResults;