import React, {useEffect} from "react";
import "./sessions-list-filters.css";
import {licenses} from "../../../../Redux/Actions/results";
import {useDispatch, useSelector} from "react-redux";

const SessionsListFilters = () => {
    const dispatch = useDispatch();
    const resultsState = useSelector(state => state?.results)

    useEffect(() => {
        if (resultsState?.filters) return;
        dispatch(licenses({
            filters: {
                ordering: "orderByDateDesc",
                filter: "all"
            }
        }));
    }, []);

    const handleFilterChange = (e) => {
        if (e?.target?.name === "ordering") {
            dispatch(licenses({
                filters: {
                    ...resultsState?.filters,
                    ordering: e?.target?.value
                }
            }))
        }

        if (e?.target?.name === "filter") {
            dispatch(licenses({
                filters: {
                    ...resultsState?.filters,
                    filter: e?.target?.value
                }
            }))
        }
    }

    return (
        <div className="sessions-list-filters d-flex gap-3 mx-3 my-3">
            <div className="flex-grow-1">
                <label htmlFor="filter-ordering" className="form-label">Order by <b>Date Created</b>
                </label>
                <select defaultValue={resultsState?.filters?.ordering} name="ordering" className="form-select" id="filter-ordering"
                        onChange={e => handleFilterChange(e)}
                >
                    <option value="orderByDateDesc">
                        Newest First
                    </option>
                    <option value="orderByDateAsc">
                        Oldest First
                    </option>
                </select>
            </div>
            <div className="flex-grow-1">
                <label htmlFor="filter-status" className="form-label">Filter by <b>Global Status</b>
                </label>
                <select value={resultsState?.filters?.filter} name="filter" className="form-select" id="filter-status"
                        onChange={e => handleFilterChange(e)}
                >
                    <option value="all">
                        All
                    </option>
                    <option value="onlyPass">
                        Only Passed
                    </option>
                    <option value="onlyFail">
                        Only Failed
                    </option>
                </select>
            </div>
        </div>
    )
}

export default SessionsListFilters;