import {useDispatch, useSelector} from "react-redux";
import "./healthcheck-report.css";
import {FaArrowCircleLeft, FaCheck, FaExclamationTriangle, FaQuestion, FaTimes} from "react-icons/fa";
import {Spinner} from "react-bootstrap";
import {formatBytes, formatBytesPure, formatDate, isObject} from "../../../../Other/Helper";
import {ReactComponent as Logo} from "../../../../../assets/images/logo.svg";
import {ReactComponent as HealthcheckLogo} from "../../../../../assets/images/healthcheck.svg";
import {ReactComponent as HealthcheckLogoShort} from "../../../../../assets/images/healthcheck-short.svg";
import {ReactComponent as PoweredBy} from "../../../../../assets/images/powered-by.svg";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {isArray} from "chart.js/helpers";

const HealthcheckReport = ({deviceData, testData}) => {

    function parseInfoForData(incomingTestData) {
        try {
            return JSON.parse(Object.values(testData).find((item) => item?.name === "healthcheck")?.data);
        } catch (e) {
            return [];
            console.log(e)
        }
    }

    const data = deviceData;
    const healthcheck = parseInfoForData(testData);

    const iconizeResult = (res) => {
        if (res === undefined) return <Spinner size="sm" />
        if (res === "success") return <FaCheck className="text-success" size="20" />
        if (res === "danger") return <FaTimes className="text-danger" size="20" />
        if (res === "warning") return <FaExclamationTriangle className="text-warning" size="20" />
        if (res === "N/A") return <span className="badge bg-warning">N/A</span>
        return <FaQuestion className="text-warning" size="20" />
    }
    if (!healthcheck) {
        return (
            <div>
                PLEASE WAIT
            </div>
        )
    }
    console.log("HEALTHCHECK: ", data, healthcheck)
    return <div className="system-eval-result">
        <div className="container">
            <div className="system-eval-result-title text-center right-text">
                <h1 className="fw-bolder">
                    <HealthcheckLogo height={100} />
                </h1>
                <h3 className="fst-italic">Customer Report</h3>
            </div>

            <div className="system-eval-result-system-info">
                <div className="healthcheck-logo-placement">
                    <HealthcheckLogoShort />
                </div>

                <div className="system-eval-result-system-info-content">
                    <h3>System Information</h3>

                    <div className="hc-si-table">
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Make</div>
                            <div className="hc-si-cell truncate">{healthcheck?.rawData?.hardware?.system?.system?.manufacturer}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Model</div>
                            <div className="hc-si-cell truncate">{healthcheck?.rawData?.hardware?.system?.system?.model}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Serial Number</div>
                            <div className="hc-si-cell truncate">{healthcheck?.rawData?.hardware?.system?.system?.serial}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">OS Version</div>
                            <div className="hc-si-cell truncate">{healthcheck?.rawData?.hardware?.os?.osInfo?.distro}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Processor</div>
                            <div className="hc-si-cell truncate">{healthcheck?.rawData?.hardware?.cpu?.cpu?.brand}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Memory</div>
                            <div className="hc-si-cell truncate d-flex gap-1">
                                <span>{healthcheck?.rawData?.hardware?.memory?.memLayout[0]?.manufacturer}</span>
                                <span>[{formatBytes(healthcheck?.rawData?.hardware?.memory?.mem?.total)}]</span>
                            </div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Primary Storage</div>
                            <div className="hc-si-cell truncate d-flex gap-1">
                                <span>{healthcheck?.systemInfo?.data?.primaryDrive?.name}</span>
                                <span>[{healthcheck?.systemInfo?.data?.primaryDrive?.size}]</span>
                            </div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">GPU</div>
                            <div className="hc-si-cell truncate">
                                {healthcheck?.rawData?.hardware?.gpu?.controllers?.map((item, gpui) =>
                                    <div className="d-flex gap-1" key={item?.model + "-" + gpui}>
                                        <span>{item?.model}</span>
                                        <span>[{formatBytes(item?.vram, "MB")}]</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">BIOS</div>
                            <div className="hc-si-cell truncate d-flex gap-1">
                                <span>{healthcheck?.rawData?.hardware?.system?.bios?.vendor}</span>
                                <span>{healthcheck?.rawData?.hardware?.system?.bios?.version}</span>
                                <span>{healthcheck?.rawData?.hardware?.system?.bios?.serial}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hc-ss-report-prologue">
                <div>
                    <h3>
                        <b>Description</b>
                    </h3>
                    <p>Thank you for using our <b>Healthcheck</b> tool. This has performed a quick assessment of your computer highlighting key recommendations that we have found. It has also completed a basic hardware, software and security inspection and the results can be found below.
                    </p>

                    <h3>
                        <b>Healthcheck Results</b>
                    </h3>
                    <p>The results of the <b>Healthcheck</b> are as follows:
                    </p>
                </div>
            </div>


            <div className="hc-ss-report">
                <div className="mb-3">
                    <b>OS Scan</b>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Restore Points:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "restore-points")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Registry:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "registry")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Windows Upgrade:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "upgrade-windows")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Disabled Devices (No Driver):</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "disabled-devices")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Windows Unexpected Shutdowns (BSoDs):</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "windows-unexpected-shutdowns")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Application Failures (BSoDs):</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "application-failures")?.result)}</div>
                    </div>
                </div>

                <div className="mb-3">
                    <b>Hardware Scan</b>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">CPU:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "cpu-speed")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Memory:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "memory")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Primary Storage:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "primary-is-ssd")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Storage Failure:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "storage-failure")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Disk Health:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "disk-health")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Resolution Check:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "screens-resolution")?.result)}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Battery:</div>
                        <div className="hc-ss-cell truncate">{iconizeResult(healthcheck?.results?.find(res => res?.id === "battery")?.result)}</div>
                    </div>
                </div>

                <div className="mb-3">
                    <b>PC Optimisation</b>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Temporary Folder Size:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.optimizerResult?.temporary ??
                            <FaQuestion />}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Downloads Folder Size:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.optimizerResult?.downloads ??
                            <FaQuestion />}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Recycle Bin Size:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.optimizerResult?.recycleBin ??
                            <FaQuestion />}</div>
                    </div>

                    {healthcheck?.diskSpaces?.map((diskSpace) =>
                        <div className="hc-ss-row" key={diskSpace?.drive}>
                            <div className="hc-ss-cell truncate">Drive {diskSpace?.drive}</div>
                            <div className={`hc-ss-cell truncate`}>
                                {diskSpace?.usage ? `${diskSpace?.usage}` :
                                    <FaQuestion />}
                                <span className="mx-1">{iconizeResult(diskSpace?.status)}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <b>Malware Results</b>
                    <span className="ms-1">(Scanned: "C:\Program Files")</span>
                    {healthcheck?.malwareResult?.scanResults?.Files === 0 ?
                        <div className="hc-ss-row">
                            <div className="alert alert-danger m-0 p-1 px-2">
                                {healthcheck?.malwareResult?.scanResults?.Errors}
                            </div>
                        </div>
                        : ""}
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Scanned Files:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.malwareResult?.scanResults?.Files ??
                            <FaQuestion />}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Scanned Folders:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.malwareResult?.scanResults?.Folders ??
                            <FaQuestion />}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Scanned Archives:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.malwareResult?.scanResults?.Archives ??
                            <FaQuestion />}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Infected Files:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.malwareResult?.scanResults?.InfectedFiles ??
                            <FaQuestion />}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Suspect Files:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.malwareResult?.scanResults?.SuspectFiles ??
                            <FaQuestion />}</div>
                    </div>
                    <div className="hc-ss-row">
                        <div className="hc-ss-cell truncate">Scan Time:</div>
                        <div className="hc-ss-cell truncate">{healthcheck?.malwareResult?.scanResults?.scanTime ??
                            <FaQuestion />}</div>
                    </div>
                </div>
            </div>

            <div className="hc-ss-report-epilogue">
                <div>
                    <h3>
                        <b>Summary</b>
                    </h3>
                    <p>
                        <b>The scan has detected that your system could benefit most from a hardware diagnostic <Link to="/hardware-testing">Hardware Testing</Link>.
                        </b>
                    </p>
                </div>

                <h3>
                    <b>How else can we help?</b>
                </h3>
                <p>Based on the results above, the following services may also be applicable to your system: <Link to="/full-setup">Cloud Storage</Link>
                </p>
                <p>Cloud Storage automatically backs up your files and folders so you don’t have to do a thing, giving you complete peace of mind. We recommend that your files are backed up. We have a range of Cloud Storage packages available. Ask our colleagues for more details.
                </p>
            </div>
        </div>
    </div>
}

export default HealthcheckReport;