import {useDispatch, useSelector} from "react-redux";
import "./alerts.css";
import {FaCheck, FaExclamation, FaInfo, FaQuestion, FaTimes} from "react-icons/fa";
import {global} from "../../../Redux/Actions/global";
import moment from "moment";
import {useEffect, useState} from "react";

const Alerts = () => {
    const [time, setTime] = useState(new Date());

    const dispatch = useDispatch();
    const alerts = useSelector(state => state?.global?.alerts);

    const dismissAlert = (alertID) => {
        dispatch(global({
            alerts: alerts?.map(alert => {
                if (alert?.id === alertID) {
                    return {
                        ...alert,
                        show: false
                    }
                }
                return alert
            })
        }))
    }

    const timeDifference = (alert, start, end) => {
        const startDate = moment(start);
        const timeEnd = moment(end);
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);

        return {
            days: diffDuration.days(),
            hours: diffDuration.hours(),
            minutes: diffDuration.minutes(),
            seconds: diffDuration.seconds()
        }
    }

    const timeDifferenceText = (alert, start, end) => {
        let array = [];
        let diffObj = timeDifference(alert, start, end);

        if (diffObj?.days !== 0) {
            array.push(diffObj?.days + " Days ")
        }
        if (diffObj?.hours !== 0) {
            array.push(diffObj?.hours + " Hours ")
        }
        if (diffObj?.minutes !== 0) {
            array.push(diffObj?.minutes + " Minutes ")
        }

        return array.join(" ") + diffObj?.seconds + " Seconds Ago";
    }

    useEffect(() => {
        alerts?.filter(alert => alert?.show).map(alert => {
            //console.log(timeDifference(alert, alert?.date, time).seconds)
            if (timeDifference(alert, alert?.date, time).seconds >= 5) {
                dismissAlert(alert?.id)
            }
        })
    })

    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 1000);
        return () => clearInterval(interval);
    }, []);

    const iconByType = (type) => {
        switch (type) {
            case "success":
                return <FaCheck />
            case "warning":
                return <FaExclamation />
            case "danger":
                return <FaTimes />
            case "info":
                return <FaInfo />
            default:
                return <FaQuestion />
        }
    }

    return (
        <div className="portal-alerts">
            {alerts?.filter(alert => alert?.show)?.map((alert, ialert) =>
                <div key={ialert} className={`portal-alert bg-${alert?.type} portal-alert-${alert?.type}`}>
                    <div className="badge position-absolute top-0 end-0 fw-normal">{timeDifferenceText(alert, alert?.date, time)}</div>
                    <div className="d-flex">
                        <div className="portal-alert-title-icon align-self-center">{iconByType(alert?.type)}</div>
                        <div className="portal-alert-title">{alert?.title}</div>
                    </div>
                    <div>
                        {alert?.message}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Alerts;
