const aboutReducer = (state = [], action) => {
    switch (action.type) {
        case 'USER_LOGIN_SESSIONS_FETCH':
            return {
                ...state, ...action.data
            }
        default:
            return state;
    }
};

export default aboutReducer;