import React from "react";
import "./uav.css";
import {BASE_URL} from "../../../../Constants";
import {FaInfoCircle} from "react-icons/fa";

const UAV = ({data}) => {
    let uav = [];
    try {
        let uav = data && Object.values(data)?.length !== 0 && data?.filter(item => item?.file_name === "uav")[0] && JSON.parse(data?.filter(item => item?.file_name === "uav")[0]?.result_data);
    } catch (e) {
        console.log(e)
        return <div>{e?.message}</div>
    }


    if (typeof uav === "undefined" || !uav?.GLOBAL_RESULT) {
        return (
            <div className="system-info-page uav">
                <div className="system-info-page-heading mb-3 d-flex justify-content-between">
                    <div className="align-self-center m-0">UAV Antivirus</div>
                    <img src={`${BASE_URL}assets/images/icons/device-components/uav.png`} alt="uav" />
                </div>
                <div className="custom-info custom-info-light border mb-3">
                    <FaInfoCircle /> Sorry there is no data for UAV
                </div>
            </div>
        )
    }

    return (
        <div className="uav">
            <div className="system-info-page-heading d-flex justify-content-between">
                <div className="align-self-center m-0">UAV Antivirus</div>
                <img src={`${BASE_URL}assets/images/icons/device-components/uav.png`} alt="uav" />
            </div>
            <div className="uav-badge-statuses text-center my-3">
                <span className={`badge bg-${uav?.systemreport?.malwarelog?.uavmalwarescanner?.infecteditems === "0" ? "success" : "danger"}`}>
                    <b className="me-1">
                        Global Status:
                    </b>
                    {uav?.GLOBAL_RESULT}
                </span>
                <span className="badge bg-primary">
                    <b className="me-1">
                        Total Scan Time:
                    </b>
                    {uav?.systemreport?.malwarelog?.uavmalwarescanner?.totalscantime}
                </span>
                <span className="badge bg-secondary">
                    <b className="me-1">Upload Date:</b>
                    {uav?.systemreport?.malwarelog?.uavmalwarescanner?.logdate}
                </span>
            </div>
            {uav?.systemreport?.malwarelog?.uavmalwarescanner?.infecteditems === "0" ?
                <div className="alert alert-success test-result-alert">
                    System is clean.
                </div>
                :
                <React.Fragment>
                    <button className="btn btn-danger mb-3 w-100" type="button" data-toggle="collapse" data-target="#errors" aria-expanded="false" aria-controls="errors">
                        Threats where found. Please review.
                    </button>
                    <div className="alert alert-danger test-result-alert collapse" id="errors">
                        <div>
                            {uav?.systemreport?.malwarelog?.uavmalwarescanner?.infected_and_suspicious_file_list && Object.keys(uav?.systemreport?.malwarelog?.uavmalwarescanner?.infected_and_suspicious_file_list).length !== 0 ? uav?.systemreport?.malwarelog?.uavmalwarescanner?.infected_and_suspicious_file_list.map((errFile, errFilei) =>
                                <div key={errFilei}>
                                    <h3>
                                        {errFile?.name}
                                    </h3>
                                    Path: {errFile?.path}
                                    <br />
                                    Status: {errFile?.status}
                                </div>
                            ) : ""}
                        </div>
                    </div>
                </React.Fragment>
            }

            <div className="uav-screen my-3 p-3 position-relative">

                <h3>Name: {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanningtask}
                </h3>

                <hr />

                <h4>Test(s) Performed</h4>
                <div className="small-text" id="tests-performed">
                    <div className="row my-3">

                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforviruses === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for viruses: &nbsp;
                                <span className="font-weight-bold">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforviruses}
                                    </span>
                            </div>
                        </div>

                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforadware === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for adware: &nbsp;
                                <span className="font-weight-bold">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforadware}
                                    </span>
                            </div>
                        </div>

                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforspyware === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for spyware: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforspyware}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforspyware === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Applications: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforspyware}
                                    </span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanfordialers === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Dialers: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanfordialers}
                                    </span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanfordialers === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Rootkits: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanfordialers}
									</span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforkeyloggers === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Keyloggers: &nbsp;
                                <span className="font-weight-bold">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanforkeyloggers}
                                    </span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanregistrykeys === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Registry Keys: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanregistrykeys}
                                    </span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scancookies === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Cookies: &nbsp;
                                <span className="font-weight-bold">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scancookies}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanbootsectors === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Boot Sectors: &nbsp;
                                <span className="font-weight-bold">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanbootsectors}
                                    </span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanmemoryprocesses === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Memory Processes: &nbsp;
                                <span className="font-weight-bold">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanmemoryprocesses}
									</span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanarchives === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Archives: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanarchives}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanruntimepackers === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Runtime Packers: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanruntimepackers}
                                    </span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanemails === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for Emails: &nbsp;
                                <span className="font-weight-bold">
                                       {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanemails}
                                    </span>
                            </div>
                        </div>
                        <div className="col-4 mb-1">
                            <div className={`border rounded w-100 p-3 ${uav?.systemreport?.malwarelog?.uavmalwarescanner?.filetypes === "Yes" ? "bg-success text-white" : "bg-secondary text-white"}`}>
                                Scan for File Types: &nbsp;
                                <span className="font-weight-bold">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.filetypes}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>

                <h4>Scanned File(s) Info</h4>
                <div className="small-text">
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Scanned Items: &nbsp;
                                <span className="font-weight-bold parse-float">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scanneditems}
                                    </span>
                            </div>
                        </div>
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Scanned Archives: &nbsp;
                                <span className="font-weight-bold parse-float">
										{uav?.systemreport?.malwarelog?.uavmalwarescanner?.scannedarchives}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Scanned Bootsectors: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scannedbootsectors}
                                    </span>
                            </div>
                        </div>
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Scanned Processes: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scannedprocesses}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Scanned Registry Keys: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scannedregistrykeys}
                                    </span>
                            </div>
                        </div>
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Scanned Processes: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.scannedprocesses}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>


                <h4>Final Result</h4>
                <div className="small-text">
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Infected Items: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.infecteditems}
                                    </span>
                            </div>
                        </div>
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Infected Registry Keys: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.infectedregistrykeys}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Infected Processes: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.infectedprocesses}
                                    </span>
                            </div>
                        </div>
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Infected Cookies: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.infectedcookies}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Resolved Items: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.resolveditems}
                                    </span>
                            </div>
                        </div>
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Unresolved Items: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.unresolveditems}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Skipped Items: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.skippeditems}
                                    </span>
                            </div>
                        </div>
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Suspect Items: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.suspectitems}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 mb-1">
                            <div className="uav-info-items">
                                Password Protected Items: &nbsp;
                                <span className="font-weight-bold parse-float">
                                        {uav?.systemreport?.malwarelog?.uavmalwarescanner?.passwordprotecteditems}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UAV;