import React from "react";
import {FaCheck, FaChevronLeft, FaTimes} from "react-icons/fa";

const AnalysisTestResultsInnterDetails = ({device, analysis}) => {
    const optionStatusGenerator = (option, device) => {
        if (option?.result === "Success") {
            return <div className="text-success">
                <FaCheck className="ms-2" />
            </div>
        }
        if (option?.result === "Failed") {
            return <div className="text-danger">
                <FaTimes className="ms-2" />
            </div>
        }
        if (device?.status === "inProgress") {
            if (option?.active) {
                return <span className="ms-1 analysis-test-results-device-checkbox analysis-test-results-device-checkbox-checked">
                    <FaCheck />
                </span>
            } else {
                return <div className="text-muted font-italic">
                    -{/*<FaStepForward className="ms-1" />*/}
                </div>
            }
        }
        if (!device?.selected) {
            return <div className="text-muted font-italic">
                -{/*<FaStepForward className="ms-1" />*/}
            </div>
        }
        if (option?.active && device?.status === "done") {
            return <div className="text-muted font-italic">
                <span className="ms-1 analysis-test-results-device-checkbox analysis-test-results-device-checkbox-checked">
                    <FaCheck />
                </span>
            </div>
        }
        if (!option?.active && device?.status === "done") {
            return <div className="text-muted font-italic">
                -{/*<FaStepForward className="ms-1" />*/}
            </div>
        }
        if (option?.active) {
            return <div className="text-muted font-italic">
                <span className="ms-1 analysis-test-results-device-checkbox analysis-test-results-device-checkbox-checked">
                    <FaCheck />
                </span>
            </div>
        }
        if (!option?.active) {
            return <span className="ms-1 analysis-test-results-device-checkbox">
                    <FaCheck />
                </span>
        }
    }


    if (device?.id === "Drives") {
        return (
            <React.Fragment>
                {device?.each && device?.each?.map(dev =>
                    <div className="text-left" key={dev?.name}>
                        <b>{dev?.name}</b>
                        <span className="mx-2">-</span>
                        {dev?.size}
                        <div>
                            {dev?.options && dev?.options.map((option, optionIndex) =>
                                <div key={optionIndex} className="analysis-test-results-option-children px-1">
                                    <div className={`analysis-test-results-option-child`}>{option?.name}</div>
                                    {analysis.results.map((result, rIndex) =>
                                        <div key={rIndex} className={`analysis-test-results-option-child`}>
                                            {optionStatusGenerator(option, device)}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {device && device?.options.map((option, optionIndex) =>
                <div key={optionIndex} className="analysis-test-results-option-children px-1">
                    <div className={`analysis-test-results-option-child`}>{option?.name}</div>
                    {analysis.results.map((result, rIndex) =>
                        <div key={rIndex} className={`analysis-test-results-option-child`}>
                            {optionStatusGenerator(
                                result.filter(res => res.id === device.id)[0]
                                    .options.filter(rOption => rOption.name === option.name)[0],
                                result.filter(res => res.id === device.id)[0])}
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    )
}

export default AnalysisTestResultsInnterDetails;