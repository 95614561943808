import {useDispatch, useSelector} from "react-redux";
import "./logout.css";
import axios from "axios";
import {BASE_URL} from "../../Constants";
import React, {useEffect, useState} from "react";
import {global} from "../../../Redux/Actions/global";
import {logout} from "../../../Redux/Actions/login";
import {useNavigate} from "react-router-dom";
import axiosRetry from "axios-retry";

const baseURL = BASE_URL;
const phpRoute = "logout-user-active-session/";

//status 3 => initial
//status 2 => loading
//status 1 => success
//status 0 => error
const logoutRetries = 10;
const LogoutServer = () => {
    const dispatch = useDispatch();
    const [retried, setRetried] = useState(0);
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)
    const [status, setStatus] = useState(3);
    let localData = JSON.parse(localStorage.getItem("loginData"));

    const deleteSession = () => {
        setStatus(2)
        axiosRetry(axios, {
            retries: logoutRetries,
            shouldResetTimeout: true,
            retryCondition: (_error) => true, // retry no matter what
            retryDelay: (retryDelay) => {
                setRetried(retried => retried + 1)
                return retryDelay * 1000;
            }
        });
        axios({
                url: baseURL + phpRoute,
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                data: JSON.stringify({
                    email: loginState?.email,
                    hash: localData?.hash
                })
            }
        ).then(res => {
            setStatus(res?.data);
        }).catch((err) => {
            console.error("Please wait... ", err);
        })
    }

    useEffect(() => {
        // console.log(retried)
        if (retried === 5) {
            loginTimoutMiddleInfoMessage();
        }
    }, [retried]); //Middle of retried timeout
    const loginTimoutMiddleInfoMessage = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "info",
                    title: "Authentication",
                    message: "There appears to be an issue. I'm working on it, please wait...",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }


    useEffect(() => {
        if (status === 3) return;
        if (status === 2) {

        }
        if (status === 1) {
            if (loginState?.length === 0) return;
            setTimeout(() => {
                localStorage.removeItem("loginData");
                dispatch(logout())
                logoutSuccessMessage();
            }, 2000)
        }
        if (status === 0) {
            logoutErrorMessage()
        }
    }, [status]);

    useEffect(() => {
        if (globalState?.trigger === "logout")
            deleteSession();
    }, [globalState?.trigger]);


    const logoutSuccessMessage = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "success",
                    title: "Authentication",
                    message: "Logout was successful...",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }
    const logoutErrorMessage = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "danger",
                    title: "Authentication",
                    message: "Can not Logout now, please try later...",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }

    if (globalState?.trigger === "logout") return <div className="start-logout">
        <div className="logout-spinner-wrapper">
            <div className="spinner-border text-light" role="status">
            </div>
            <br />
            Logging Out, Please wait...
        </div>
    </div>

    return <div className="logout"></div>
}

export default LogoutServer;