import {useDispatch, useSelector} from "react-redux";
import "./system-eval-report.css";
import {FaArrowCircleLeft, FaCheck, FaExclamationTriangle, FaQuestion, FaTimes} from "react-icons/fa";
import {Spinner} from "react-bootstrap";
import {formatBytes, formatBytesPure, formatDate, isObject} from "../../../../Other/Helper";
import {ReactComponent as SystemEval} from "../../../../../assets/images/system-eval.svg";
import {ReactComponent as SystemEvalLogo} from "../../../../../assets/images/system-eval-short.svg";
import {ReactComponent as PoweredBy} from "../../../../../assets/images/powered-by.svg";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import CircleProgressBar from "../../../../Other/CircleProgressBar/CircleProgressBar";

const SystemEvalReport = ({deviceData, testData}) => {

    function parseInfoForData(incomingTestData) {
        try {
            return JSON.parse(Object.values(incomingTestData).find((item) => item?.name === "system-eval")?.data);
        } catch (e) {
            return [];
            console.log(e)
        }
    }

    const data = parseInfoForData(testData);

    const iconizeResult = (res) => {
        if (res === undefined) return <Spinner size="sm" />
        if (res === "success") return <FaCheck className="text-success" size="20" />
        if (res === "danger") return <FaTimes className="text-danger" size="20" />
        if (res === "warning") return <FaExclamationTriangle className="text-warning" size="20" />
        if (res === "N/A") return <span className="badge bg-warning">N/A</span>
        return <FaQuestion className="text-warning" size="20" />
    }
    console.log("SYSTEM EVAL: ", data)

    // useEffect(() => {
    //     window.electron.reportRequest({
    //         query: `SELECT * FROM reports WHERE id = ?`,
    //         bind: [id],
    //         single: true
    //     });
    // }, []);
    //
    // useEffect(() => {
    //     window.electron.reportReceived((result) => {
    //         console.log("result: ", result)
    //         if (result?.reportData?.data !== undefined) {
    //             setData(JSON.parse(result?.reportData?.data));
    //         }
    //         delete result?.reportData?.data;
    //         setRawData(result?.reportData);
    //     })
    // }, [window.electron.reportReceived]);
    //
    // useEffect(() => {
    //     console.log(data, rawData)
    // }, [data, rawData]);

    return <div className="system-eval-result">
        <div className="container">
            <div className="system-eval-result-title text-center right-text">
                <SystemEval height="100" />
                <h3 className="fst-italic">Customer Report</h3>
            </div>

            <div className="system-eval-result-system-info">
                <div className="healthcheck-logo-placement">
                    <SystemEvalLogo />
                </div>

                <div className="system-eval-result-system-info-content">
                    <h3>System Information</h3>

                    <div className="hc-si-table">
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Make</div>
                            <div className="hc-si-cell truncate">{data?.rawData?.hardware?.system?.system?.manufacturer}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Model</div>
                            <div className="hc-si-cell truncate">{data?.rawData?.hardware?.system?.system?.model}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Serial Number</div>
                            <div className="hc-si-cell truncate">{data?.rawData?.hardware?.system?.system?.serial}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">OS Version</div>
                            <div className="hc-si-cell truncate">{data?.rawData?.hardware?.os?.osInfo?.distro}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Processor</div>
                            <div className="hc-si-cell truncate">{data?.rawData?.hardware?.cpu?.cpu?.brand}</div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Memory</div>
                            <div className="hc-si-cell truncate">
                                <span className="me-1">{data?.rawData?.hardware?.memory?.memLayout[0]?.manufacturer}</span>
                                <span className="me-1">[{formatBytes(data?.rawData?.hardware?.memory?.mem?.total)}]</span>
                            </div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">Primary Storage</div>
                            <div className="hc-si-cell truncate">
                                <span className="me-1">{data?.systemInfo?.data?.primaryDrive?.name}</span>
                                <span className="me-1">[{data?.systemInfo?.data?.primaryDrive?.size}]</span>
                            </div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">GPU</div>
                            <div className="hc-si-cell truncate">
                                <span className="me-1">{data?.systemInfo?.data?.gpu?.model}</span>
                                <span className="me-1">[{data?.systemInfo?.data?.gpu?.vram}]</span>
                            </div>
                        </div>
                        <div className="hc-si-row">
                            <div className="hc-si-cell truncate">BIOS</div>
                            <div className="hc-si-cell truncate">
                                <span className="me-1">{data?.rawData?.hardware?.system?.bios?.vendor}</span>
                                <span className="me-1">{data?.rawData?.hardware?.system?.bios?.version}</span>
                                <span className="me-1">{data?.rawData?.hardware?.system?.bios?.serial}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hc-ss-report-prologue">
                <div>
                    <h3>
                        <b>Summary</b>
                    </h3>
                    <p>Your system has completed our System Eval and has a score of {data?.rank}. A breakdown of this score is shown below with more details in the Results section.</p>
                    <p>
                        <b>The scan has detected that your system could benefit most from a hardware diagnostic <Link to="/">Fault Finder</Link> scan.
                        </b>
                    </p>

                    <div className="my-5 text-center">
                        {
                            <CircleProgressBar rank={data?.rank} />}
                    </div>

                    <h3>
                        <b>System Eval Results</b>
                    </h3>
                    <p>The results of the <b>System Eval</b> are as follows:
                    </p>
                </div>
            </div>


            <div className="ss-report">
                <div className="mb-3">
                    <b> Visual Assessment</b>
                    {data?.manualInspection?.map(item =>
                        <div className="ss-row" key={item?.id}>
                            <div className="ss-cell truncate">{item?.name}:</div>
                            <div className="ss-cell truncate">{iconizeResult(item?.result)}</div>
                        </div>
                    )}
                </div>
                <div className="mb-3">
                    <b>Security Assessment</b>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Resolution Score:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "screens-resolution")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Firewall Score:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "firewall")?.result)}</div>
                    </div>
                </div>
                <div className="mb-3">
                    <b>Reliability Assessment</b>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Windows Unexpected Shutdowns (BSoDs):</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "windows-unexpected-shutdowns")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Application Failures (BSoDs):</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "application-failures")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Storage Failure:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "storage-failure")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Disk Health:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "disk-health")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Disabled Devices (No Driver):</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "disabled-devices")?.result)}</div>
                    </div>
                </div>
                <div className="mb-3">
                    <b>Overall Performance</b>
                    <div className="ss-row">
                        <div className="ss-cell truncate">CPU:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "cpu-speed")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Memory:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "memory")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Primary Storage:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "primary-is-ssd")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Restore Points:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "restore-points")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Registry:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "registry")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Battery:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "battery")?.result)}</div>
                    </div>
                    <div className="ss-row">
                        <div className="ss-cell truncate">Windows Upgrade:</div>
                        <div className="ss-cell truncate">{iconizeResult(data?.results?.find(res => res?.id === "upgrade-windows")?.result)}</div>
                    </div>
                </div>
            </div>

            <div className="hc-ss-report-epilogue">
                <div>
                    <h3>
                        <b>Summary</b>
                    </h3>
                    <p>
                        <b>The scan has detected that your system could benefit most from a hardware diagnostic <Link to="/hardware-testing">Hardware Testing</Link>.
                        </b>
                    </p>
                </div>

                <h3>
                    <b>How else can we help?</b>
                </h3>
                <p>Based on the results above, the following services may also be applicable to your system: <Link to="/full-setup">Cloud Storage</Link>
                </p>
                <p>Cloud Storage automatically backs up your files and folders so you don’t have to do a thing, giving you complete peace of mind. We recommend that your files are backed up. We have a range of Cloud Storage packages available. Ask our colleagues for more details.
                </p>
            </div>
        </div>

    </div>
}

export default SystemEvalReport;