import React, {useEffect, useState} from 'react';
import "./tracker.css";
import {useDispatch, useSelector} from "react-redux";
import {addColumn, columnInputChange, initTracker, reorderTrackerTasks, taskModifier} from "../../../Redux/Actions/tracker";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {BASE_URL} from "../../Constants";
import {Button} from "react-bootstrap";
import axios from "axios";
import loadable from "@loadable/component";
import {FaPencilAlt, FaPlus} from "react-icons/fa";
import {RiMouseFill} from "react-icons/ri";
import {BsChevronDoubleDown, BsChevronDoubleUp, BsFillShiftFill} from "react-icons/bs";

const Column = loadable(() => import('./Column/Column'));
const ModifyTask = loadable(() => import('./Modify/ModifyTask'));

const baseURL = BASE_URL;
const phpRoute = "tracker";

const Tracker = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global);
    const loginState = useSelector(state => state?.login);
    const tracker = useSelector(state => state?.tracker);
    const [test, setTest] = useState(0);

    useEffect(() => {
        dispatch(initTracker({
            columnOrder: tracker?.columnOrder ?? [],
            columns: tracker?.columns ?? {},
            tasks: tracker?.tasks ?? {},
            isLoading: true,
            modifyTask: {
                title: "",
                description: ""
            },
            modalShow: false,
            columnEdit: "",
            columnNameValue: "unknown",
            contextMenuVisible: false,
        }));
        const timeout = setTimeout(() => {
            fetch(baseURL + phpRoute,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify({
                        email: loginState?.email
                    })
                }
            )
                .then(response => response.json())
                .then(data => {
                    dispatch(initTracker({
                        columnOrder: data?.column_order ?? [],
                        columns: data?.columns ?? {},
                        tasks: data?.tasks ?? {},
                        isLoading: false,
                        modifyTask: {},
                        modalShow: false,
                        columnEdit: "",
                    }))
                });
        }, 1000);
        return () => clearTimeout(timeout);
    }, [test]);

    const OnDragEnd = (result) => {
        const {
            destination,
            source,
            draggableId,
            type
        } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'column') {
            const newColumnOrder = Array.from(tracker?.columnOrder);
            newColumnOrder.splice(source.index, 1);
            newColumnOrder.splice(destination.index, 0, draggableId);

            const newState = {
                columnOrder: newColumnOrder,
            }
            dispatch(reorderTrackerTasks(newState));
            submitColumnReorder(newState);
            return;
        }

        const start = tracker?.columns[source?.droppableId];
        const end = tracker?.columns[destination?.droppableId];

        if (start === end) {
            const newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId)

            const newColumn = {
                ...start,
                taskIds: newTaskIds,
            };

            const newState = {
                columns: {
                    ...tracker?.columns,
                    [newColumn.id]: newColumn
                }
            }
            dispatch(reorderTrackerTasks(newState));
            submitTaskReOrder(newState);

            return;
        }

        //move to different column
        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const endTaskIds = Array.from(end.taskIds);
        endTaskIds.splice(destination.index, 0, draggableId);
        const newEnd = {
            ...end,
            taskIds: endTaskIds,
        };

        const newState = {
            columns: {
                ...tracker?.columns,
                [newStart.id]: newStart,
                [newEnd.id]: newEnd
            }
        }
        dispatch(reorderTrackerTasks(newState));
        submitTaskReOrder(newState);
    }
    const submitTaskReOrder = (tasks) => {
        axios({
            method: 'post',
            url: baseURL + phpRoute,
            data: JSON.stringify({
                email: loginState?.email,
                type: "task_re_order",
                tasks: tasks
            }),
            headers: {'content-type': 'application/json'},
        })
            .then(res => {
                //console.log(res);
            })
    }
    const submitColumnReorder = (columns) => {
        axios({
            method: 'post',
            url: baseURL + phpRoute,
            data: JSON.stringify({
                email: loginState?.email,
                type: "column_re_order",
                columns: columns
            }),
            headers: {'content-type': 'application/json'},
        })
            .then(res => {
                //console.log(res);
            })
    }
    const modalClose = () => {
        dispatch(taskModifier({
            modalShow: false
        }))
    };
    const columnInputChangeFunc = (e) => {
        let value = e?.target?.value;
        dispatch(columnInputChange({
            columnInputValue: value
        }))
    }
    const addColumnFunc = () => {
        axios({
            method: 'post',
            url: baseURL + phpRoute,
            data: JSON.stringify({
                email: loginState?.email,
                type: "add_column",
                title: tracker?.columnInputValue
            }),
            headers: {'content-type': 'application/json'},
        })
            .then(res => {
                //console.log(res.data);
                dispatch(addColumn({
                    columnOrder: [
                        ...tracker?.columnOrder,
                        res?.data?.uniq_id
                    ],
                    columns: {
                        ...tracker?.columns,
                        [res?.data?.uniq_id]: res?.data?.column
                    }
                }))
            })
    }

    const columnPreloader = () => {
        let columns = [];
        let columnAmount = Math.round(Math.random() * 6)
        for (let i = 0; i <= columnAmount; i++) {
            columns.push(i);
        }
        return columns && columns?.map((column, icolumn) =>
            <div className="tracker-column" key={icolumn}>
                <div className="tracker-column-title">
                    <div className="placeholder-glow" style={{fontSize: "16px"}}>
                        <span className="placeholder col-6" />
                        <div className="btn btn-outline-secondary btn-sm float-end py-0 px-1">
                            <FaPencilAlt />
                        </div>
                    </div>
                </div>

                <div className="add-card-button">
                    <div className="btn btn-outline-secondary py-0 w-100 my-1 border-0">Add Card <FaPlus />
                    </div>
                </div>
                <div className="tracker-column-overflow">
                    {taskPreloader()}
                </div>
            </div>
        )
    }
    const taskPreloader = () => {
        let tasks = [];
        let taskAmount = Math.round(Math.random() * 3)
        for (let i = 0; i <= taskAmount; i++) {
            tasks.push(i);
        }

        return tasks && tasks?.map((task, itask) =>
            <div className="task-list" key={itask}>
                <div className="tracker-column-card">
                    <div className="position-relative">
                        <div className="task-datetime badge text-secondary">
                            --/--/----
                        </div>
                    </div>
                    <div className="tracker-column-card-title">
                        <h5 className="placeholder-glow" style={{fontSize: "16px"}}>
                            <span className="placeholder col-6" />
                        </h5>
                        <p className="placeholder-glow" style={{fontSize: "14px"}}>
                            <span className="placeholder col-4 placeholder-xs" />
                            &nbsp;
                            <span className="placeholder col-6 placeholder-xs" />
                            &nbsp;
                            <span className="placeholder col-8 placeholder-xs" />
                            &nbsp;
                            <span className="placeholder col-3 placeholder-xs" />
                            &nbsp;
                            <span className="placeholder col-2 placeholder-xs" />
                            &nbsp;
                            <span className="placeholder col-3 placeholder-xs" />
                        </p>
                        {task === Math.round(Math.random() * 3) ?
                            <a href="#" tabIndex="-1" className="btn btn-primary disabled placeholder col-6" /> : ""}
                    </div>
                </div>
            </div>
        )
    }

    if (tracker?.isLoading) {
        return (
            <div className="tracker">
                <div className="d-flex page-main-heading mb-2">
                    <h2 className="m-0 mx-3 mt-1 text-uppercase">Tracker</h2>
                    <div className="align-self-center mt-1 badge bg-warning fw-normal text-dark">
                        <b>Shift</b>
                        <span> + </span>
                        <b>Scroll</b>
                        <span> to Scroll Horizontally</span>
                    </div>
                    <div className="d-flex align-self-center mt-1 mx-2 shift-mouse-scroll">
                        <div>
                            <BsFillShiftFill />
                        </div>
                        <div className="align-self-center">
                            +
                        </div>
                        <div className="d-flex flex-column">
                            <BsChevronDoubleUp size="7" style={{width: "100%"}} />
                            <RiMouseFill size="20" />
                            <BsChevronDoubleDown size="7" style={{width: "100%"}} />
                        </div>
                    </div>
                </div>
                <div className="horizontal-scroller-wrapper">
                    <div className="horizontal-scroller pr-2">
                        <div id="horizontal-scroller" data-rbd-droppable-id="all-columns">

                            {columnPreloader()}

                        </div>
                    </div>

                </div>
            </div>
        );
    }
    return (
        <div className="tracker">
            <div className="d-flex page-main-heading mb-2" onClick={() => setTest(Math.random() * 5)}>
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Tracker</h2>
                <div className="align-self-center mt-1 badge bg-warning fw-normal text-dark">
                    <b>Shift</b>
                    <span> + </span>
                    <b>Scroll</b>
                    <span> to Scroll Horizontally</span>
                </div>
                <div className="d-flex align-self-center mt-1 mx-2 shift-mouse-scroll">
                    <div>
                        <BsFillShiftFill />
                    </div>
                    <div className="align-self-center">
                        +
                    </div>
                    <div className="d-flex flex-column">
                        <BsChevronDoubleUp size="7" style={{width: "100%"}} />
                        <RiMouseFill size="20" />
                        <BsChevronDoubleDown size="7" style={{width: "100%"}} />
                    </div>
                </div>
            </div>
            <div className="horizontal-scroller-wrapper">
                {tracker?.modifyTask &&
                    <ModifyTask
                        show={tracker?.modalShow}
                        onHide={modalClose}
                    />
                }
                <div className="horizontal-scroller pr-2">
                    <DragDropContext onDragEnd={OnDragEnd}>
                        <Droppable id="horizontal-scroller" droppableId="all-columns" direction="horizontal" type="column">
                            {(provided) => (
                                <div {...provided?.droppableProps} ref={provided?.innerRef}>
                                    {tracker?.columnOrder && tracker?.columnOrder?.map((columnId, index) => {
                                        const column = tracker?.columns[columnId];
                                        const tasks = column?.taskIds?.map(taskId => tracker?.tasks[taskId]);
                                        return <Column key={columnId} column={column} tasks={tasks} inputs={tracker?.inputList} index={index} inputList={tracker?.inputList} />;
                                    })} {provided?.placeholder}
                                    <div className="tracker-column column-add-column">
                                        <div className="input-group flex-nowrap">
                                            <input className="form-control border" onChange={(e) => columnInputChangeFunc(e)} name="column" type="text" placeholder="Add Column" />
                                            {tracker?.columnInputValue ?
                                                <Button onClick={addColumnFunc} variant="btn btn-light border">Add</Button>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    )
}

export default Tracker;

