import React, {useEffect, useState} from "react";
import "./system-info-pages.css";
import "./system-info.css"
import "../../../../assets/css/custom-info.css";
import {Link, useLocation, Outlet, useNavigate, useParams} from "react-router-dom";
import {FaBrain, FaChevronDown, FaCircle, FaClipboardList, FaCode, FaInfoCircle, FaMicrochip} from "react-icons/fa";
import {breadcrumbs, results, sessions} from "../../../../Redux/Actions/results";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {BASE_URL} from "../../../Constants";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";


const resultsMenuArray = [
    {
        id: "test-results",
        name: "TEST RESULTS",
        icon:
            <FaBrain />,
        open: false,
        subMenu: [
            {
                id: "wstp3",
                name: "Winstress Pro (WSTP3)",
                link: "wstp",
                selected: false,
                hidden: true
            },
            {
                id: "uav",
                name: "UAV Rescue",
                link: "uav",
                selected: false,
                hidden: true
            },
            {
                id: "analysis",
                name: "Analysis Results",
                link: "analysis-results",
                selected: false,
                hidden: true
            },
            {
                id: "checkup",
                name: "Checkup Results",
                link: "checkup-results",
                selected: false,
                hidden: true
            },
            {
                id: "interactive",
                name: "Interactive Test Results",
                link: "interactive-results",
                selected: false,
                hidden: true
            },
            {
                id: "system-eval",
                name: "System Eval",
                link: "system-eval",
                selected: false,
                hidden: true
            },
            {
                id: "healthcheck",
                name: "Healthcheck",
                link: "healthcheck",
                selected: false,
                hidden: true
            },
        ]
    },
    {
        id: "hardware",
        name: "HARDWARE",
        icon:
            <FaMicrochip />,
        open: true,
        subMenu: [
            {
                name: "System Overview",
                link: "system-overview",
                selected: true
            },
            {
                name: "Enabled Devices",
                link: "enabled-devices",
                selected: false
            },
            {
                name: "Disabled Devices",
                link: "disabled-devices",
                selected: false
            }
        ]
    },
    {
        id: "software",
        name: "SOFTWARE",
        icon:
            <FaCode />,
        open: false,
        subMenu: [
            {
                name: "Installed Applications",
                link: "installed-applications",
                selected: false
            },
            {
                name: "Installed Hot fixes",
                link: "installed-hotfixes",
                selected: false
            },
            {
                name: "Device Drivers",
                link: "device-drivers",
                selected: false
            },
            {
                name: "System Services",
                link: "system-services",
                selected: false
            },
            {
                name: "Running Processes",
                link: "running-processes",
                selected: false
            },
            {
                name: "OS Configuration",
                link: "os-configuration",
                selected: false
            }
        ]
    },
    {
        id: "logs",
        name: "LOGS",
        icon:
            <FaClipboardList />,
        open: false,
        subMenu: [
            {
                name: "Application Failures",
                link: "application-failures",
                selected: false
            },
            {
                name: "Unexpected Shutdowns",
                link: "unexpected-shutdowns",
                selected: false
            }
        ]
    },
];
const baseURL = BASE_URL;
const phpRoute = "full-result/";


const SystemInfo = () => {
    const {sessionID} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const resultsState = useSelector(state => state?.results);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const handleMenuFolding = (name) => {
        dispatch(results({
                resultsMenuArray: resultsState?.resultsMenuArray?.map(item => {
                    if (item.name === name) {
                        return {
                            ...item,
                            open: true
                        }
                    }
                    return {
                        ...item,
                        open: false
                    }
                })
            }
        ));
    }
    const handleSubMenu = (name, subName) => {
        dispatch(results({
                resultsMenuArray: resultsState?.resultsMenuArray?.map(item => {
                    if (item.name === name) {
                        return {
                            ...item,
                            subMenu: item?.subMenu?.map(item => {
                                if (item?.name === subName) {
                                    return {
                                        ...item,
                                        selected: true
                                    }
                                }
                                return {
                                    ...item,
                                    selected: false
                                }
                            }),
                            open: true
                        }
                    }
                    return {
                        ...item,
                        subMenu: item?.subMenu?.map(item => {
                            return {
                                ...item,
                                selected: false
                            }
                        }),
                        open: false
                    }
                })
            }
        ));
    }
    const [resultsData, setResultsData] = useState();
    const [reportsData, setReportsData] = useState();
    const [testsData, setTestsData] = useState();
    const [userData, setUserData] = useState();
    useEffect(() => {
        dispatch(results({
            resultsMenuArray: resultsMenuArray
        }));
    }, []);


    const displayTestMenuItem = (parentId, menuItem, index) => {
        if (parentId !== "test-results") return true;
        try {
            //console.log(menuItem)
            return Object.values(testsData)?.some(item => {
                // uxdiscovery check
                if (item?.name === "uxdiscovery-diagnostics") {
                    const parsed = JSON.parse(item?.data);
                    return Object.entries(parsed).some(([i, value]) => {
                        console.log("Current entry:", i, value); // Logging current entry
                        if (i === menuItem?.id && menuItem?.id === "analysis") {
                            return value?.devices?.some(device => device?.status === "done")
                        }
                        if (i === menuItem?.id && menuItem?.id === "checkup") {
                            return value?.devices?.some(device => device?.status === "pass")
                        }
                        if (i === menuItem?.id && menuItem?.id === "interactive") {
                            return Object.keys(value).length > 0
                        }
                        return i === menuItem?.id && (Array.isArray(value) ? value.length > 0 : Object.keys(value).length > 0);
                    });
                }
                // Other tests
                //console.log(item?.name, menuItem?.id);
                return item?.name === menuItem?.id;
            });


        } catch (e) {
            console.log(e)
        }
        return false;
    }

    useEffect(() => {
        if (!resultsState?.breadcrumbs?.selectedSession) return;
        axios({
            url: baseURL + phpRoute + "?lang=" + localStorage.getItem("language"),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            data: JSON.stringify({
                hash: resultsState?.breadcrumbs?.selectedSession
            })
        }).then(res => {
            let response = res?.data;
            if (res?.data?.error) {
                setError(res?.data?.error)
                return;
            }
            //console.log("TESTS:", response)
            setResultsData(response?.device_data)
            let testData = response?.testResults && response?.testResults;
            setTestsData({...testData})
            let userData = response?.userData && response?.userData;
            setUserData({...userData})
        }).catch(err => {
            console.log(err)
        })

            .finally(() => {

                dispatch(sessions({
                    result: resultsData && JSON.parse(resultsData),
                    report: reportsData,
                    test: testsData,
                    user: userData
                }));
                setLoading(false);
            });
    }, [loading, resultsState?.breadcrumbs?.selectedSession]);

    if (error) {
        return (
            <div className="custom-info custom-info-light border m-3">
                <FaInfoCircle className="me-2" /> The session doesn't exist, which could mean that you have an incorrect URL or the administrator has removed the session.
            </div>
        )
    }
    if (loading) {
        return (
            <div className="mx-3">
                Loading...
            </div>
        )
    }

    return (
        <div className="system-info h-100">
            <div className="custom-info custom-info-light border m-3">
                <FaInfoCircle className="me-2" /> View a complete overview of your system's hardware and software, including its configuration and layout. It may also have test results.
            </div>

            <div className="system-info-content mx-3">
                <div className="system-info-content-menu-wrapper">
                    <div className="system-info-side-menu">

                        <div className="sticky pr-3">
                            {resultsState?.resultsMenuArray && resultsState?.resultsMenuArray?.map((item, i) =>
                                <div key={i} className="mb-3">
                                    <div className={`system-info-side-menu-parent ${item?.open ? "system-info-side-menu-parent-open" : "system-info-side-menu-parent-closed"}`} onClick={() => handleMenuFolding(item?.name)}>
                                        <div>
                                            {item?.icon}
                                        </div>
                                        <div>
                                            {item?.name}
                                        </div>
                                        <div>
                                            {item?.open ?
                                                <FaCircle /> :
                                                <FaChevronDown />}
                                        </div>
                                    </div>
                                    <div className={`${item?.open ? "system-info-side-menu-children-open" : "system-info-side-menu-children"}`}>
                                        {item?.subMenu.map((subItem, subi) =>
                                            <Link
                                                to={subItem?.link}
                                                key={subi}
                                                onClick={() => handleSubMenu(item?.name, subItem?.name)}
                                                className={`system-info-side-menu-child ${subItem?.selected ? "selected" : ""} ${displayTestMenuItem(item?.id, subItem, subi) ? "" : "d-none"}`}
                                            >
                                                <div>

                                                </div>
                                                <div className="pl-1">
                                                    {subItem?.name}
                                                </div>
                                                <div>

                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                    <div className="system-info-content">
                        <Outlet />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default SystemInfo;
