import React from "react";
import "./checkup-test-results.css"
import {useSelector} from "react-redux";
import {FaCheck, FaInfoCircle, FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";

const CheckupTestResults = ({deviceData, testData}) => {
    console.log("CHECKUP: ", JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.checkup);
    let checkup = [];
    try {
        checkup = JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.checkup;
    } catch (e) {
        return <div className="custom-info custom-info-light mb-3">
            <FaInfoCircle className="me-2" /> Mouse Interactive Test was not performed
        </div>
    }

    // const diagnostics = useSelector((state) => state?.diagnostics);
    // const checkup = diagnostics?.checkup

    const itemStatus = (device) => {
        let beautified = device?.status;
        if (device?.status === "canceled") {
            beautified =
                <span className="text-dark">Canceled <FaTimes /></span>
        }
        if (device?.status === "testing") {
            beautified =
                <span className="text-warning">In Progress</span>
        }
        if (device?.status === "pass") {
            beautified =
                <span className="text-success">Passed <FaCheck /></span>
        }
        if (device?.status === "fail") {
            beautified =
                <span className="text-danger">Failed <FaTimes /></span>
        }
        if (device?.status === "skipped") {
            beautified =
                <span className="text-secondary">---</span>
        }
        return beautified;
    }

    if (!checkup?.results) {
        return (
            <div className="custom-info custom-info-light">
                <FaInfoCircle className="me-2" /> Please run <Link className="btn-analysis" to="/checkup">Checkup</Link> to generate report
            </div>
        )
    }

    return (
        <div className="checkup checkup-test-results h-100">
            <div className="checkup-test-results-heading d-flex justify-content-between">
                <div className="align-self-center m-0">Checkup Test Results</div>
                <div>Time Elapsed: {checkup?.latestTestDuration}</div>
            </div>
            {checkup?.devices && checkup?.devices?.map((device, devicei) =>
                <div key={devicei} className="d-flex gap-2 p-2 my-3 checkup-parent-flex">
                    <div className="checkup-flex-item checkup-device-image">
                        <img src={process.env.PUBLIC_URL + `/assets/images/icons/${device?.id}.svg`} alt="" />
                    </div>
                    <div className="checkup-flex-item pl-3">
                                <span className="text-capitalize font-weight-bold me-1">
                                    {device?.id}
                                </span>
                        ({device?.subtest}) {/*<div>*/} {/*    <small>*/} {/*        {device?.name ?? Object.values(device?.each).map((one, onei) =>*/} {/*            <span key={onei} className="me-3">*/} {/*                        <span className="font-weight-bold me-1">{onei + 1})</span>*/} {/*                    </span>*/} {/*        )}*/} {/*    </small>*/} {/*</div>*/}
                    </div>

                    <div className="checkup-flex-item text-right text-capitalize">

                        {itemStatus(device)}
                    </div>
                </div>
            )}
        </div>
    )
}

export default CheckupTestResults;