import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {placeholderRandomizer} from "../../Redux/Actions/global";

const PlaceholderRandomizer = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global)
    const resultsState = useSelector(state => state?.results)
    const sessionsAmount = resultsState?.licenses

    const randomNumber = (min, max) => {
        return Math.round(Math.random() * (max - min) + min);
    }

    const numberArray = (min, max) => {
        let array = [];
        for (let i = min; i <= max; i++) {
            array.push(i)
        }
        return array;
    }

    // const licenseCols = (cols, colMin = 1, colMax = 8) => {
    //     let colsArray = [];
    //     for (let c = 1; c <= cols; c++) {
    //         colsArray.push({
    //             length: Math.round(Math.random() * (colMax - colMin) + colMin),
    //         })
    //     }
    //     return colsArray;
    // }

    // const licenseArrayRandomizer = (min, max, cols, colMin = 1, colMax = 8) => {
    //     let array = [];
    //     for (let i = min; i <= max; i++) {
    //         array.push({
    //             id: i,
    //             cols: licenseCols(cols, colMin, colMax)
    //         })
    //     }
    //     return array;
    // }

    const sessionsMajorTestsRandomizer = () => {
        let tests = [];
        let testTypes = ["warning", "success", "danger"]
        let amount = Math.round(Math.random() * 2)
        for (let i = 0; i <= amount; i++) {
            tests.push({
                id: i,
                type: testTypes[Math.round(Math.random() * 2)]
            });
        }
        return tests;
    }
    const sessionsArrayRandomizer = (min, max) => {
        let array = [];
        for (let i = min; i <= max; i++) {
            array.push({
                id: i,
                tests: sessionsMajorTestsRandomizer()
            })
        }
        return array;
    }

    useEffect(() => {
        // dispatch(placeholderRandomizer({
        //     placeholderRandomizer: {
        //         //licenses: licenseArrayRandomizer(1, 3, 3, 3, 8),
        //         sessions: sessionsArrayRandomizer(1, sessionsAmount),
        //     }
        // }))
    }, [sessionsAmount]);

    return (
        <React.Fragment>

        </React.Fragment>
    )
}


export default PlaceholderRandomizer;