export const login = (loginStatus) => {
    return {
        type: 'SET_LOGIN_STATUS',
        data: loginStatus
    };
};

export const logout = (loginStatus) => {
    return {
        type: 'SET_LOGOUT_STATUS',
        data: loginStatus
    };
};

