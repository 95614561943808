import React from "react";
import "./system-services.css";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const SystemServices = ({data}) => {

    const systemServices = data?.software?.systemServices;
    if (typeof systemServices === "undefined") {
        return (
            <div className="system-info-page system-services">
                <div className="system-overview system-services-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">System Services</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                <ContentPreloader theme="danger" forContent="each" text="Please wait while System Services is being loaded..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const servicesArray = systemServices

    //console.log(servicesArray)

    return (
        <div className="system-info-page system-services">
            <div className="system-overview system-services-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">System Services</div>
                </div>
                <div>
                    <div className="system-info-row position-relative text-orange">
                        <div className="drive-number-no-margin me-3 align-self-center system-info-th text-orange">
                            #
                        </div>
                        <div className="drive-number-no-margin device-in-loop-parts system-info-th font-italic">
                            <div className="text-orange">
                                Name
                            </div>
                            <div className="text-orange">
                                Description
                            </div>
                            <div className="text-orange text-right">
                                Details
                            </div>
                        </div>
                    </div>
                    {servicesArray && Array.isArray(servicesArray) && servicesArray.map((service, servicei) =>
                        <div className="system-info-row position-relative" key={servicei}>
                            <div className="drive-number align-self-center system-info-td">
                                {servicei + 1}
                            </div>
                            <div className="device-in-loop-parts system-info-td" style={{wordWrap: "break-word", wordBreak: "break-all"}}>
                                <div className="text-success">
                                    {service?.Name}
                                </div>
                                <div className="text-orange" style={{wordWrap: "break-word", wordBreak: "break-all"}}>
                                    <span className="monospaced-value">{service?.Caption}</span>
                                </div>
                                <div className="text-orange text-right">
                                    <OverlayTrigger
                                        key="left"
                                        placement="left"
                                        overlay={
                                            <Tooltip id={`tooltip-left`}>
                                                <div className="text" style={{
                                                    maxWidth: "250px",
                                                    wordWrap: "break-word"
                                                }}
                                                >
                                                    {service?.Description}
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <button className="btn btn-success btn-sm">Details</button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SystemServices;
