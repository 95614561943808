import React from "react";
import {useSelector} from "react-redux";
import {FaCheck, FaInfoCircle, FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";

const MouseTestResults = ({testData}) => {
    //console.log("INTERACTIVE: ", JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.interactive?.keyboard);
    let mouse = [];
    try {
        mouse = JSON.parse(Object.values(testData)?.find((item) => item?.name === "uxdiscovery-diagnostics")?.data)?.interactive?.mouse;
    } catch (e) {
        return <div className="custom-info custom-info-light mb-3">
            <FaInfoCircle className="me-2" /> Mouse Interactive Test was not performed
        </div>
    }

    if (!mouse) {
        return (
            <div className="custom-info custom-info-light mb-3">
                <FaInfoCircle className="me-2" /> Mouse Interactive Test was not performed
            </div>
        )
    }

    return (
        <div className="mb-3">
            <div className="analysis-test-results">
                <div className="analysis-test-results-heading">
                    <div className="align-self-center m-0">Mouse Test Result(s)</div>
                    <div className="align-self-center">

                    </div>
                </div>

                <hr className="mt-0" />
                {mouse && mouse?.tests.map((test, testi) =>
                    <div key={test?.title + "-" + testi}>
                        <b className="text-secondary">{test?.title}</b>
                        : {test?.result === "success" ?
                        <FaCheck className="text-success" /> :
                        <FaTimes className="text-danger" />}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MouseTestResults;