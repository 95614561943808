import React from "react";
import "./installed-applications.css";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const InstalledApplications = ({data}) => {

    const installedApplications = data?.registry;

    if (typeof installedApplications === "undefined") {
        return (
            <div className="system-info-page installed-applications">
                <div className="system-overview installed-applications-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Installed Applications</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                <ContentPreloader theme="danger" forContent="each" text="Please wait while Installed Applications is being loaded..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const appsArray = installedApplications;
    //console.log(appsArray)

    return (
        <div className="system-info-page installed-applications">
            <div className="system-overview installed-applications-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">Installed Applications</div>
                </div>
                <div>
                    <div className="system-info-row position-relative text-orange">
                        <div className="drive-number-no-margin me-3 align-self-center system-info-th text-orange">
                            #
                        </div>
                        <div className="drive-number-no-margin device-in-loop-parts system-info-th font-italic">
                            <div className="text-orange">
                                Application
                            </div>
                            <div className="text-orange">
                                Vendor
                            </div>
                            <div className="text-orange">
                                Version
                            </div>
                            <div className="text-orange">
                                Install Date
                            </div>
                        </div>
                    </div>
                    {appsArray && Array.isArray(appsArray) && appsArray.map((instApps, instAppsi) =>
                        <div className="system-info-row position-relative" key={instAppsi}>
                            <div className="drive-number align-self-center system-info-td">
                                {instAppsi + 1}
                            </div>
                            <div className="device-in-loop-parts system-info-td">
                                <div className="text-success">
                                    {instApps?.name}
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{instApps?.publisher}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{instApps?.version}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{instApps?.installLocation}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{instApps?.installDate}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default InstalledApplications;
