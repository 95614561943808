import React, {useEffect, useState} from "react";
import {BASE_URL} from "../../../../Constants";
import axiosRetry from "axios-retry";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {RiLogoutBoxLine} from "react-icons/ri";

const baseURL = BASE_URL;
const phpRoute = "logout-user-active-session/";

const retries = 10;
//status 3 => initial
//status 2 => loading
//status 1 => success
//status 0 => error
const LogSessionOut = ({hash}) => {
    const dispatch = useDispatch();
    const [retried, setRetried] = useState(0);
    const loginState = useSelector(state => state?.login)
    const [status, setStatus] = useState(3);

    const deleteSession = () => {
        setStatus(2)
        axiosRetry(axios, {
            retries: retries,
            shouldResetTimeout: true,
            retryCondition: (_error) => true, // retry no matter what
            retryDelay: (retryDelay) => {
                setRetried(retried => retried + 1)
                //return retryDelay * 1000;
                return 1000;
            }
        });
        axios({
                url: baseURL + phpRoute,
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                data: JSON.stringify({
                    email: loginState?.email,
                    hash: hash
                })
            }
        ).then(res => {
            setTimeout(() => {
                console.log("Server:", res?.data === 1 ? "Someone was Successfully Logged Out" : "Sorry can not logout now...")
                console.error(res?.data);
                setStatus(res?.data);
            }, 1000)
        }).catch((err) => {
            console.log("About Retried:", retried)
            console.error("Please wait... ", err);
        })
    }

    const LogoutSessionBtn = () => {
        if (status === 2) {
            return <div className="btn btn-warning disabled">
                <div className="spinner-border spinner-border-sm" role="status"></div>
            </div>;
        }
        if (status === 1) {
            return <div className="btn btn-success disabled">Logout was successful...</div>;
        }
        if (status === 0) {
            return <div className="btn btn-danger disabled">There was a problem. Please try later...</div>;
        }
        return <button className="btn btn-danger" onClick={deleteSession}>
            <RiLogoutBoxLine /> Log Out
        </button>
    }

    return (
        <div>
            {LogoutSessionBtn()}
        </div>
    )
}

export default LogSessionOut;