export const dashboard = (dashboardData) => {
    return {
        type: 'DASHBOARD',
        data: dashboardData
    };
};

export const chart = (chartData) => {
    return {
        type: 'CHART',
        data: chartData
    };
};

export const dashTaskPriorityList = (dashTaskPriorityList) => {
    return {
        type: 'DASH_TASK_PRIORITY_LIST',
        data: dashTaskPriorityList
    };
};
