import {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import {closeModal} from '../../Redux/Actions/global';
// import {closeConnectItModal} from "../../Redux/Actions/connect-it"

export function formatDate(inputDate) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(inputDate);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour time to 12-hour time
    hours = hours % 12 || 12;

    const timezoneOffsetHours = Math.floor(date.getTimezoneOffset() / 60);
    const timezoneOffsetMinutes = Math.abs(date.getTimezoneOffset() % 60);
    const timezone = date.getTimezoneOffset() < 0 ? `UTC+${timezoneOffsetHours}:${timezoneOffsetMinutes}` : `UTC-${timezoneOffsetHours}:${timezoneOffsetMinutes}`;

    const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes}:${seconds} ${ampm}`// ${timezone}`;

    return formattedDate;
}

export function formatBytes(input, convertFrom = 'Bytes', decimals = 2) {
    if (isNaN(input) || input === null || input === undefined) {
        return 'Invalid input';
    }

    if (input < 0) {
        return 'Negative value';
    }

    if (!Number.isInteger(decimals) || decimals < 0) {
        return 'Invalid decimals';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const convertToBytes = (size) => {
        const i = sizes.indexOf(size);
        if (i === -1) {
            return NaN; // Invalid convertFrom value
        }
        return input * Math.pow(k, i);
    };

    if (convertFrom === 'Bytes') {
        const i = Math.floor(Math.log(input) / Math.log(k));
        return `${parseFloat((input / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    } else {
        const convertedBytes = convertToBytes(convertFrom);
        if (isNaN(convertedBytes)) {
            return 'Invalid convertFrom value';
        }
        const i = Math.floor(Math.log(convertedBytes) / Math.log(k));
        return `${parseFloat((convertedBytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }
}


export function formatBytesPure(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
}

export function formatStdoutDate(dateString) {
    try {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const hour = dateString.substring(8, 10);
        const minute = dateString.substring(10, 12);
        const second = dateString.substring(12, 14);
        return `${year}-${month}-${day} ${hour}:${minute}:${second} `;
    } catch (e) {
        return e
    }
}

export function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}


export function useCustomModal() {
    const dispatch = useDispatch();

    const globalCustomModal = useSelector(state => state?.global?.customModal);
    const connectItCustomModal = useSelector(state => state?.connectIt?.customModal);

    const [isLoading, setLoading] = useState(true);
    const containerRef = useRef(null);

    useEffect(() => {
        if (isLoading && containerRef && containerRef.current) setLoading(false);
    }, [isLoading]);

    const hideModal = () => {
        // dispatch(closeModal({customModal: {show: false}}));
        // dispatch(closeConnectItModal({customModal: {show: false}}));
    };

    const [customModalState, setCustomModalState] = useState();

    useEffect(() => {
        if (globalCustomModal !== undefined) setCustomModalState(globalCustomModal);
        if (connectItCustomModal !== undefined) setCustomModalState(connectItCustomModal);
    }, [globalCustomModal, connectItCustomModal]);

    return {
        isLoading,
        containerRef,
        hideModal,
        customModalState,
    };
}

export function limitTextByWords(text, maxWords) {
    const words = text.split(' ');
    if (words.length <= maxWords) {
        return text;
    }
    const limitedText = words.slice(0, maxWords).join(' ');
    return limitedText + '...';
}

export function getCurrentDate() {
    const date = new Date();

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const meridiem = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    const formattedDate = `${month}-${day}-${year} ${formattedHours}:${minutes}:${seconds} ${meridiem}`;

    return formattedDate;
}

export function extractFileNameFromPath(path) {
    try {
        console.log(path?.split('/').pop());
        return path?.split('/').pop()
    } catch (error) {
        console.log(error)
        return path;
    }
}

export function getLocalStorageItem(key, defaultValue) {
    let value;
    try {
        const storedValue = localStorage.getItem(key);
        value = storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
        console.error("Error accessing localStorage:", error);

        value = defaultValue;
    }
    return value;
}

export function encodeID(num, length = 10) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // 62 characters
    const base = charset.length; // Base 62 encoding
    let hash = '';

    // Generate initial hash
    while (num > 0) {
        hash = charset[num % base] + hash;
        num = Math.floor(num / base);
    }

    // Store the length of the original hash
    const originalHashLength = hash.length;

    // Add random characters if hash is shorter than the required length
    let randomPadding = '';
    while (hash.length + randomPadding.length < length) {
        const randomIndex = Math.floor(Math.random() * base);
        randomPadding = charset[randomIndex] + randomPadding;
    }

    // Ensure the hash is exactly the specified length
    if (hash.length + randomPadding.length > length) {
        randomPadding = randomPadding.substring(0, length - hash.length);
    }

    // Prepend the length of the original hash to the final hash to help with decoding
    hash = originalHashLength.toString().padStart(2, '0') + hash + randomPadding;

    return hash;
}

export function decodeID(hash) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // 62 characters
    const base = charset.length; // Base 62 encoding

    // Extract the original hash length from the first 2 characters
    const originalHashLength = parseInt(hash.substring(0, 2), 10);

    // Decode using only the characters corresponding to the original hash length
    const encodedPart = hash.substring(2, 2 + originalHashLength);

    let num = 0;
    for (let i = 0; i < encodedPart.length; i++) {
        num = num * base + charset.indexOf(encodedPart[i]);
    }

    return num;
}

