import React from "react";
import "./qtw.css";
import {BASE_URL} from "../../../../Constants";
import {FaInfoCircle} from "react-icons/fa";

const QTW = ({data}) => {
    const qtw = data && Object.values(data)?.length !== 0 && data?.filter(item => item?.file_name === "qtw")[0] && JSON.parse(data?.filter(item => item?.file_name === "qtw")[0]?.result_data);

    const chooseImage = (TestName) => {
        if (TestName.includes("CPU")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/cpu.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("RAM")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/ram.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Hard")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/hdd.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Motherboard")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/mainboard.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Display")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/monitor.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Sound")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/sound.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("USB")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/usb.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("COM")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/com.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("LPT")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/lpt.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("WebCam")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/webcam.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("KB")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/keyboard.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Network")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/network.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("WiFi")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/wifi.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Windows")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/win-error-log.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Mouse")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/mouse.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Network")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/network.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("Bluethooth")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/bluetooth.png`} className="me-3" alt="" />;
        }
        if (TestName.includes("0000")) {
            return <img src={`${BASE_URL}assets/images/icons/device-components/0000.png`} className="me-3" alt="" />;
        }


        return <div className="qtw-no-image align-self-center">
            <div className="position-absolute top-50 start-50 translate-middle text-center">
                No Image
            </div>
        </div>;
    }

    if (typeof qtw === "undefined" || Object.values(data)?.length === 0) {
        return (
            <div className="system-info-page qtw">
                <div className="system-info-page-heading mb-3 d-flex justify-content-between">
                    <div className="align-self-center m-0">QTW</div>
                    <img src={`${BASE_URL}assets/images/icons/device-components/qtw.png`} alt="qtw" />
                </div>
                <div className="custom-info custom-info-light border mb-3">
                    <FaInfoCircle /> Sorry there is no data for QTW
                </div>
            </div>
        )
    }
    return (
        <div className="system-info-page qtw">
            <div className="system-info-page-heading mb-3 d-flex justify-content-between">
                <div className="align-self-center m-0">QTW</div>
                <img src={`${BASE_URL}assets/images/icons/device-components/qtw.png`} alt="qtw" />
            </div>
            <div className="custom-info custom-info-light border mb-3 py-3">
                {qtw?.DetailedInformation && Object.values(qtw?.DetailedInformation).map((det, deti) =>
                    <div key={deti} className="qtw-result-parent parent">
                        <div className="qtw-result-child d-flex">
                            {chooseImage(det?.TestName)}
                            <h5 className="ms-3 align-self-center">
                                {det?.TestName}
                            </h5>
                        </div>
                        <div className="qtw-result-child">
                            {det?.TestResult === "Pass" ?
                                <span className="badge bg-success">{det?.TestResult}</span>
                                :
                                <span className="badge bg-danger">{det?.TestResult}</span>
                            }
                        </div>
                        <div className="qtw-result-child text-right qtw-vertical-scroller">
                            {det?.SubtestsResult && Object.values(det?.SubtestsResult).map((sub, subi) =>
                                <div key={subi} className="qtw-result-parent">
                                    <div className="me-3 qtw-result-child">
                                        {sub?.SubtestName}
                                    </div>
                                    <div className="qtw-result-child">
                                        {sub?.SubtestResult === "Pass" ?
                                            <span className="badge bg-success">{sub?.SubtestResult}</span>
                                            :
                                            <span className="badge bg-danger">{sub?.SubtestResult}</span>
                                        }

                                        {sub?.SubtestName.includes("Speed") ?
                                            <span className="badge bg-light text-dark ms-1">
                                                Read Spd: {sub?.SubtestResult?.ReadSpeed ? sub?.SubtestResult?.ReadSpeed : 0} | Write Spd: {sub?.SubtestResult?.WriteSpeed ? sub?.SubtestResult?.WriteSpeed : 0}
                                            </span>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default QTW;