import React from "react";
import Puzzle15 from "./Puzzle15/Puzzle15";

const Relax = () => {
    return (
        <div className="relax">
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Relax</h2>
            </div>

            <div className="mx-3">

                <div>
                    <Puzzle15 />
                </div>
            </div>
        </div>
    )
}

export default Relax;