import React, {Component, useEffect, useState} from "react";
import axios from "axios";
import {connect, useDispatch, useSelector} from "react-redux";
import {BASE_URL} from "../../../Constants";
import "./logo-upload.css";
import {LogoUpdate} from "../../../../Redux/Actions/settings";
import {global} from "../../../../Redux/Actions/global";
import {Spinner} from "react-bootstrap";

const baseURL = BASE_URL;
const phpRoute = "uportal-settings/";

const FileUploadForm = () => {
    const dispatch = useDispatch();
    const settingsState = useSelector(state => state?.settings);
    const loginState = useSelector(state => state?.login);
    const globalState = useSelector(state => state?.global);

    const [loading, setLoading] = useState(false);
    const [logoUploadStatus, setLogoUploadStatus] = useState(false);
    const [state, setState] = useState({
        image_file: null,
        image_preview: null,
        labelFileName: "Please select file first (JPG, JPEG, PNG)"
    });

    useEffect(() => {
        if (!settingsState?.logo) return;
        setState({
            ...state,
            image_preview: baseURL + "assets/images/user/company/" + settingsState?.logo
        })
    }, [settingsState?.logo]);

    useEffect(() => {
        if (!logoUploadStatus) return;
        setLoading(true);
        const timeout = setTimeout(() => {
            setLoading(false);
            dispatch(global({
                alerts: [
                    ...globalState?.alerts ?? "",
                    {
                        id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                        type: settingsState?.logoUploadMessageType,
                        title: "Logo Upload",
                        message: settingsState?.logoUploadMessage,
                        show: true,
                        dismissible: true,
                        date: new Date(),
                    }
                ]
            }))
            setLogoUploadStatus(false)
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [logoUploadStatus]);

    const handleImagePreview = (e) => {
        let image_as_base64 = baseURL + "assets/images/user/company/no-logo.png";
        if (e?.target?.files?.length !== 0) {
            image_as_base64 = URL.createObjectURL(e?.target?.files[0]);
        }
        let image_as_files = e?.target?.files[0];

        setState({
            ...state,
            image_preview: image_as_base64,
            image_file: image_as_files,
            labelFileName: e?.target?.files[0]?.name
        })
    }

    const handleSubmitFile = () => {
        if (state?.image_file !== null) {
            let formData = new FormData();
            formData.append('logo', state?.image_file);
            formData.append('email', loginState?.email);
            formData.append('type', "logo_upload");

            axios.post(baseURL + phpRoute + "?lang=" + localStorage.getItem("language"),
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                }
            )
                .then(res => {
                    dispatch(LogoUpdate({
                        logo: res?.data?.file_name,
                        logoUploadMessageType: res?.data?.messageType,
                        logoUploadMessage: res?.data?.message,
                    }));
                })
                .catch(err => console.log(err))
                .finally(() => setLogoUploadStatus(true))
        }
    }


    return (
        <div className="position-relative">
            <div className="upload-logo-image-wrapper">
                <div className="me-2 mb-2 preview-company-logo">
                    <div className="logo-preview-corners">
                        <div className="contain" style={{backgroundImage: `url("${state?.image_preview}")`}} />
                    </div>
                </div>
            </div>

            <div className="upload-logo-custom-input">
                <div className="custom-file">
                    <input type="file" onChange={handleImagePreview} className="custom-file-input" />
                    <label className="custom-file-label d-block">{state?.labelFileName}</label>
                </div>
                <button className={`btn btn-secondary container-fluid`} style={{marginTop: "6px"}} onClick={handleSubmitFile} disabled={!!loading}>
                    {loading ?
                        <Spinner animation="grow" variant="light" size="sm" /> : ""} Upload Logo {loading ?
                    <Spinner animation="grow" variant="light" size="sm" /> : ""}
                </button>
            </div>
        </div>
    );
}

export default FileUploadForm;