import "./theme.css";
import {BsFillMoonFill, BsFillSunFill} from "react-icons/bs";
import React from "react";
import {global} from "../../../Redux/Actions/global";
import {useDispatch, useSelector} from "react-redux";

const Theme = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global)

    const toggleTheme = () => {
        dispatch(global({
            theme: globalState?.theme === "light" ? "dark" : "light",
            // alerts: [
            //     ...globalState?.alerts ?? "",
            //     {
            //         id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
            //         type: "success",
            //         title: "Global Theme",
            //         message: "Theme was changed successfully",
            //         show: true,
            //         dismissible: true,
            //         date: new Date(),
            //     }
            // ]
        }))
    }
    return (
        <div className="theme" onClick={toggleTheme}>
            {globalState?.theme === "light"
                ?
                <BsFillMoonFill size="20" className="theme-icon cursor-pointer text-secondary " />
                :
                <BsFillSunFill size="25" className="theme-icon cursor-pointer text-warning" />
            }
        </div>
    )
}
export default Theme;