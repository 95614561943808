import React, {useEffect, useState} from 'react';
import "./settings.css";
import {BASE_URL} from "../../Constants";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import LogoUpload from "./LogoUpload/LogoUpload";
import {initSettings} from "../../../Redux/Actions/settings";
import Tagline from "./Tagline/Tagline";

const baseURL = BASE_URL;
const phpRoute = "uportal-settings/";

const Settings = () => {
    const dispatch = useDispatch();
    const loginState = useSelector(state => state?.login);

    useEffect(() => {
        axios({
            method: 'post',
            url: baseURL + phpRoute + "?lang=" + localStorage.getItem("language"),
            data: JSON.stringify({
                email: loginState?.email
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => {
            dispatch(initSettings({
                ...res?.data
            }));
        }).catch(err => console.log(err))
    }, []);

    return (
        <div className="settings">
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Settings</h2>
            </div>

            <div className="settings-content-wrapper">
                <div className="mb-4">
                    <Tagline />
                </div>
                <div className="mb-4">
                    <LogoUpload />
                </div>
            </div>
        </div>
    )
}


export default Settings;