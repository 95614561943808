import React, {useEffect, useState} from "react";
import "./puzzle-15.css";
import Puzzle15Timer from "./Puzzle15Timer/Puzzle15Timer";
import {IoShuffle} from "react-icons/io5";
import {AiOutlineCheck} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {puzzle15} from "../../../../Redux/Actions/relax";
import RelaxWin from "../RelaxWin/RelaxWin";

const puzzleNumbers = [
    [
        {digit: 3},
        {digit: 2},
        {digit: 1},
        {digit: 4}
    ],
    [
        {digit: 5},
        {digit: 6},
        {digit: 7},
        {digit: 8}
    ],
    [
        {digit: 9},
        {digit: 10},
        {digit: 11},
        {digit: 12}
    ],
    [
        {digit: 0},
        {digit: 13},
        {digit: 14},
        {digit: 15},
    ]
]

const Puzzle15 = () => {
    const dispatch = useDispatch();
    const relaxState = useSelector(state => state?.relax);
    const puzzle15State = useSelector(state => state?.relax?.puzzle15);
    const board = useSelector(state => state?.relax?.puzzle15?.board);
    const [animate, setAnimate] = useState({
        digit: 99999,
        direction: ""
    })

    useEffect(() => {
        if (board) return;
        dispatch(puzzle15({
            ...relaxState,
            puzzle15: {
                ...puzzle15State,
                board: puzzleNumbers
            }
        }));
    }, []);
    useEffect(() => {
        setAnimate({
            digit: 99999,
            direction: ""
        })

        let status = checkWin(board);
        if (status !== "win") return;

        dispatch(puzzle15({
            ...relaxState,
            puzzle15: {
                ...puzzle15State,
                status: status
            }
        }));
    }, [board]);
    useEffect(() => {
        if (animate?.digit === 99999) return;
        const timeout = setTimeout(() => {
            dispatch(puzzle15({
                ...relaxState,
                puzzle15: {
                    ...puzzle15State,
                    board: board && board?.map(row => {
                        return row?.map(number => {
                            if (number?.digit === 0) {
                                return {
                                    ...number,
                                    digit: animate?.digit
                                }
                            }
                            if (number?.digit === animate?.digit) {
                                return {
                                    ...number,
                                    digit: 0
                                }
                            }
                            return {
                                ...number
                            }
                        })
                    })
                }
            }));

        }, 200)
        return () => clearTimeout(timeout);
    }, [animate])

    const findRow = (element, array) => {
        for (let i = 0; i <= array?.length; i++) {
            if (array[i]?.some(el => el?.digit === element)) {
                return i;
            }
        }
        return 0;
    }
    const findPosition = (element, array) => {
        let row = findRow(element, array);
        return array[row].findIndex(el => el?.digit === element);
    }
    const canMove = (row, pos, array) => {
        if (array[row][pos - 1]?.digit === 0) {
            return "left"
        }
        if (array[row][pos + 1]?.digit === 0) {
            return "right"
        }
        if (array[row - 1] && array[row - 1][pos]?.digit === 0) {
            return "top"
        }
        if (array[row + 1] && array[row + 1][pos]?.digit === 0) {
            return "bottom"
        }
        return "no";
    }
    const checkWin = (array) => {
        let newArray = array?.flat(1)?.map(number => {
            if (number?.digit === 0) {
                return {
                    ...number,
                    digit: 10000
                }
            }
            return {
                ...number
            }
        });
        if (newArray?.every((number, inumber) => {
            //console.log(number?.digit + " - " + newArray[inumber - 1]?.digit)
            return inumber === 0 || number?.digit >= newArray[inumber - 1]?.digit;
        })) {
            return "win";
        }
        return "nowin";
    }
    const move = (digit) => {
        let move = canMove(findRow(digit, board), findPosition(digit, board), board);

        if (puzzle15State?.isPaused) {
            dispatch(puzzle15({
                ...relaxState,
                puzzle15: {
                    ...puzzle15State,
                    isPaused: false,
                    timer: puzzle15State?.currentTimer
                }
            }))
        }

        if (move === "no") return;

        setAnimate({
            digit: digit,
            direction: move
        })
    }
    const calcAnim = (digit) => {
        if (animate?.digit === digit) {
            return animate?.direction
        }
    }

    const solve = () => {
        let newArray = [];
        let flatArray = board?.flat(2).filter(item => item?.digit !== 0).sort((a, b) => a.digit - b.digit);
        flatArray.push({digit: 0});

        for (let i = 0; i < flatArray?.length; i += 4) {
            newArray.push(flatArray.slice(i, i + 4));
        }

        dispatch(puzzle15({
            ...relaxState,
            puzzle15: {
                ...puzzle15State,
                board: newArray,
                timer: 0,
                currentTimer: 0,
                isPaused: true,
                interval: 0,
            }
        }));
        if (puzzle15State?.interval) {
            clearInterval(puzzle15State?.interval);
        }
    }
    const shuffle = () => {
        let newArray = board;

        for (let i = 0; i < newArray?.length; i++) {
            for (let j = 0; j < newArray[i]?.length; j++) {
                let i1 = Math.floor(Math.random() * (newArray?.length));
                let j1 = Math.floor(Math.random() * (newArray?.length));

                let temp = newArray[i][j];
                newArray[i][j] = newArray[i1][j1];
                newArray[i1][j1] = temp;
            }
        }

        dispatch(puzzle15({
            ...relaxState,
            puzzle15: {
                ...puzzle15State,
                board: newArray,
                timer: 0,
                currentTimer: 0,
                isPaused: true,
                interval: 0,
            }
        }));

        if (puzzle15State?.interval) {
            clearInterval(puzzle15State?.interval);
        }
    }

    return (
        <React.Fragment>
            <div className="puzzle15-wrapper">
                <RelaxWin />
                <div className="puzzle15">
                    <div>
                        <Puzzle15Timer />
                    </div>
                    {board && board?.map((row, irow) =>
                        <div className="puzzle15-number" key={irow}>
                            {row && row?.map((number, inumber) =>
                                <div key={inumber} className={`puzzle15-number-text`} onClick={() => move(number?.digit)}>
                                    <div key={inumber} className={`puzzle15-number-animate puzzle15-number-animate-${calcAnim(number?.digit)} ${number?.digit === 0 ? "puzzle15-number-zero" : ""}`}>
                                        <div className="puzzle15-number-text-digit">
                                            {number?.digit === 0 ? "" : number?.digit}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="d-flex justify-content-between puzzle15-controls">
                        <button className="btn btn-warning flex-grow-1 w-50"
                                onClick={shuffle}
                        >Shuffle <IoShuffle size="20px" />
                        </button>
                        <button className="btn btn-info flex-grow-1 w-50" onClick={solve}>Solve <AiOutlineCheck size="20px" />
                        </button>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default Puzzle15;
