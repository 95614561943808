import React from "react";
import "./wstp.css";
import {BASE_URL} from "../../../../Constants";
import {FaExclamationTriangle, FaInfoCircle} from "react-icons/fa";

const WSTP = ({data}) => {
    function removeTextKeys(obj) {
        if (!obj || typeof obj !== 'object') return obj;

        if (Array.isArray(obj)) {
            return obj.map(removeTextKeys);
        }

        let newObj = {};
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                if (key === '_text') {
                    newObj = obj[key];
                } else {
                    newObj[key] = removeTextKeys(obj[key]);
                }
            }
        }
        return newObj;
    }

    function deleteObject(obj, targetKey) {
        if (!obj || typeof obj !== 'object') return null;
        if (obj.hasOwnProperty(targetKey)) {
            let {[targetKey]: _, ...rest} = obj; // Destructure and omit the targetKey
            return rest;
        }
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                const deletionResult = deleteObject(obj[key], targetKey);
                if (deletionResult !== null) {
                    obj[key] = deletionResult;
                    return obj;
                }
            }
        }
        return null;
    }

    function findObjectWithValue(obj, targetValue) {
        if (!obj || typeof obj !== 'object') return null;

        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'object') {
                    const nestedResult = findObjectWithValue(obj[key], targetValue);
                    if (nestedResult) {
                        return nestedResult;
                    }
                } else if (obj[key]?.includes(targetValue) || obj[key] === targetValue) {
                    try {
                        const parsed = JSON.parse(obj?.data);

                        return {
                            results: removeTextKeys(parsed?.diagnostics?.diagnosticsresults),
                            other: removeTextKeys(deleteObject(parsed?.diagnostics, "diagnosticsresults")),
                        };
                    } catch (e) {
                        console.error("JSON parsing failed:", e);
                        return null;
                    }
                }
            }
        }
        return null;
    }

    let wstpusb = [];
    try {
        wstpusb = findObjectWithValue(data, "WSTP");
    } catch (e) {
        console.error("Error finding WSTP data:", e);
        return <div>{e?.message || "An error occurred."}</div>;
    }

    if (!wstpusb) {
        return (
            <div className="system-info-page wstpusb">
                <div className="system-info-page-heading mb-3 d-flex justify-content-between">
                    <div className="align-self-center m-0">Winstress Pro (WSTP3)</div>
                    <img src={`${BASE_URL}assets/images/icons/device-components/wstpusb.png`} alt="wstpusb" />
                </div>
                <div className="custom-info custom-info-light border mb-3">
                    <FaInfoCircle /> Sorry, there is no data for WSTP3.
                </div>
            </div>
        );
    }

    const conditionalView = (obj, objhtml) => (obj !== undefined && obj !== null ? objhtml : null);

    const getKeyByValue = (object, value) =>
        Object.keys(object).find((key) => object[key] === value);

    const findError = (object, name) => {
        if (!Array.isArray(object?.errors)) return null;

        let returnable = object?.errors.map((error) => {
            if (!Array.isArray(error)) return null;
            const foundError = error.find((item) => item?.name.toLowerCase() === name);
            return foundError ? foundError.description : "noerror";
        });

        if (returnable?.[0] === "noerror") return null;
        return (
            <div className="alert alert-danger py-0 px-2">
                <FaExclamationTriangle className="me-1" /> {returnable}
            </div>
        );
    };
    return (
        <div className="system-info-page wstpusb">
            <div className="system-info-page-heading mb-3 d-flex justify-content-between">
                <div className="align-self-center m-0">Winstress Pro (WSTP3)</div>
                <img src={`${BASE_URL}assets/images/icons/device-components/wstpusb.png`} alt="wstpusb" />
            </div>
            <div className="wstpusb-badge-statuses text-center">
                <span className={`badge bg-${wstpusb?.GLOBAL_RESULT === "PASS" ? "success" : "danger"}`}>
                    <b className="me-1">
                        Global Status:
                    </b>
                    {wstpusb?.other?.globalresult}
                </span>
                <span className="badge bg-primary">
                    <b className="me-1">
                        Elapsed Time:
                    </b>
                    {wstpusb?.other?.elapsedtime}
                </span>
                <span className="badge bg-secondary">
                    <b className="me-1">Upload Date:</b>
                    {wstpusb?.other?.date}
                </span>
            </div>

            <div className="wstpusb-test-items">
                <div className="wstpusb-test-item">
                    {conditionalView(wstpusb?.results?.systemcategory?.processors?.processor0,
                        <div className="wstpusb-test-item-processor">
                            <div className="wstpusb-test-item-flex-inside">
                                <h4>
                                    <img src={`${BASE_URL}assets/images/icons/device-components/cpu.png`} className="me-3" alt="" />
                                    Processor
                                </h4>
                            </div>
                            <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-heading">
                                <div className="text-center">
                                    Status
                                </div>
                                <div className="text-center">
                                    # of Loops
                                </div>
                            </div>
                            <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                <div className="text-center">
                                    {wstpusb?.results?.systemcategory?.processors?.processor0?.result === "Pass" ?
                                        <span className="badge bg-success">{wstpusb?.results?.systemcategory?.processors?.processor0?.result}</span>
                                        :
                                        <span className="badge bg-danger">{wstpusb?.results?.systemcategory?.processors?.processor0?.result}</span>
                                    }
                                </div>
                                <div className="text-center">
                                    {wstpusb?.results?.systemcategory?.processors?.processor0?.finishedloops}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="wstpusb-test-item">
                    {conditionalView(wstpusb?.results?.storagecategory?.fixedphysicaldrives,
                        <div className="wstpusb-test-item-device">
                            <div className="wstpusb-test-item-flex-inside">
                                <h4>
                                    <img src={`${BASE_URL}assets/images/icons/device-components/hdd.png`} className="me-3" alt="" />
                                    Data Storage
                                </h4>
                            </div>
                            <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-heading">
                                <div>
                                    Name
                                </div>
                                <div className="text-center">
                                    Status
                                </div>
                                <div className="text-center">
                                    # of Loops
                                </div>
                            </div>
                            <div>
                                {wstpusb?.results?.storagecategory?.fixedphysicaldrives?.drive && (Array.isArray(wstpusb?.results?.storagecategory?.fixedphysicaldrives?.drive) ? wstpusb?.results?.storagecategory?.fixedphysicaldrives?.drive : Object.values(wstpusb?.results?.storagecategory?.fixedphysicaldrives?.drive)).map((hdd, hddi) =>
                                    <div key={hddi} className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                        <div>
                                            {hdd?.name}
                                        </div>
                                        <div className="text-center">
                                            {hdd?.result === "Pass" ?
                                                <span className="badge bg-success">{hdd?.result}</span>
                                                :
                                                <span className="badge bg-danger">{hdd?.result}</span>
                                            }
                                        </div>
                                        <div className="text-center">
                                            {hdd?.finishedloops}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="wstpusb-test-item">
                    {conditionalView(wstpusb?.results?.systemcategory?.mainboard,
                        <div className="wstpusb-test-item-mainboard">
                            <div className="wstpusb-test-item-flex-inside">
                                <h4>
                                    <img src={`${BASE_URL}assets/images/icons/device-components/mainboard.png`} className="me-3" alt="" />
                                    Mainboard
                                </h4>
                            </div>
                            <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-heading">
                                <div className="text-center">
                                    Status
                                </div>
                                <div className="text-center">
                                    # of Loops
                                </div>
                            </div>
                            <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                <div className="text-center">
                                    {wstpusb?.results?.systemcategory?.mainboard?.result === "Pass" ?
                                        <span className="badge bg-success">{wstpusb?.results?.systemcategory?.mainboard.result}</span>
                                        :
                                        <span className="badge bg-danger">{wstpusb?.results?.systemcategory?.mainboard?.result}</span>
                                    }
                                </div>
                                <div className="text-center">
                                    {wstpusb?.results?.systemcategory?.mainboard?.finishedloops}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="wstpusb-test-item">
                    {conditionalView(wstpusb?.results?.memorycategory,
                        <div className="wstpusb-test-item-ram">
                            <div className="wstpusb-test-item-flex-inside">
                                <h4>
                                    <img src={`${BASE_URL}assets/images/icons/device-components/ram.png`} className="me-3" alt="" />
                                    Memory RAM
                                </h4>
                            </div>
                            <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-heading">
                                <div className="text-center">
                                    Status
                                </div>
                                <div className="text-center">
                                    # of Loops
                                </div>
                            </div>
                            <div>
                                {wstpusb?.results?.memorycategory && (Array.isArray(wstpusb?.results?.memorycategory) ? wstpusb?.results?.memorycategory : Object.values(wstpusb?.results?.memorycategory)).map((ram, rami) =>
                                    <div key={rami} className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                        <div className="text-center">
                                            {ram?.result === "Pass" ?
                                                <span className="badge bg-success">{ram?.result}</span>
                                                :
                                                <span className="badge bg-danger">{ram?.result}</span>
                                            }
                                        </div>
                                        <div className="text-center">
                                            {ram?.finishedloops}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="wstpusb-test-item w-100">
                    {conditionalView(wstpusb?.results?.multimediacategory,
                        <div className="wstpusb-test-item-multimedia">
                            <div className="wstpusb-test-item-flex-inside">
                                <h4>
                                    <img src={`${BASE_URL}assets/images/icons/device-components/multimedia.png`} className="me-3" alt="" />
                                    Multimedia
                                </h4>
                            </div>
                            <hr className="mt-1" />

                            <div className="d-flex gap-3 wstpusb-multimedia">
                                {conditionalView(wstpusb?.results?.multimediacategory?.simple2d,
                                    <div className="wstpusb-multimedia-items">
                                        <div className="wstpusb-multimedia-items-icon">
                                            <img src={`${BASE_URL}assets/images/icons/device-components/simple2d.png`} alt="" />
                                        </div>
                                        <div className="wstpusb-test-item-flex-heading">
                                            <div className="d-flex wstpusb-multimedia-item-flex">
                                                <div className="wstpusb-multimedia-item-flex">
                                                    Name
                                                </div>
                                                <div className="text-center wstpusb-multimedia-item-flex">
                                                    Status
                                                </div>
                                                <div className="text-center wstpusb-multimedia-item-flex">
                                                    # of Loops
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wstpusb-test-item-flex">
                                            <div className="wstpusb-multimedia-item-flex">
                                                {wstpusb?.results?.multimediacategory?.simple2d && (Array.isArray(wstpusb?.results?.multimediacategory?.simple2d) ? wstpusb?.results?.multimediacategory?.simple2d : Object.values(wstpusb?.results?.multimediacategory?.simple2d)).map((simple2d, simple2di) =>
                                                    <div key={simple2di} className="d-flex wstpusb-test-item-flex-body">
                                                        <div className="wstpusb-multimedia-item-flex">
                                                            {getKeyByValue(wstpusb?.results?.multimediacategory?.simple2d, simple2d)}
                                                        </div>
                                                        <div className="text-center wstpusb-multimedia-item-flex">
                                                            {simple2d.result === "Pass" ?
                                                                <span className="badge bg-success">{simple2d?.result}</span>
                                                                :
                                                                <span className="badge bg-danger">{simple2d?.result}</span>
                                                            }
                                                        </div>
                                                        <div className="text-center wstpusb-multimedia-item-flex">
                                                            {simple2d?.finishedloops}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {conditionalView(wstpusb?.results?.multimediacategory?.directx,
                                    <div className="wstpusb-multimedia-items">
                                        <div className="wstpusb-multimedia-items-icon">
                                            <img src={`${BASE_URL}assets/images/icons/device-components/directx.png`} alt="" />
                                        </div>
                                        <div className="wstpusb-test-item-flex-heading">
                                            <div className="d-flex wstpusb-multimedia-item-flex">
                                                <div className="wstpusb-multimedia-item-flex">
                                                    Name
                                                </div>
                                                <div className="text-center wstpusb-multimedia-item-flex">
                                                    Status
                                                </div>
                                                <div className="text-center wstpusb-multimedia-item-flex">
                                                    # of Loops
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wstpusb-test-item-flex">
                                            <div className="wstpusb-multimedia-item-flex">
                                                {wstpusb?.results?.multimediacategory?.directx && (Array.isArray(wstpusb?.results?.multimediacategory?.directx) ? wstpusb?.results?.multimediacategory?.directx : Object.values(wstpusb?.results?.multimediacategory?.directx)).map((directx, directxi) =>
                                                    <div key={directxi} className="d-flex wstpusb-test-item-flex-body">
                                                        <div className="wstpusb-multimedia-item-flex">
                                                            {getKeyByValue(wstpusb?.results?.multimediacategory?.directx, directx)}
                                                        </div>
                                                        <div className="text-center wstpusb-multimedia-item-flex">
                                                            {directx.result === "Pass" ?
                                                                <span className="badge bg-success">{directx?.result}</span>
                                                                :
                                                                <span className="badge bg-danger">{directx?.result}</span>
                                                            }
                                                        </div>
                                                        <div className="text-center wstpusb-multimedia-item-flex">
                                                            {directx?.finishedloops}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {conditionalView(wstpusb?.results?.multimediacategory?.opengl,
                                    <div className="wstpusb-multimedia-items">
                                        <div className="wstpusb-multimedia-items-icon">
                                            <img src={`${BASE_URL}assets/images/icons/device-components/opengl.png`} alt="" />
                                        </div>
                                        <div className="wstpusb-test-item-flex-heading">
                                            <div className="d-flex wstpusb-multimedia-item-flex">
                                                <div className="wstpusb-multimedia-item-flex">
                                                    Name
                                                </div>
                                                <div className="text-center wstpusb-multimedia-item-flex">
                                                    Status
                                                </div>
                                                <div className="text-center wstpusb-multimedia-item-flex">
                                                    # of Loops
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wstpusb-test-item-flex">
                                            <div className="wstpusb-multimedia-item-flex">
                                                {wstpusb?.results?.multimediacategory?.opengl && (Array.isArray(wstpusb?.results?.multimediacategory?.opengl) ? wstpusb?.results?.multimediacategory?.opengl : Object.values(wstpusb?.results?.multimediacategory?.opengl)).map((opengl, opengli) =>
                                                    <div key={opengli} className="d-flex wstpusb-test-item-flex-body">
                                                        <div className="wstpusb-multimedia-item-flex">
                                                            {getKeyByValue(wstpusb?.results?.multimediacategory?.opengl, opengl)}
                                                        </div>
                                                        <div className="text-center wstpusb-multimedia-item-flex">
                                                            {opengl.result === "Pass" ?
                                                                <span className="badge bg-success">{opengl?.result}</span>
                                                                :
                                                                <span className="badge bg-danger">{opengl?.result}</span>
                                                            }
                                                        </div>
                                                        <div className="text-center wstpusb-multimedia-item-flex">
                                                            {opengl?.finishedloops}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="wstpusb-test-item">
                    {conditionalView(wstpusb?.results?.networkcategory,
                        <div className="wstpusb-test-item-network">
                            <div className="wstpusb-test-item-flex-inside">
                                <h4>
                                    <img src={`${BASE_URL}assets/images/icons/device-components/network.png`} className="me-3" alt="" />
                                    Network
                                </h4>
                            </div>
                            <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-heading">
                                <div>
                                    Name
                                </div>
                                <div className="text-center">
                                    Status
                                </div>
                                <div className="text-center">
                                    # of Loops
                                </div>
                            </div>
                            <div>
                                {wstpusb?.results?.networkcategory?.ethernet?.device && (Array.isArray(wstpusb?.results?.networkcategory?.ethernet?.device) ? wstpusb?.results?.networkcategory?.ethernet?.device : Object.values(wstpusb?.results?.networkcategory?.ethernet?.device)).map((network, networki) =>
                                    <div key={networki} className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                        <div>
                                            {network?.name}
                                        </div>
                                        <div className="text-center">
                                            {network?.result === "Pass" ?
                                                <span className="badge bg-success">{network?.result}</span>
                                                :
                                                <span className="badge bg-danger">{network?.result}</span>
                                            }
                                        </div>
                                        <div className="text-center">
                                            {network?.finishedloops}
                                        </div>
                                    </div>
                                )}

                                <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                    <div>
                                        {wstpusb?.results?.networkcategory?.wifi?.device?.name}
                                    </div>
                                    <div className="text-center">
                                        {wstpusb?.results?.networkcategory?.wifi?.device?.result === "Pass" ?
                                            <span className="badge bg-success">{wstpusb?.results?.networkcategory?.wifi?.device?.result}</span>
                                            :
                                            <span className="badge bg-danger">{wstpusb?.results?.networkcategory?.wifi?.device?.result}</span>
                                        }
                                    </div>
                                    <div className="text-center">
                                        {wstpusb?.results?.networkcategory?.wifi?.device?.finishedloops}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="wstpusb-test-items">
                {wstpusb?.results?.interactivecategory && (Array.isArray(wstpusb?.results?.interactivecategory) ? wstpusb?.results?.interactivecategory : Object.entries(wstpusb?.results?.interactivecategory)).map(([key, value], mainKey) => {
                    if (key === "monitors") {
                        return <div className="wstpusb-test-item w-100">
                            {conditionalView(wstpusb?.results?.interactivecategory[key],
                                wstpusb?.results?.interactivecategory[key]?.monitor.map((monitor, monitori) =>
                                    <div className="wstpusb-test-item-processor" key={monitori}>
                                        <div className="wstpusb-test-item-flex-inside">
                                            <h4>
                                                <img src={`${BASE_URL}assets/images/icons/device-components/monitor.png`} className="me-3" alt="" />
                                                {monitor?.name}
                                            </h4>
                                        </div>
                                        <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-heading">
                                            <div className="text-center">
                                                Status
                                            </div>
                                            <div className="text-center">
                                                # of Loops
                                            </div>
                                        </div>
                                        <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                            <div className="text-center">
                                                {monitor.result === "Pass" ?
                                                    <span className="badge bg-success">{monitor?.result}</span>
                                                    :
                                                    <span className="badge bg-danger">{monitor?.result}</span>
                                                }
                                            </div>
                                            <div className="text-center">
                                                {monitor?.finishedloops}
                                            </div>
                                        </div>
                                        {/*{findError(wstpusb, key)}*/}
                                    </div>
                                )
                            )}
                        </div>
                    }
                    return <div className="wstpusb-test-item">
                        {conditionalView(wstpusb?.results?.interactivecategory,
                            <div className="wstpusb-test-item-processor">
                                <div className="wstpusb-test-item-flex-inside">
                                    <h4>
                                        <img src={`${BASE_URL}assets/images/icons/device-components/${key}.png`} className="me-3" alt="" />
                                        {key}
                                    </h4>
                                </div>
                                <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-heading">
                                    <div className="text-center">
                                        Status
                                    </div>
                                    <div className="text-center">
                                        # of Loops
                                    </div>
                                </div>
                                <div className="wstpusb-test-item-flex-inside wstpusb-test-item-flex-body">
                                    <div className="text-center">
                                        {value.result === "Pass" ?
                                            <span className="badge bg-success">{value?.result}</span>
                                            :
                                            <span className="badge bg-danger">{value?.result}</span>
                                        }
                                    </div>
                                    <div className="text-center">
                                        {value?.finishedloops}
                                    </div>
                                </div>
                                {/*{findError(wstpusb, key)}*/}
                            </div>
                        )}
                    </div>
                })}
            </div>
        </div>
    )
}

export default WSTP;