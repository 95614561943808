import React, {useEffect} from "react";
import "./results.css";
import Licenses from "./Licenses/Licenses";
import Sessions from "./Sessions/Sessions";
import SessionsListFilters from "./Filters/SessionsListFilters";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import Devices from "./Devices/Devices";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {breadcrumbs} from "../../../Redux/Actions/results";
import {decodeID} from "../../Other/Helper";

const Results = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global)
    const breadcrumbsState = useSelector((state) => state?.results?.breadcrumbs);
    const licensesState = useSelector(state => state?.results?.licenses)
    const resultsState = useSelector(state => state?.results)
    const {licenseID, deviceID, sessionID} = useParams();

    useEffect(() => {
        if (!licenseID) return;
        const decodedID = decodeID(licenseID);
        if (decodedID) {
            const selectedLicense = licensesState?.find(license => {
                try {
                    console.log(parseInt(license.id), decodedID)
                    return parseInt(license.id) === decodedID;
                } catch (error) {
                    console.log(error)
                    return undefined;
                }
            });
            if (selectedLicense) {
                dispatch(breadcrumbs({
                    breadcrumbs: {
                        ...resultsState?.breadcrumbs,
                        selectedLicense: selectedLicense?.license_hash,
                        selectedDevice: deviceID,
                        selectedSession: sessionID,
                    }
                }));
            }
        }
    }, [licensesState, sessionID, licenseID, deviceID]);

    return (
        <div className="results">
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Results</h2>
            </div>

            <div className="mx-3">
                <Licenses />
            </div>
            <SessionsListFilters />

            <Breadcrumbs breadcrumbs={breadcrumbsState} />

            <Outlet />
        </div>
    )
}

export default Results;
