import React, {useEffect, useState} from "react";
import "./events.css";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {FaCaretDown, FaCaretUp} from "react-icons/fa";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const Events = ({id, title, data}) => {

    const applicationFailures = data?.software?.eventLog?.ApplicationFailures;
    const unexpectedShutdowns = data?.software?.eventLog?.WindowsUnexpectedShutdown;

    const [eventsArray, setEventsArray] = useState([]);
    const [filter, setFilter] = useState(5);
    const [asc, setAsc] = useState(true);

    useEffect(() => {
        if (id === "application-failures") setEventsArray(applicationFailures)
        if (id === "unexpected-shutdowns") setEventsArray(unexpectedShutdowns)
    }, [id])

    useEffect(() => {
        let argArray = ["Category", "Index", "EventID", "CategoryNumber", "EntryType", "TimeWritten"];
        //console.log("useEffect:", argArray[filter], asc)
        setEventsArray(reorderArray(eventsArray, asc, argArray[filter]))
    }, [filter, asc])

    const reorderArray = (array, ascDesc, type) => {
        switch (type) {
            case "Category":
                return [...array]?.sort(sort_by(type, ascDesc, (a) => a.toUpperCase()));//FOR TEXT
            case "TimeWritten":
                return [...array]?.sort((a, b) => ascDesc ? Date.parse(a[type]) - Date.parse(b[type]) : Date.parse(b[type]) - Date.parse(a[type]));
            default:
                return [...array]?.sort((a, b) => ascDesc ? a[type] - b[type] : b[type] - a[type]);
        }
    }
    const sort_by = (field, reverse, primer) => {
        const key = primer ?
            function (x) {
                return primer(x[field])
            } :
            function (x) {
                return x[field]
            };
        reverse = !reverse ? 1 : -1;
        return function (a, b) {
            return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
        }
    }
    const sets = (filter, isAscending) => {
        setFilter(filter);
        setAsc(isAscending);
        //console.log("sets Function", filter, isAscending)
    }

    const displayCaret = () => {
        if (asc) return <FaCaretUp />
        return <FaCaretDown />
    }

    if (typeof eventsArray === "undefined") {
        return (
            <div className="system-info-page events">
                <div className="system-overview events-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">{title}</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                <ContentPreloader theme="danger" forContent="each" text={`Please wait while ${title} are being loaded...`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (Array.isArray(eventsArray) && eventsArray.length === 0) {
        return (
            <div className="system-info-page events">
                <div className="system-overview events-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">{title}</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                There is no data for {title}...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="system-info-page events">
            <div className="system-overview events-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">{title}</div>
                </div>
                <div>
                    <div className="system-info-row position-relative text-orange">
                        <div className="drive-number-no-margin me-3 align-self-center system-info-th text-orange">
                            #
                        </div>
                        <div className="drive-number-no-margin device-in-loop-parts system-info-th font-italic">
                            <div className="text-orange events-column-heading" onClick={() => sets(0, !asc)}>
                                        <span className="event-heading-wrapper">
                                            Name
                                            <span className="events-sorting-caret">
                                                {filter === 0 ? displayCaret() : ""}
                                            </span>
                                        </span>
                            </div>
                            <div className="text-orange events-column-heading" onClick={() => sets(1, !asc)}>
                                        <span className="event-heading-wrapper">
                                            Record
                                            <span className="events-sorting-caret">
                                                {filter === 1 ? displayCaret() : ""}
                                            </span>
                                        </span>
                            </div>
                            <div className="text-orange events-column-heading" onClick={() => sets(2, !asc)}>
                                        <span className="event-heading-wrapper">
                                            Code
                                            <span className="events-sorting-caret">
                                                {filter === 2 ? displayCaret() : ""}
                                            </span>
                                        </span>
                            </div>
                            <div className="text-orange events-column-heading" onClick={() => sets(3, !asc)}>
                                        <span className="event-heading-wrapper">
                                            Category
                                            <span className="events-sorting-caret">
                                                {filter === 3 ? displayCaret() : ""}
                                            </span>
                                        </span>
                            </div>
                            <div className="text-orange events-column-heading" onClick={() => sets(4, !asc)}>
                                        <span className="event-heading-wrapper">
                                            Type
                                            <span className="events-sorting-caret">
                                                {filter === 4 ? displayCaret() : ""}
                                            </span>
                                        </span>
                            </div>
                            <div className="text-orange events-column-heading" onClick={() => sets(5, !asc)}>
                                        <span className="event-heading-wrapper">
                                            Date
                                            <span className="events-sorting-caret">
                                                {filter === 5 ? displayCaret() : ""}
                                            </span>
                                        </span>
                            </div>
                        </div>
                    </div>
                    {eventsArray && Array.isArray(eventsArray) && eventsArray?.map((event, eventi) =>
                        <div className="system-info-row position-relative" key={eventi}>
                            <div className="drive-number align-self-center system-info-td">
                                {eventi + 1}
                            </div>
                            <div className="device-in-loop-parts system-info-td">
                                <div className="text-success">
                                    {event?.Category}
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{event?.Index}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{event?.EventID}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{event?.CategoryNumber}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{event?.EntryType}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{event?.TimeWritten}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Events;