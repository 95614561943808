import React, {useEffect, useState} from "react";
import "./devices.css";
import {ImQrcode} from "react-icons/im";
import {MdOutlineCopyAll} from "react-icons/md";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Link, Outlet, useParams} from "react-router-dom";
import {licenses, devices, breadcrumbs} from "../../../../Redux/Actions/results";
import {BASE_URL} from "../../../Constants";
import {global, placeholderRandomizer} from "../../../../Redux/Actions/global";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import DownloadQr from "../../../Other/QRCode/DownloadQr/DownloadQr";
import Licenses from "../Licenses/Licenses";
import {PiDevicesDuotone} from "react-icons/pi";
import {decodeID} from "../../../Other/Helper";

const baseURL = BASE_URL;
const phpRoute2 = "license-devices/";

const Devices = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)
    const resultsState = useSelector(state => state?.results)
    const {licenseID} = useParams();
    const retrieveData = () => {
        axios({
            method: 'post',
            url: baseURL + phpRoute2 + "?lang=en",
            data: JSON.stringify({
                email: loginState?.email,
                id: decodeID(licenseID), //resultsState?.breadcrumbs?.selectedLicense,
                filter: resultsState?.filters
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => {
            console.log("DEVICE LIST: ", res.data)
            dispatch(devices({devices: res?.data}));
        }).catch(err => {
            console.log(err)
            dispatch(devices({devices: []})); //No Devices for this license
        })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (resultsState?.filters) return;
        dispatch(licenses({
            filters: {
                ordering: "orderByDateDesc",
                filter: "all"
            }
        }));
    }, []);

    const devicesMajorTestsRandomizer = () => {
        let tests = [];
        let testTypes = ["warning", "success", "danger"]
        let amount = Math.round(Math.random() * 2)
        for (let i = 0; i <= amount; i++) {
            tests.push({
                id: i,
                type: testTypes[Math.round(Math.random() * 2)]
            });
        }
        return tests;
    }
    const devicesArrayRandomizer = (min, max) => {
        let array = [];
        for (let i = min; i <= max; i++) {
            array.push({
                id: i,
                tests: devicesMajorTestsRandomizer()
            })
        }
        return array;
    }

    const filterLicenseCount = () => {
        try {
            return resultsState?.licenses?.find((license) => license?.license_hash === resultsState?.breadcrumbs?.selectedLicense)?.usage
        } catch (e) {
            return 3;
        }
    }

    useEffect(() => {
        dispatch(placeholderRandomizer({
            placeholderRandomizer: {
                ...globalState?.placeholderRandomizer,
                devices: devicesArrayRandomizer(1, filterLicenseCount(), 3, 3, 8),
            }
        }))

        if (!resultsState?.breadcrumbs?.selectedLicense) return;
        setLoading(true)
        let timeout = setTimeout(() => {
            retrieveData();
        }, 1000);
        return () => clearTimeout(timeout);
    }, [resultsState?.breadcrumbs?.selectedLicense, resultsState?.filters])


    const onCopyUrl = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "success",
                    title: "Clipboard",
                    message: "Public URL was copied to clipboard!",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }

    const deviceListPreLoader = () => {
        let devices = globalState?.placeholderRandomizer?.devices;
        return devices && devices?.map((device, idevice) =>
            <div className="d-flex align-items-center justify-content-between devices-list-device-row" key={idevice}>
                <div className="devices-list-device-items devices-list-device-items-numbers">
                    {idevice + 1}
                </div>
                <div>
                    <PiDevicesDuotone size={25} />
                </div>
                <div className="devices-list-device-items devices-list-device-items-qr">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="devices-list-device-items devices-list-device-items-public-url">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="devices-list-device-items">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="devices-list-device-items devices-list-device-items-global-status">
                    <span className={`placeholder col-4`} />
                </div>
                <div className="devices-list-device-items">
                    <div className="devices-list-major-tests">
                        {device?.tests?.map(item =>
                            <div key={item?.id} className={`btn btn-${item?.type} btn-sm disabled placeholder`} style={{width: "33.33%"}} />
                        )}
                    </div>
                </div>
                <div className="devices-list-device-items">
                    <a href="#" tabIndex="-1" className="btn btn-secondary btn-sm  disabled placeholder container-fluid" />
                </div>
            </div>
        )
    }
    const deviceList = () => {
        return typeof resultsState?.devices !== "undefined" && resultsState?.devices?.map((device, idevice) =>
            <div className="d-flex align-items-center justify-content-between devices-list-device-row" key={idevice}>
                <div className="devices-list-device-items devices-list-device-items-numbers">
                    {idevice + 1}
                </div>
                <div>
                    <PiDevicesDuotone size={25} />
                </div>
                <div className="devices-list-device-items devices-list-device-items-qr">
                    {/*<DownloadQr hash={device?.id} />*/}
                </div>
                <div className="devices-list-device-items devices-list-device-items-public-url">
                    Test Session(s): {device?.count} {/*<CopyToClipboard text={`https://portal.uxd.com/public-results/${device?.id}`} onCopy={onCopyUrl}>*/} {/*    <div className="copy-clipboard">*/} {/*        Public URL <MdOutlineCopyAll size="23" />*/} {/*    </div>*/} {/*</CopyToClipboard>*/}
                </div>
                <div className="devices-list-device-items">
                    {device?.date_generated}
                </div>
                {/*<div className="devices-list-device-items devices-list-device-items-global-status">*/} {/*    {globalStatus(device)}*/} {/*</div>*/} {/*<div className="devices-list-device-items">*/} {/*    {majorTests(device)}*/} {/*</div>*/}
                <div className="devices-list-device-items">
                    <Link onClick={() => dispatch(breadcrumbs({
                        breadcrumbs: {
                            ...resultsState?.breadcrumbs,
                            selectedDevice: device?.id
                        }
                    }))} className="btn btn-secondary btn-sm container-fluid"
                          to={`/results/${licenseID}/${device?.id}`}
                    >Show session(s) for this device </Link>
                </div>
            </div>
        )
    }

    return (
        <div className="devices-list">
            <div className="mx-3 devices-list-inner">
                <div className="d-flex align-items-center justify-content-between devices-list-device-row devices-list-device-row-head">
                    <div className="devices-list-device-items devices-list-device-items-numbers">
                        #
                    </div>
                    <div>
                        Icon
                    </div>
                    <div className="devices-list-device-items devices-list-device-items-qr">
                        QR
                    </div>
                    <div className="devices-list-device-items devices-list-device-items-public-url">
                        Public Url
                    </div>
                    <div className="devices-list-device-items">
                        Date Created
                    </div>
                    {/*<div className="devices-list-device-items devices-list-device-items-global-status">*/} {/*    Global Status*/} {/*</div>*/} {/*<div className="devices-list-device-items">*/} {/*    Major Test(s) Performed*/} {/*</div>*/}
                    <div className="devices-list-device-items">
                        Actions
                    </div>
                </div>
                {loading && resultsState?.breadcrumbs?.selectedLicense ? deviceListPreLoader() : deviceList()}
            </div>
        </div>
    )
}

export default Devices;