import "./circle-progress-bar.css"
import {useEffect, useState} from "react";


const CircleProgressBar = ({rank}) => {
    const [color, setColor] = useState("#e3ffd3");
    const [animRank, setAnimRank] = useState(0);
    const [intervalID, setIntervalID] = useState(0);

    useEffect(() => {
        let interval = setInterval(() => {
            setAnimRank(animRank => animRank + 1)
        } , 30)
        setIntervalID(interval);

        return () => {
            return clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if(rank === animRank){
            clearInterval(intervalID)
        }
    }, [animRank]);

    useEffect(() => {
        if(animRank > 0 && animRank < 49){
            setColor("#ffd1d1")
        }
        if(animRank > 50 && animRank < 79){
            setColor("#fff6d1")
        }
        if(animRank > 80 && animRank < 100){
            setColor("#e3ffd3")
        }
    }, [animRank]);

    return (
        <div className="circular-progress" style={{background: `conic-gradient(#7d2ae8 ${animRank * 3.6}deg, #ffffff 0deg)`}}>
            <div className="circular-progress-before" style={{backgroundColor: `${color}`}}>

            </div>
            <span className="progress-value">{animRank}%</span>
        </div>
    )
}
export default CircleProgressBar;