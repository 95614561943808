import React, {useEffect, useRef, useState} from "react"
import QRCodeStyling from "qr-code-styling"

export default function App() {
    const [options, setOptions] = useState({
        width: 200,
        height: 200,
        type: "svg",
        data: "https://uxd.com",
        image: "/assets/images/ux-logo.svg",
        margin: 0,
        qrOptions: {
            typeNumber: 0,
            mode: "Byte",
            errorCorrectionLevel: "H"
        },
        imageOptions: {
            hideBackgroundDots: true,
            imageSize: 0.5,
            margin: 5,
            crossOrigin: "anonymous"
        },
        dotsOptions: {
            color: "#F25D00",
            // gradient: {
            //     type: 'linear', // 'radial'
            //     rotation: 0,
            //     colorStops: [{offset: 0, color: 'rgba(143,93,0,0.8)'}, {offset: 1, color: '#ff6302'}]
            // },
            type: "dots"
        },
        backgroundOptions: {
            color: "rgba(95,238,243,0)"
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 0,
            //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
            // },
        },
        cornersSquareOptions: {
            color: "#444444",
            type: "extra-rounded"
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 180,
            //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
            // },
        },
        cornersDotOptions: {
            color: "#444444",
            type: "rounded"
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 180,
            //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
            // },
        }
    })
    const [fileExt, setFileExt] = useState("svg")
    const [qrCode] = useState(new QRCodeStyling(options))
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            qrCode.append(ref.current)
        }
    }, [qrCode, ref])

    useEffect(() => {
        if (!qrCode) return
        qrCode.update(options)
    }, [qrCode, options])

    const onDataChange = event => {
        setOptions(options => ({
            ...options,
            data: event.target.value
        }))
    }

    const onExtensionChange = event => {
        setFileExt(event.target.value)
    }

    const onDownloadClick = () => {
        if (!qrCode) return
        qrCode.download({
            extension: fileExt
        }).then(r => console.log(r))
    }

    return (
        <div>
            <div className="d-flex page-main-heading mb-2">
                {/*<h2 className="m-0 mx-3 mt-1 text-uppercase">QR Styling</h2>*/}
            </div>
            <div className="mx-3">
                <div ref={ref} />
                <div style={styles.inputWrapper}>
                    <input
                        value={options.data}
                        onChange={onDataChange}
                        style={styles.inputBox}
                    />
                    <select onChange={onExtensionChange} value={fileExt}>
                        <option value="svg">SVG</option>
                        <option value="png">PNG</option>
                        <option value="jpeg">JPEG</option>
                        <option value="webp">WEBP</option>
                    </select>
                    <button onClick={onDownloadClick}>Download</button>
                </div>
            </div>
        </div>
    )
}

const styles = {
    inputWrapper: {
        margin: "20px 0",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "300px"
    },
    inputBox: {
        flexGrow: 1,
        marginRight: 20
    }
}
