import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {FaArrowTurnDown} from 'react-icons/fa6';

const Breadcrumbs = () => {
    const [crumbs, setCrumbs] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const pathnames = location.pathname.split('/').filter((x) => x);

        // Exclude the first segment "results"
        const relevantPathnames = pathnames.slice(1);

        const segmentNames = ['License', 'Device', 'Session'];

        const mappedCrumbs = relevantPathnames.map((x, i) => {
            let name;
            if (i < 3) {
                name = segmentNames[i]; // Use predefined names for the first three segments
            } else {
                // Capitalize each word in the last segment
                name = x.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
            }

            return {
                name: name,
                href: `/${pathnames.slice(0, i + 2).join('/')}`, // Adjust href to match the segment index
            };
        });

        setCrumbs(mappedCrumbs);
    }, [location]);

    const removeCrumb = (crumbIndex) => {
        const url = crumbs[crumbIndex].href;
        navigate(url);
        setCrumbs(crumbs.slice(0, crumbIndex + 1));
    };

    return (
        <div className="m-3 breadcrumbs">
            {crumbs.map((crumb, crumbIndex) => (
                <span key={crumbIndex}>
                    <Link to={crumb.href} onClick={() => removeCrumb(crumbIndex)}>
                        {crumb.name}
                    </Link> {crumbIndex !== crumbs.length - 1 && (
                    <span className="separator"> / </span>
                )} {crumbIndex === crumbs.length - 1 && (
                    <span className="arrow-down">
                            <FaArrowTurnDown />
                        </span>
                )}
                </span>
            ))}
        </div>
    );
};

export default Breadcrumbs;
