import React from "react";
import "./aside-menu.css";

import {RiDashboardFill} from "react-icons/ri";
import {GiBoomerang, GiCardboardBoxClosed, GiCloudDownload} from "react-icons/gi";
import {BsCardChecklist} from "react-icons/bs";
import {MdPowerSettingsNew, MdSupport} from "react-icons/md";
import {VscSettings} from "react-icons/vsc";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {FaPuzzlePiece} from "react-icons/fa";
import {AiOutlineQuestion} from "react-icons/ai";
import {global} from "../../../Redux/Actions/global";

const AsideMenu = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global)
    const location = useLocation();
    const logOut = () => {
        dispatch(global({trigger: "logout"}))
    };

    const menuArray = [
        {
            id: "portalPages",
            title: "Portal Pages",
            menuItems: [
                {
                    id: "dashboard",
                    title: "Dashboard",
                    status: "ok",
                    children: [],
                    icon:
                        <RiDashboardFill />,
                    link: "dashboard"
                },
                {
                    id: "tracker",
                    title: "Tracker",
                    status: "ok",
                    children: [],
                    icon:
                        <GiBoomerang />,
                    link: "tracker"
                },
                {
                    id: "products",
                    title: "Products",
                    status: "ok",
                    children: [],
                    icon:
                        <GiCardboardBoxClosed />,
                    link: "products"
                },
                {
                    id: "results",
                    title: "Results",
                    status: "ok",
                    children: [],
                    icon:
                        <BsCardChecklist />,
                    link: "results"
                },
                // {
                //     id: "support",
                //     title: "Support",
                //     status: "pending",
                //     children: [],
                //     icon:
                //         <MdSupport />,
                //     link: "support"
                // },
                {
                    id: "settings",
                    title: "Settings",
                    status: "ok",
                    children: [],
                    icon:
                        <VscSettings />,
                    link: "settings"
                },
                {
                    id: "relax",
                    title: "Relax",
                    status: "ok",
                    children: [],
                    icon:
                        <FaPuzzlePiece />,
                    link: "relax"
                },
                // {
                //     id: "about",
                //     title: "About",
                //     status: "pending",
                //     children: [],
                //     icon:
                //         <AiOutlineQuestion />,
                //     link: "about"
                // },
                {
                    id: "downloads",
                    title: "Downloads",
                    status: "ok",
                    children: [],
                    icon:
                        <GiCloudDownload />,
                    link: "downloads"
                },
                {
                    id: "logout",
                    title: "Logout",
                    status: "ok",
                    children: [],
                    icon:
                        <MdPowerSettingsNew />,
                    link: location?.pathname ?? "/",
                    clickHandler: logOut
                }
            ]
        }
    ]

    return (
        <div>
            <div className="aside-menu">
                {menuArray && menuArray?.map((category, ci) =>
                    <div key={ci} className="aside-menu-cat">
                        <div className={`text-truncate text-uppercase text-secondary fw-bold ${globalState?.asideClosed ? "d-none" : ""}`}>
                            {category?.title}
                        </div>
                        {category?.menuItems && category?.menuItems?.map((items, ii) =>
                            <Link to={items?.link} key={ii} onClick={items?.clickHandler} className={`aside-menu-cat-item d-flex ${globalState?.asideClosed ? "justify-content-center" : ""}`}>
                                <div className="aside-menu-cat-item-icon align-self-center">
                                    {items?.icon}
                                </div>
                                <div className={`text-truncate aside-menu-cat-item-title align-self-center ${globalState?.asideClosed ? "d-none" : ""}`}>


                                    {items?.status === "pending" ?
                                        <div>
                                            <span className="text-secondary">{items?.title}</span>
                                            <span className="badge bg-danger ms-3">Coming Soon</span>
                                        </div> :
                                        items?.title
                                    }
                                </div>
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default AsideMenu;
