import React, {useEffect} from "react";
import "./line-chart.css";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {dashboard} from "../../../../../Redux/Actions/dashboard";
import {Bar, Line} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js/auto';//dont remove!!!
import axios from "axios";
import {BASE_URL} from "../../../../Constants";

const baseURL = BASE_URL;
const phpRoute = "user-licenses-chart/";

const LineChart = () => {
    const dispatch = useDispatch();
    const dashState = useSelector(state => state?.dashboard)
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)

    const demoLineDates = (startDate, stopDate) => {
        let dateArray = [];
        let currentDate = moment(startDate);
        stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray.map((item, index) => {
            return {
                x: item, //date
                y: Math.floor(Math.random() < 0.9 ? Math.random() < 0.6 ? 0 : Math.random() * 3 : Math.random() * 9)//usage
            };
        })
    }
    const generateDemo = () => {
        let today = new Date();
        let past = new Date();
        past.setDate(today.getDate() - 21)

        let lineArr = [];
        for (let i = 0; i <= 5; i++) {
            lineArr.push({
                label: "License #" + (i + 1),
                data: demoLineDates(past, today),
                borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.8)`,
                backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.8)`,
                tension: 0.3,
            })
            // datasets: [
            //     {
            //         label: 'time-data',
            //         data: [
            //             {x: '2021-08-21 18:37:39', y: 2},
            //             {x: '2021-08-22 18:39:39', y: 3},
            //             {x: '2021-09-25 18:44:39', y: 1},
            //         ],
            //         borderColor: 'rgba(255,99,132,0.8)',
            //         tension: 0.3,
            //     },
            // ],
        }
        dispatch(dashboard({
            chart: {
                highestNumber: Math.max(...lineArr.flatMap(item => item?.data?.reduce(function (acc, val) { return acc + val?.usage; }, 0))),
                lineChart: {
                    datasets: lineArr
                }
            }
        }))
        return lineArr;
    }
    const generateRandomColor = () => {
        return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.8)`;
    }
    const generateReal = (data) => {
        let lineArr = [];
        for (let i = 0; i <= data?.line_chart?.length - 1; i++) {
            lineArr.push({
                label: data?.line_chart[i]?.name,
                data: data?.line_chart[i]?.data,
                borderColor: generateRandomColor(),
                backgroundColor: generateRandomColor(),
                tension: 0.3,
            })
        }
        dispatch(dashboard({
            chart: {
                lineChart: {
                    datasets: lineArr
                }
            }
        }))
        return lineArr;
    }
    useEffect(() => {
        if (loginState?.email === "demo@uxd.com") {
            generateDemo();
            return;
        }
        //
        axios({
            method: 'post',
            url: baseURL + phpRoute,
            data: JSON.stringify({
                email: loginState?.email
            }),
            headers: {'content-type': 'application/json'},
        })
            .then(res => {
                console.log(res?.data)
                generateReal(res?.data)
            })
    }, []);


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: globalState?.theme === "dark" ? "#ffffff" : "#45d55",
                }
            },
            title: {
                display: true,
                text: 'License Usage',
                color: globalState?.theme === "dark" ? "#ffffff" : "#45d55"
            },
        },
        scales: {
            yAxes: {
                grid: {
                    color: globalState?.theme === "dark" ? "#1a1d21" : "#eaeaea"
                },
                ticks: {
                    color: globalState?.theme === "dark" ? "#ffffff" : "#45d55"
                },
            },
            xAxes: {
                grid: {
                    color: globalState?.theme === "dark" ? "#1a1d21" : "#eaeaea"
                },
                ticks: {
                    color: globalState?.theme === "dark" ? "#ffffff" : "#45d55"
                },
            }
        },
    };

    if (!dashState?.chart?.lineChart) {
        return (
            <div>
                Loading Data, Please Wait...
            </div>
        )
    }

    return (
        <div className="line-chart">
            <h5>Statistics</h5>
            <div className="line-chart-body">
                <div className="line-chart-body-item">
                    <Line options={options} data={dashState?.chart?.lineChart} type="Line" />
                </div>
                <div className="line-chart-body-item">
                    <Bar options={options} data={dashState?.chart?.lineChart} type="Bar" />
                </div>
            </div>
        </div>
    )
}

export default LineChart;