const loginReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_LOGIN_STATUS':
            return {
                ...state, ...action.data
            }
        case 'SET_LOGOUT_STATUS':
            return {
                ...state, ...action.data
            }
        default:
            return state;
    }
};

export default loginReducer;