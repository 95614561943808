import React from "react";
import LineChart from "./Charts/LineChart/LineChart";
import DashTaskPriorityList from "./DashTaskPriorityList/DashTaskPriorityList";

const Dashboard = () => {
    return (
        <div>
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Dashboard</h2>

            </div>

            <div className="mx-3">
                <DashTaskPriorityList />
                <LineChart />
            </div>
        </div>
    )
}

export default Dashboard;