import React, {Component} from 'react';

class GetDetailsPart extends Component {
    getDetailsPart(determinant, array, characterAmount, showHeading, stripTags = false) {
        for (let i = 0; i <= array.length - 1; i++) {
            if (array[i].type === determinant) {
                let text;
                if (!showHeading) {
                    text = array[i].text.replace(/<h4.*>.*?<\/h4>/ig, '').toString();
                } else {
                    text = array[i].text.toString();
                }

                if (parseFloat(characterAmount) !== 0) {
                    text = text.substr(0, characterAmount) + "...";
                }

                if (stripTags) {
                    text = text.replace(/(<([^>]+)>)/ig, "");
                }

                return (
                    <React.Fragment key={array[i].id}>
                        <div dangerouslySetInnerHTML={{__html: text}} />
                    </React.Fragment>
                )
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.getDetailsPart(this.props.determinant, this.props.details, this.props.characterAmount, this.props.showHeading, this.props.stripTags)}
            </React.Fragment>
        );
    }
}

export default GetDetailsPart;