import React, {useEffect, useState} from 'react';
import {BASE_URL} from "../../../Constants";
import "./inner-product.css";
import axios from "axios";
import {useSelector} from "react-redux";
import GetDetailsPart from "../../../Other/GetDetailsPart";
import {FaBook, FaExternalLinkAlt, FaInfoCircle} from "react-icons/fa";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const baseURL = BASE_URL;
const phpRoute = "single/";

const InnerProduct = ({altID, expirationDate}) => {
    const navigate = useNavigate();
    const [product, setProduct] = useState();
    const [loading, setLoading] = useState(true);

    const globalState = useSelector(state => state?.global);

    useEffect(() => {
        let timeout = setTimeout(() => {
            axios({
                url: baseURL + phpRoute + altID + "?lang=" + localStorage.getItem("language"),
                method: "get",
                headers: {'content-type': 'application/json'},
            }).then(res => {
                setProduct(res?.data)
            }).finally(() => setLoading(false));
        }, 1000)
        return () => clearTimeout(timeout);
    }, []);

    const bgImage = (hasImage, image) => {
        if (hasImage) {
            return image;
        }
        if (globalState?.theme === "dark") {
            return BASE_URL + 'assets/images/product/dark-no-image.png'
        }
        return {BASE_URL} + 'assets/images/product/no-image.png'
    }

    const calculateExpiration = () => {
        let start = new Date();
        let end = expirationDate;

        const startDate = moment(start);
        const timeEnd = moment(end);
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);

        let diffs = {
            days: diffDuration.days(),
            hours: diffDuration.hours(),
            minutes: diffDuration.minutes(),
            seconds: diffDuration.seconds()
        }
        //console.log(diffs)
        if (diffDuration.days() <= 0) {
            return "danger";
        }
        if (diffDuration.days() <= 15) {
            return "warning";
        }
        return "success";
    }

    if (loading) {
        return (
            <div className="inner-product card" aria-hidden="true">
                <div className="placeholder-glow">
                    <div className="inner-product-expiration-date btn btn-secondary position-absolute top-0 end-0 fw-normal col-5 placeholder disabled" />
                </div>
                <div className="inner-product-image card-img-top" />
                <div className="card-body">
                    <h5 className="placeholder-glow">
                        <span className="placeholder col-6" />
                    </h5>
                    <div className="placeholder-glow inner-product-description">
                        <span className="placeholder col-4 placeholder-xs" />
                        &nbsp;
                        <span className="placeholder col-6 placeholder-xs" />
                        &nbsp;
                        <span className="placeholder col-8 placeholder-xs" />
                        &nbsp;
                        <span className="placeholder col-3 placeholder-xs" />
                        &nbsp;
                        <span className="placeholder col-3 placeholder-xs" />
                        &nbsp;
                        <span className="placeholder col-4 placeholder-xs" />
                        &nbsp;
                        <span className="placeholder col-3 placeholder-xs" />
                    </div>
                    <a href="#" tabIndex="-1" className="btn btn-primary disabled placeholder col-7 me-1" />
                    <a href="#" tabIndex="-1" className="btn btn-danger disabled placeholder col-4" />
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {product && product?.map((prod, iprod) =>
                <div key={iprod} className="inner-product card">
                    <div className={`inner-product-expiration-date btn btn-${calculateExpiration(expirationDate)} position-absolute top-0 end-0 col-6`}>
                        Expiration Date: {expirationDate}
                    </div>
                    {prod?.main_image.map((m, i) =>
                        <div
                            key={i}
                            className="inner-product-image card-img-top"
                            style={{backgroundImage: `url(${bgImage(m?.has_image, m?.media_name)})`}}
                        />
                    )}
                    <div className="card-body">
                        <h5>{prod?.name}</h5>
                        <div className="inner-product-description">
                            <GetDetailsPart
                                determinant="SUMMARY"
                                details={prod?.details}
                                characterAmount="0"
                                showHeading={false}
                                stripTags={true}
                            />
                        </div>
                        <a href={`https://uxd.com/product/${prod?.alt_id}`} target="_blank" className="btn btn-primary me-1 col-7 btn-ellipsis">
                            <FaInfoCircle className="me-1" /> Product Details
                            <i className="fas fa-external-link-alt" />
                            <FaExternalLinkAlt className="ms-1" />
                        </a>
                        <button className="btn btn-danger col-4 btn-ellipsis" onClick={() => navigate(`/guide/${prod?.alt_id}`)}>
                            <FaBook /> Guide
                        </button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default InnerProduct;
