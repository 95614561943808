import "./messages.css";
import {MdMessage} from "react-icons/md";
import React, {useState} from "react";
import HeaderDropDown from "../HeaderDropDown/HeaderDropDown";
import {useDispatch, useSelector} from "react-redux";
import {global} from "../../../Redux/Actions/global";

const Messages = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global);
    const [messageCount, setMessageCount] = useState(0);

    const toggleMessagesBody = () => {
        if (globalState?.header?.headerDropDownParent === "messages") {
            dispatch(global({
                header: {
                    headerDropDownParent: "initial"
                }
            }))
            return;
        }
        dispatch(global({
            header: {
                headerDropDownParent: "messages"
            }
        }))
    }

    const messagesBody = () => {
        if (globalState?.header?.headerDropDownParent !== "messages") return <div />;
        return <HeaderDropDown text="No new messages yet!" />
    }

    const messagesActive = () => {
        if (globalState?.header?.headerDropDownParent !== "messages") return;
        return "messages-active"
    }

    return (
        <div className={`messages position-relative ${messagesActive()}`} onClick={toggleMessagesBody}>
            <div className={`badge rounded-pill px-1 py-1 bg-danger ${messageCount === 0 ? "d-none" : ""} fw-light font-monospace position-absolute top-0 end-0`}>{messageCount}</div>
            <MdMessage size="25" className="text-secondary position-absolute top-50 start-50 translate-middle" />

            {messagesBody()}
        </div>
    )
}

export default Messages;