import React from "react";
import "./preloaders.css";
import {Spinner} from "react-bootstrap";

const ContentPreloader = ({ theme = "primary", spinnerSize = "sm", forContent = "", text = "" }) => {

    const preloaderItself = () => {
        return (
            <Spinner animation="border" variant={theme} size={spinnerSize} />
        )
    }

    switch (forContent){
        case 'results':
            return (
                <div className="preloader-results-wrapper text-center">
                    {preloaderItself()}
                    {text !== "" ? <div>{text}</div> : ""}
                </div>
            );
        case 'each':
            return (
                <div className="preloader-each-wrapper text-center">
                    {preloaderItself()}
                    {text !== "" ? <div>{text}</div> : ""}
                </div>
            );
        default:
            return preloaderItself();
    }
}
export default ContentPreloader;