import React from "react";
import "./footer.css";
import {Link} from "react-router-dom";
import {VscFeedback} from "react-icons/vsc";
import packageJSON from "../../../package.json";
import {useSelector} from "react-redux";

const Footer = () => {
    const nowDate = new Date().getFullYear();
    const loginState = useSelector(state => state?.login);

    return (
        <footer className="w-100">
            <div className="feedback-btn align-self-center">
                {loginState.logged_in ?
                    <Link to="/feedback" className="btn btn-light btn-sm border">
                        <VscFeedback size="20" />
                        <span className="feedback-btn-text ms-1">Feedback</span>
                    </Link>
                    : ""}
            </div>
            <div className="align-self-center ellipsis footer-copyright text-end me-3">
                <span>Copyright © 2020-</span>
                <span>{nowDate}</span>

                <b className="text-secondary ms-1">Ultra-X, Inc.</b>
                <br />
                <span>All rights reserved</span>
                <b className="text-secondary ms-1">v{packageJSON?.version}</b>

            </div>
        </footer>
    )
}

export default Footer;