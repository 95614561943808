export const global = (globalData) => {
    return {
        type: 'GLOBAL',
        data: globalData
    };
};

export const alert = (globalData) => {
    return {
        type: 'ALERT',
        data: globalData
    };
};

export const placeholderRandomizer = (globalData) => {
    return {
        type: 'PLACEHOLDER_RANDOMIZER',
        data: globalData
    };
};
