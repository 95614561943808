import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import Main from './Components/Main';
import {Provider} from 'react-redux'
import {createStore} from "redux";
import allReducers from "./Redux/Reducers/all-reducers";

const store = createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
//console.log = () => {};
ReactDOM.render(
    <Provider store={store}>
        <Router basename={'/'}>
            <Main />
        </Router>
    </Provider>,
    document.getElementById('root')
);


