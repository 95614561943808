import React, {useEffect} from "react";
import "./public-results.css";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {results} from "../../../Redux/Actions/results";
import SystemInfo from "../Results/SystemInfoPages/SystemInfo";
import {GoogleMap} from "./GoogleMap/GoogleMap";
import Footer from "../../Footer/Footer";
import {BASE_URL} from "../../Constants";

const PublicResults = () => {
    const dispatch = useDispatch();
    const resultsState = useSelector((state) => state?.results);
    const {licenseID, deviceID, sessionID} = useParams();

    useEffect(() => {
        if (!resultsState?.breadcrumbs?.selectedSession) {
            dispatch(
                results({
                    breadcrumbs: {selectedSession: sessionID},
                })
            );
        }
    }, []);

    return (
        <div className="results">
            <header className="public-results-header">
                {resultsState?.user?.logo ? (
                    <img
                        src={`${BASE_URL}assets/images/user/company/${resultsState?.user?.logo}`}
                        height={70}
                        style={{margin: "10px"}}
                    />
                ) : (
                    ""
                )}
                <div className="p-3">
                    <strong>{resultsState?.user?.name}</strong>
                    <br />
                    <small>{resultsState?.user?.tagline}</small>
                </div>
            </header>

            <SystemInfo />
            <div className="google-map-container">
                <GoogleMap
                    title={resultsState?.user?.name}
                    address={resultsState?.user?.display_address}
                />
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default PublicResults;
