import "./notifications.css";
import {IoNotificationsSharp} from "react-icons/io5";
import React, {useState} from "react";
import HeaderDropDown from "../HeaderDropDown/HeaderDropDown";
import {global} from "../../../Redux/Actions/global";
import {useDispatch, useSelector} from "react-redux";

const Notifications = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global);
    const [notifCount, setNotifCount] = useState(0);

    const toggleNotificationsBody = () => {
        if (globalState?.header?.headerDropDownParent === "notifications") {
            dispatch(global({
                header: {
                    headerDropDownParent: "initial"
                }
            }))
            return;
        }
        dispatch(global({
            header: {
                headerDropDownParent: "notifications"
            }
        }))
    }

    const messagesBody = () => {
        if (globalState?.header?.headerDropDownParent !== "notifications") return <div />;
        return <HeaderDropDown text="No notifications yet!" />
    }

    const notificationsActive = () => {
        if (globalState?.header?.headerDropDownParent !== "notifications") return;
        return "notifications-active"
    }

    return (
        <div className={`notifications position-relative  ${notificationsActive()}`} onClick={toggleNotificationsBody}>
            <div className={`badge rounded-pill px-1 py-1 bg-danger ${notifCount === 0 ? "d-none" : ""} fw-light font-monospace position-absolute top-0 end-0`}>{notifCount}</div>
            <IoNotificationsSharp size="25" className="text-secondary position-absolute top-50 start-50 translate-middle" />

            {messagesBody()}
        </div>
    )
}

export default Notifications;