import React, {useState} from "react";
import "./feedback.css"
import {Editor} from "@tinymce/tinymce-react";
import {useDispatch, useSelector} from "react-redux";
import {global} from "../../../Redux/Actions/global";
import axios from "axios";
import {BASE_URL} from "../../Constants";
import {Spinner} from "react-bootstrap";

const baseURL = BASE_URL;
const phpRoute = "helpdesk-feedback"

const Feedback = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e, type) => {
        if (type === "subject") {
            dispatch(global({
                feedback: {
                    ...globalState?.feedback,
                    subject: e?.target?.value
                }
            }))
            return;
        }

        dispatch(global({
            feedback: {
                ...globalState?.feedback,
                message: e
            }
        }))
    };

    const sendFeedback = () => {
        console.log(globalState?.feedback)
        setLoading(true);
        if (!globalState?.feedback?.subject) {
            dispatch(global({
                alerts: [
                    ...globalState?.alerts ?? "",
                    {
                        id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                        type: "warning",
                        title: "Missing Information",
                        message:
                            <span>Please provide <b>subject</b> to proceed further</span>,
                        show: true,
                        dismissible: true,
                        date: new Date(),
                    }
                ]
            }))
            setLoading(false)
            return;
        }
        if (!globalState?.feedback?.message) {
            dispatch(global({
                alerts: [
                    ...globalState?.alerts ?? "",
                    {
                        id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                        type: "warning",
                        title: "Missing Information",
                        message:
                            <span>Please provide <b>message</b> to proceed further</span>,
                        show: true,
                        dismissible: true,
                        date: new Date(),
                    }
                ]
            }))
            setLoading(false)
            return;
        }

        axios({
            method: 'post',
            url: baseURL + phpRoute,
            data: JSON.stringify({
                subject: globalState?.feedback?.subject,
                message: globalState?.feedback?.message,
                user_id: loginState?.user_data?.id,
                application: "uxportal"
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => {
            dispatch(global({
                alerts: [
                    ...globalState?.alerts ?? "",
                    {
                        id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                        type: res?.data?.status,
                        title: "Missing Information",
                        message: res?.data?.errors && res?.data?.errors?.length !== 0 ? res?.data?.errors?.map((err, erri) =>
                                <div key={`feedback-sent-error-${erri}`}>
                                    {err}
                                </div>
                            ) :
                            <span>Feedback sent successfully</span>,
                        show: true,
                        dismissible: true,
                        date: new Date(),
                    }
                ],
                feedback: {
                    subject: res?.data?.status === "success" ? "" : globalState?.feedback?.subject,
                    message: res?.data?.status === "success" ? "" : globalState?.feedback?.message
                }
            }))
        }).finally(() => setLoading(false))
    }

    return (
        <div className="feedback">
            <div className="d-flex page-main-heading mb-2">
                <h2 className="m-0 mx-3 mt-1 text-uppercase">Feedback</h2>
            </div>
            <div className="px-3">
                <h5 className="feedback-main-h5">We will be glad to hear your opinion! :D</h5>
                <div>
                    <div className="mb-3">
                        <label htmlFor="subject" className="form-label feedback-subject">Subject</label>
                        <input type="text" className="form-control" value={globalState?.feedback?.subject || ''} id="subject" aria-describedby="subject" onChange={(e) => handleInputChange(e, "subject")} />
                        <div id="subject" className="form-text feedback-under-subject-text">Please first write subject what your message is about...</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text" className="form-label feedback-message">Message</label>
                        <Editor
                            apiKey="su5jgxxsk6pnmqs6e3vkg13vl5trlint8qpwtft3dg9gb1m4"
                            value={globalState?.feedback?.message || ''}
                            textareaName="description"
                            init={{
                                menubar: false,
                                skin: 'small',
                                icons: 'small',
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'bold italic underline strikethrough backcolor forecolor | removeformat | alignleft aligncenter alignright | bullist numlist outdent indent',
                                forced_root_block: ""
                            }}
                            onEditorChange={(e) => handleInputChange(e, "message")}
                            tagname="textarea"
                        />
                    </div>
                    <button onClick={sendFeedback} className="btn btn-primary" disabled={loading}>

                        {loading ?
                            <Spinner animation="grow" variant="light" size="sm" /> : ""}

                        Send Feedback

                        {loading ?
                            <Spinner animation="grow" variant="light" size="sm" /> : ""}

                    </button>

                </div>
            </div>
        </div>
    )
}

export default Feedback;