export const results = (resultsMenuData) => {
    return {
        type: 'RESULTS_MENU',
        data: resultsMenuData
    };
};

export const breadcrumbs = (breadcrumbsData) => {
    return {
        type: 'BREADCRUMBS',
        data: breadcrumbsData
    };
};

export const devices = (devicesData) => {
    return {
        type: 'PULL_DEVICE_DATA',
        data: devicesData
    };
};

export const reports = (devicesData) => {
    return {
        type: 'PULL_REPORTS_DATA',
        data: devicesData
    };
};

export const licenses = (resultData) => {
    return {
        type: 'LICENSES',
        data: resultData
    };
};

export const sessions = (sessionsData) => {
    return {
        type: 'PULL_SESSION_DATA',
        data: sessionsData
    };
};

export const report = (reportData) => {
    return {
        type: 'REPORT',
        data: reportData
    };
};