const globalReducer = (state = [], action) => {
    switch (action.type) {
        case 'GLOBAL':
            return {
                ...state, ...action.data
            }
        case 'ALERT':
            return {
                ...state, ...action.data
            }
        case 'PLACEHOLDER_RANDOMIZER':
            return {
                ...state, ...action.data
            }
        default:
            return state;
    }
};

export default globalReducer;