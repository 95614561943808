export const taskModifier = (taskModifier) => {
	return {
		type: 'MODIFY_TASK',
		data: taskModifier
	};
};

export const reorderTrackerTasks = (reorderData) => {
	return {
		type: 'REORDER_TRACKER_TASKS',
		data: reorderData
	};
};

export const updateTracker = (updateData) => {
	return {
		type: 'UPDATE_DATA',
		data: updateData
	};
};

export const initTracker = (initTrackerData) => {
	return {
		type: 'INITIAL_TRACKER_DATA',
		data: initTrackerData
	};
};

export const updateTask = (updateTask) => {
	return {
		type: 'UPDATE_TASK',
		data: updateTask
	};
};

export const deleteTask = (deleteTask) => {
	return {
		type: 'DELETE_TASK',
		data: deleteTask
	};
};

export const columnInputChange = (columnInputChange) => {
	return {
		type: "COLUMN_INPUT_CHANGE",
		data: columnInputChange
	}
}

export const columnNameChange = (columnNameChange) => {
	return {
		type: "COLUMN_NAME_CHANGE",
		data: columnNameChange
	}
}


export const addColumn = (addColumn) => {
	return {
		type: "ADD_COLUMN",
		data: addColumn
	}
}

export const deleteColumn = (deleteColumn) => {
	return {
		type: "DELETE_COLUMN",
		data: deleteColumn
	}
}

export const changePriority = (changePriority) => {
	return {
		type: "CHANGE_PRIORIRTY",
		data: changePriority
	}
}

export const makeEvent = (makeEvent) => {
	return {
		type: "MAKE_EVENT",
		data: makeEvent
	}
}