import React from "react";
import "./installed-hotfixes.css";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";
import {useSelector} from "react-redux";
import {getLocalStorageItem} from "../../../../Other/Helper";

const InstalledHotfixes = ({data}) => {

    const installedHotfixes = data?.software?.hotFixes;

    if (typeof installedHotfixes === "undefined") {
        return (
            <div className="system-info-page installed-hotfixes">
                <div className="system-overview installed-hotfixes-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">Installed Hotfixes</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <div className="system-info-page-content-block block-w100">
                            <div className="system-info-page-content-block-heading">
                                <ContentPreloader theme="danger" forContent="each" text="Please wait while Installed Hotfixes is being loaded..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="system-info-page installed-hotfixes">
            <div className="system-overview installed-hotfixes-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">Installed Hotfixes</div>
                </div>
                <div>
                    <div className="system-info-row position-relative text-orange">
                        <div className="drive-number-no-margin me-3 align-self-center system-info-th text-orange">
                            #
                        </div>
                        <div className="drive-number-no-margin device-in-loop-parts system-info-th font-italic">
                            <div className="text-orange">
                                ID
                            </div>
                            <div className="text-orange">
                                Description
                            </div>
                            <div className="text-orange">
                                Install Date
                            </div>
                        </div>
                    </div>
                    {installedHotfixes && Array.isArray(installedHotfixes) && installedHotfixes.map((hotfix, hotfixi) =>
                        <div className="system-info-row position-relative" key={hotfixi}>
                            <div className="drive-number align-self-center system-info-td">
                                {hotfixi + 1}
                            </div>
                            <div className="device-in-loop-parts system-info-td">
                                <div className="text-success">
                                    <span className="monospaced-value">{hotfix?.HotFixID}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{hotfix?.Description}</span>
                                </div>
                                <div className="text-orange">
                                    <span className="monospaced-value">{hotfix?.Date?.DateTime}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default InstalledHotfixes;
