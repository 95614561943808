import React from "react";
import "./header.css";
import {FaBars} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {global} from "../../Redux/Actions/global";
import Messages from "./Messages/Messages";
import Notifications from "./Notifications/Notifications";
import Avatar from "../Other/Avatar/Avatar";
import Theme from "./Theme/Theme";
import {useNavigate} from "react-router-dom";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const globalState = useSelector(state => state?.global)
    const loginState = useSelector(state => state?.login)

    const toggleAside = () => {
        dispatch(global({
            asideClosed: !globalState?.asideClosed
        }))
    }

    return (
        <header className="d-flex justify-content-between">
            <div onClick={toggleAside} className="aside-control align-self-center px-3">
                <FaBars size="20" />
            </div>

            <div className="d-flex gap-2 header-user-menu px-3">
                <div className="align-self-center">
                    <Theme />
                </div>

                <div className="align-self-center">
                    <Messages />
                </div>

                <div className="align-self-center">
                    <Notifications />
                </div>

                <div className="align-self-center" onClick={() => navigate("/settings")}>
                    <Avatar user={loginState?.user_data} showName={false} />
                </div>
            </div>
        </header>
    )
}

export default Header;