import React, {Component} from 'react';
import "../../Tracker/tracker.css"
import "./dash-task-priority-list.css";
import {connect} from "react-redux";
import axios from "axios";
import {BASE_URL} from "../../../Constants";
import {dashTaskPriorityList} from "../../../../Redux/Actions/dashboard";

const baseURL = BASE_URL;
const phpRoute = "tracker";

class DashTaskPriorityList extends Component {
    constructor() {
        super();
        this.state = {
            timeout: 0
        }
    }


    componentDidMount() {
        this.props.dispatch(dashTaskPriorityList({
            isLoading: true,
        }))

        let timeout = setTimeout(() => {
            axios({
                method: 'post',
                url: baseURL + phpRoute,
                data: JSON.stringify({
                    email: this.props?.login?.email
                }),
                headers: {'content-type': 'application/json'},
            })
                .then(res => {
                    this.props.dispatch(dashTaskPriorityList({
                        dashTasks: res?.data?.tasks
                    }))
                }).finally(() => {
                this.props.dispatch(dashTaskPriorityList({
                    isLoading: false
                }))
            })
        }, 1000)
        this.setState({
            ...this.state,
            timeout: timeout
        })
    }

    componentWillUnmount() {
        clearTimeout(this.state?.timeout);
    }

    priorityTasksLoader = () => {
        return [1, 2, 3, 4].map(item =>
            <div className="dash-task" key={item}>
                <div className="tracker-column-card border-danger">
                    <div className="tracker-column-card-scroll priority-task-vertical-scroller">
                        <div className="tracker-column-card-title placeholder placeholder-sm col-7" />
                        <p className="card-text placeholder-glow">
                            <span className="placeholder placeholder-sm col-10" />
                            <span className="placeholder placeholder-sm col-11" />
                            <span className="placeholder placeholder-sm col-6" />
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.props?.dashboard?.isLoading) {
            return (
                <div className="tracker tracker-priority-tasks mb-3">
                    <h5>Priority Tasks</h5>
                    <div className="horizontal-scroller-wrapper">
                        {this.priorityTasksLoader()}
                    </div>
                </div>
            )
        }
        if (this?.props?.dashboard?.dashTasks && Object.values(this?.props?.dashboard?.dashTasks)
            .filter(item => parseInt(item.priority_id) === 3)?.length === 0) {
            return (
                <div className="tracker tracker-priority-tasks mb-3">
                    <h5>Priority Tasks</h5>
                    <div className="horizontal-scroller-wrapper">
                        <div className="alert alert-dark flex-grow-1">
                            You don't have a priority tasks
                        </div>
                    </div>
                </div>
            )
        }

        if (this?.props?.dashboard?.dashTasks?.length === 0 || typeof this?.props?.dashboard?.dashTasks === "undefined") {
            return (
                <div className="alert alert-secondary">
                    You don't have priority tasks.
                </div>
            )
        }
        return (
            <div className="tracker tracker-priority-tasks mb-3">
                <h5>Priority Tasks</h5>
                <div className="horizontal-scroller-wrapper tracker-priority-tasks-wrapper">
                    {this?.props?.dashboard?.dashTasks && Object.values(this?.props?.dashboard?.dashTasks)
                        .filter(item => parseInt(item.priority_id) === 3)
                        .map((item, index) =>
                            <div className="dash-task" key={index}>
                                <div className="tracker-column-card border-danger">
                                    <div className="tracker-column-card-scroll priority-task-vertical-scroller">
                                        <div className="position-relative">
                                            <div className="task-datetime badge text-secondary">
                                                {this?.props?.task?.date_created}
                                            </div>
                                        </div>
                                        <div className="tracker-column-card-title">{item?.title}</div>
                                        <div dangerouslySetInnerHTML={{__html: item?.description}} />
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(DashTaskPriorityList);