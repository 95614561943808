const settingsReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_UPORTAL_SETTINGS':
            return {
                ...state, ...action.data
            }
        case 'UPDATE_TAGLINE':
            return {
                ...state, ...action.data
            }
        case 'UPDATE_LOGO':
            return {
                ...state, ...action.data
            }
        default:
            return state;
    }
};

export default settingsReducer;