import React from "react";
import "../system-info-pages.css";
import "./system-overview.css";
import {useSelector} from "react-redux";
import {FaCheck, FaTimes} from "react-icons/fa";
import {formatBytes} from "../../../../Other/Helper";
import {HiBolt, HiBoltSlash} from "react-icons/hi2";
import ContentPreloader from "../../../../Other/Preloaders/ContentPreloader";


const SystemOverview = ({data}) => {
    const hardware = data?.hardware;

    function identifyDDR(clockSpeed) {
        if (clockSpeed >= 800 && clockSpeed < 1600) {
            return "DDR3";
        } else if (clockSpeed >= 1600 && clockSpeed < 3200) {
            return "DDR3 / DDR4";
        } else if (clockSpeed >= 3200 && clockSpeed < 4800) {
            return "DDR4";
        } else if (clockSpeed >= 4800) {
            return "DDR5";
        } else {
            return "Unknown";
        }
    }

    const checkImage = (imageSrc, name) => {
        let http = new XMLHttpRequest();

        http.open('GET', imageSrc, false);
        http.send();
        let req = http?.response;
        //console.log(req.includes("svg"));

        return req.includes("svg") ?
            <img src={imageSrc} className="" alt="" /> : name;
    }

    function diskSpaces() {
        try {
            let diskLayout = hardware?.drive?.diskLayout;
            let blockDevices = hardware?.drive?.blockDevices;
            let fsSize = hardware?.drive?.fsSize;

            let usbDrives = diskLayout;//.filter(layout => layout?.interfaceType !== "USB");

            let usbDrivesBlockDevices = usbDrives.map(usbDrive => {
                return {
                    ...usbDrive,
                    blockDevices: blockDevices.filter(bld => bld?.device === usbDrive?.device)
                };
            });

            //console.log(usbDrivesBlockDevicesFsSize)
            return usbDrivesBlockDevices.map(udbd => {
                let blockDeviceFsSizes = udbd.blockDevices.map(blockDevice => {
                    return {
                        ...blockDevice,
                        fsSize: fsSize.find(fsz => fsz?.fs === blockDevice?.name)
                    };
                });

                return {
                    ...udbd,
                    blockDevicesFsSizes: blockDeviceFsSizes
                };
            });
        } catch (error) {
            //console.log(error)
        }
    }

    function renderPartitions(partitions) {
        return partitions?.map((partition) =>
            <div key={partition?.name} className="logical-drive position-relative mb-2 flex-grow-1">
                <b>{partition?.name}</b>
                <i className="mx-1">{partition?.label !== "" ? partition?.label : "(no label)"}</i>

                <div className="drive-format-type badge badge-orange fw-normal">
                    {partition?.physical} / <b>{partition?.fsType}</b>
                </div>

                <div className="progress">
                    <div className="progress-bar bg-orange" role="progressbar" style={{
                        width: `${partition?.fsSize?.use}%`
                    }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                    />
                </div>

                <div className="inner-ellipsis">
                    Used
                    <b className="ms-1 me-2 text-orange">{partition?.fsSize?.use} %
                    </b>

                    <b className="mx-1">{formatBytes(partition?.fsSize?.available)}</b>
                    free of
                    <b className="mx-1">{formatBytes(partition?.fsSize?.size)}</b>
                </div>
            </div>
        );
    }

    const drives = diskSpaces();

    function getDiagonalSizes(monitor) {
        const sizeX = monitor.sizeX;
        const sizeY = monitor.sizeY;
        const pixelDensity = Math.sqrt(sizeX * sizeX + sizeY * sizeY);
        const diagonalSizeInches = pixelDensity / monitor.pixelDepth;

        return diagonalSizeInches.toFixed(2);
    }

    function audioDeviceStatus(statusCode) {
        const statusMap = {
            1: "Other",
            2: "Unknown",
            3: "Running/Full Power",
            4: "Warning",
            5: "In Test",
            6: "Not Applicable",
            7: "Power Off",
            8: "Off Line",
            9: "Off Duty",
            10: "Degraded",
            11: "Not Installed",
            12: "Install Error",
            13: "Power Save - Unknown",
            14: "Power Save - Low Power Mode",
            15: "Power Save - Standby",
            16: "Power Cycle",
            17: "Power Save - Warning",
            18: "Paused",
            19: "Not Ready",
            20: "Not Configured",
            21: "Quiesced"
        };

        if (statusMap.hasOwnProperty(statusCode)) {
            return statusMap[statusCode];
        } else {
            return "Invalid status code";
        }
    }

    const systemOverview = hardware;

    if (typeof systemOverview === "undefined") {
        return (
            <div className="system-info-page">
                <div className="system-overview hardware-inventory-content">
                    <div className="system-info-page-heading mb-3">
                        <div className="align-self-center m-0">System Overview</div>
                    </div>
                    <div className="system-info-page-content no-data">
                        <ContentPreloader theme="danger" forContent="each" text="Please wait while System Overview is being loaded..." />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="system-info-page">
            <div className="system-overview hardware-inventory-content">
                <div className="system-info-page-heading mb-3">
                    <div className="align-self-center m-0">System Overview</div>
                </div>
                <div className="system-info-page-content">
                    <div className="system-info-page-content-block SMBIOS-info">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/system-icon.svg"} className="me-3" alt="" style={{filter: "sepia(20%)"}} />
                            </div>
                            <div>
                                <div className="text-orange">
                                    <div className="smbios-system-name">
                                        {hardware?.system?.system?.model}
                                        <div className="smbios-system-manufacturer">
                                            {/*by {*/} {/*checkImage(*/} {/*    `/assets/images/${hardware?.system?.system?.manufacturer?.toLowerCase()}-logo.svg`,*/} {/*    hardware?.system?.system?.manufacturer*/} {/*)}*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr />
                        <div>
                            <div>
                                <div className="system-info-td">
                                    <div className="text-orange">
                                        <b className="me-2">Family:</b>
                                        <span className="monospaced-value">{hardware?.system?.system?.model}</span>

                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">SKU:</b>
                                        <span className="monospaced-value">{hardware?.system?.system?.sku}</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">Serial Number:</b>
                                        <span className="monospaced-value">{hardware?.system?.system?.serial}</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">UUID:</b>
                                        <span className="monospaced-value">{hardware?.system?.system?.uuid}</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">Version:</b>
                                        <span className="monospaced-value">{hardware?.system?.system?.version}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="system-info-page-content-block">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/cpu.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Processor</div>
                                <b className="text-primary">{hardware?.cpu?.cpu?.brand}</b>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div>
                                <div className="system-info-td">
                                    <b className="cpu-name">{hardware?.cpu?.cpu?.brand}</b>
                                    <div className="text-orange">
                                        <b className="me-2">Manufacturer:</b>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.manufacturer}</span>
                                    </div>
                                    <div className="text-orange family-model-stepping">
                                        <b>Family</b>
                                        <b>Model</b>
                                        <b>Stepping</b>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.family}</span>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.model}</span>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.stepping}</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">Speed:</b>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.speed}GHz</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">Temperature:</b>
                                        <span className="monospaced-value">{hardware?.cpu?.cpuTemperature?.main}
                                            <sup>o</sup>C</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">Cores:</b>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.physicalCores}</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">Total Threads:</b>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.cores}</span>
                                    </div>
                                    <div className="text-orange">
                                        <b className="me-2">Virtualization:</b>
                                        <span className="monospaced-value">{hardware?.cpu?.cpu?.virtualization ?
                                            <FaCheck className="text-success" /> :
                                            <FaTimes className="text-danger" />}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="system-info-page-content-block">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/mainboard.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Motherboard</div>
                                <b className="text-info">
                                    {hardware?.system?.baseboard?.manufacturer}
                                </b>
                            </div>
                        </div>
                        <hr />

                        <div className="mainboard-name">
                            <b>{hardware?.system?.baseboard?.manufacturer}</b>
                        </div>

                        <div className="text-orange">
                            <div>
                                <b className="me-2">Model:</b>
                                <span className="monospaced-value">{hardware?.system?.baseboard?.model}</span>
                            </div>
                            <div>
                                <b className="me-2">Version:</b>
                                <span className="monospaced-value">{hardware?.system?.baseboard?.version}</span>
                            </div>
                            <div>
                                <b className="me-2">Part Number:</b>
                                <span className="monospaced-value">{hardware?.system?.baseboard?.manufacturer}</span>
                            </div>
                            <div>
                                <b className="me-2">Serial Number:</b>
                                <span className="monospaced-value">{hardware?.system?.baseboard?.serial}</span>
                            </div>
                            <div>
                                <b className="me-2">BIOS Manufacturer:</b>
                                <span className="monospaced-value">{hardware?.system?.bios?.vendor}</span>
                            </div>
                            <div>
                                <b className="me-2">
                                    BIOS Version:
                                </b>
                                <span className="monospaced-value">{hardware?.system?.bios?.version}</span>
                            </div>
                            <div>
                                <b className="me-2">
                                    EXTENDED BIOS Version:
                                </b>
                                <span className="monospaced-value">{hardware?.system?.bios?.version} {hardware?.system?.bios?.vendor}</span>
                            </div>
                            <div>
                                <b className="me-2">
                                    BIOS Date:
                                </b>
                                <span className="monospaced-value">{hardware?.system?.bios?.releaseDate}</span>
                            </div>
                            <div>
                                <b className="me-2">
                                    Memory Slots:
                                </b>
                                <span className="monospaced-value">{hardware?.system?.baseboard?.memSlots}</span>
                            </div>
                        </div>
                    </div>
                    <div className="system-info-page-content-block block-w100">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/gpu.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Graphics</div>
                                <b className="text-success">Found {hardware?.gpu?.controllers?.length} GPU(s)</b>
                            </div>
                        </div>
                        <hr />
                        <div className="devices-loop">
                            {hardware?.gpu?.controllers && hardware?.gpu?.controllers.map((gpu, igpu) =>
                                <div key={`gpu-${igpu}`} className="device-in-loop">
                                    <div className="system-info-row drive-row position-relative border-orange">
                                        <div className="drive-number align-self-center">
                                            {igpu + 1}
                                        </div>
                                        <div>
                                            <div className="gpu-name">
                                                <b className="me-2">{gpu?.model}</b>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Vendor:</b>
                                                <span className="monospaced-value">{gpu?.vendor}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Memory:</b>
                                                <span className="monospaced-value">{formatBytes(gpu?.vram, "MB")}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Sub Device ID:</b>
                                                <span className="monospaced-value">{gpu?.subDeviceId}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Driver Version:</b>
                                                <span className="monospaced-value">{gpu?.driverVersion ?? "N/A"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="system-info-page-content-block block-w100">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/ram.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Memory Modules</div>
                                <b className="text-success">
                                    Found {hardware?.memory?.memLayout?.length} Memory Module(s)
                                </b>
                            </div>
                            <div className="text-right text-orange small">
                                <div>
                                    <b className="me-2">Total Memory Slots:</b>
                                    {hardware?.system?.baseboard?.memSlots}
                                </div>
                                <div>
                                    <b className="me-2">Total Memory:</b>
                                    {formatBytes(hardware?.memory?.mem?.total)}
                                </div>
                                <div>
                                    <b className="me-2">Free Memory:</b>
                                    {formatBytes(hardware?.memory?.mem?.free)}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="devices-loop">
                            {hardware?.memory?.memLayout?.map((physMem, physMemi) =>
                                <div key={physMemi} className="device-in-loop">
                                    <div className="system-info-row drive-row position-relative border-orange" key={physMemi}>
                                        <div className="drive-number align-self-center">
                                            {physMemi + 1}
                                        </div>
                                        <div>
                                            {/*<OverlayTrigger*/} {/*    key="left"*/} {/*    placement="left"*/} {/*    overlay={*/} {/*        <Tooltip id={`smbios-ram-tooltip`}>*/} {/*            <div className="text text-left">*/} {/*                {SMBIOSRam(physMem?.DEVICE_LOCATOR) && Object.entries(SMBIOSRam(physMem?.DEVICE_LOCATOR))?.map(([ramKey, ramValue]) =>*/} {/*                    <div key={ramKey} className="d-flex justify-content-between">*/} {/*                        <b className="text-capitalize fw-normal ram-hover-raw-data-names">*/} {/*                            {fixRamKey(ramKey)}:*/} {/*                        </b>*/} {/*                        <span className="fw-light ms-1">*/} {/*                            {ramValue}*/} {/*                        </span>*/} {/*                    </div>*/} {/*                )}*/} {/*            </div>*/} {/*        </Tooltip>*/} {/*    }*/} {/*>*/} {/*    <div className="ram-hover-raw-data btn btn-sm">*/} {/*        Extended Data*/} {/*    </div>*/} {/*</OverlayTrigger>*/}

                                            <div className="drive-model">
                                                <b className="me-2">{physMem?.manufacturer}</b>
                                                <i className="text-orange">{physMem?.clockSpeed}MHz</i>
                                            </div>
                                            <div>
                                                {formatBytes(physMem?.size)}
                                                <span className="badge bg-warning ms-2">
                                                    {identifyDDR(physMem?.clockSpeed)}
                                                </span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Part Number:</b>
                                                <span className="monospaced-value">{physMem?.partNum}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Serial Number:</b>
                                                <span className="monospaced-value">{physMem?.serialNum}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="system-info-page-content-block block-w100">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/hdd.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Drives</div>
                                <b className="text-success">
                                    Found {drives?.length} Drive(s)
                                </b>
                            </div>
                        </div>
                        <hr />
                        <div className="devices-loop">
                            {drives && drives?.map((physDrive, physDrivei) =>
                                <div key={physDrivei} className="device-in-loop">
                                    <div className="system-info-row drive-row position-relative border-orange" key={physDrivei}>
                                        <div className="drive-number align-self-center">
                                            {physDrivei + 1}
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="inner-ellipsis">
                                                <b>{physDrive?.name}</b>
                                                <span className="badge bg-primary fw-normal ms-2">
                                                    {physDrive?.interfaceType}
                                                </span>
                                                <span className="badge bg-secondary fw-normal ms-2">
                                                    SMART: {physDrive?.smartStatus}
                                                </span>
                                            </div>
                                            <div className="text-orange drive-size">
                                                {formatBytes(physDrive?.size)}
                                                <small>Formatted</small>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Serial Number:</b>
                                                <span className="monospaced-value">{physDrive?.serialNum !== "" ? physDrive?.serialNum :
                                                    <i>(no serial)</i>}</span>
                                            </div>
                                            <div className="text-orange inner-ellipsis">
                                                <b className="me-2">Firmware Revision:</b>
                                                <span className="monospaced-value">{physDrive?.firmwareRevision}</span>
                                            </div>
                                            <div className="disk-partitions d-flex">
                                                {renderPartitions(physDrive?.blockDevicesFsSizes)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="system-info-page-content-block block-w100">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/monitor.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Monitor</div>
                                <b className="text-success">Found {(hardware?.gpu?.displays && hardware?.gpu?.displays?.length) ?? 0} Monitor(s)</b>
                            </div>
                        </div>
                        <hr />
                        <div className="devices-loop">
                            {hardware?.gpu?.displays && hardware?.gpu?.displays.map((monitor, monitori) =>
                                <div key={monitor?.deviceName} className="device-in-loop">
                                    <div className="system-info-row drive-row position-relative border-orange">
                                        <div className="drive-number align-self-center">
                                            {monitori + 1}
                                        </div>
                                        <div>
                                            <div className="install-date badge badge-orange fw-normal text-orange">
                                                {monitor?.IS_PRIMARY}
                                            </div>
                                            <div className="drive-model">
                                                <b className="me-2">{monitor?.vendor} {monitor?.model}</b>
                                                <span className="badge badge-secondary fw-normal">{monitor?.IMAGE_RUNTIME_VERSION}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Diagonal Size (inch):</b>
                                                <span className="monospaced-value">{getDiagonalSizes(monitor)}"</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Resolution:</b>
                                                <span className="monospaced-value">{monitor?.resolutionX}x{monitor?.resolutionY}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="system-info-page-content-block block-w100">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/network.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Network Adapter(s)</div>
                                <b className="text-success">Found {(hardware?.network?.networkInterfaces && hardware?.network?.networkInterfaces?.length) ?? 0} Network Adapter(s)</b>
                            </div>
                        </div>
                        <hr />
                        <div className="devices-loop">
                            {hardware?.network?.networkInterfaces && hardware?.network?.networkInterfaces?.filter((network) => network?.mac !== "00:00:00:00:00:00")?.map((network, networki) =>
                                <div key={network?.ifaceName} className={`device-in-loop`}>
                                    <div className="system-info-row drive-row position-relative border-orange">
                                        <div className="drive-number align-self-center">
                                            {networki + 1}
                                        </div>
                                        <div>
                                            <div className="install-date badge badge-orange fw-normal text-orange">
                                                {network?.operstate}
                                            </div>
                                            <div className="drive-model">
                                                <b className="me-2">{network?.ifaceName}</b>
                                                <span className="badge bg-secondary fw-normal">{network?.iface}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Physical Address (MAC):</b>
                                                <span className="monospaced-value">{network?.mac}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Speed:</b>
                                                <span className="monospaced-value">{network?.speed}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="system-info-page-content-block block-w100">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/sound.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Audio Devices(s)</div>
                                <b className="text-success">Found {(hardware?.audio && hardware?.audio?.length) ?? 0} Audio Device(s)</b>
                            </div>
                        </div>
                        <hr />
                        <div className="devices-loop">
                            {hardware?.audio && hardware?.audio?.map((sound, soundi) =>
                                <div key={sound?.id} className="device-in-loop">
                                    <div className="system-info-row drive-row position-relative border-orange">
                                        <div className="drive-number align-self-center">
                                            {soundi + 1}
                                        </div>
                                        <div>
                                            <div className="install-date badge badge-orange fw-normal text-orange">
                                                {audioDeviceStatus(sound?.status)}
                                            </div>
                                            <div className="drive-model">
                                                <b className="me-2">{sound?.name}</b>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Device ID:</b>
                                                <span className="monospaced-value">{sound?.id}</span>
                                            </div>
                                            <div className="text-orange">
                                                <b className="me-2">Manufacturer:</b>
                                                <span className="monospaced-value">{sound?.manufacturer}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="system-info-page-content-block block-w100">
                        <div className="system-info-page-content-block-heading">
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/battery.svg"} className="me-3" alt="" />
                            </div>
                            <div>
                                <div className="h22">Battery</div>
                                <b className="text-success">{hardware?.battery?.hasBattery ? "Battery Powered Device" : "No Battery"}</b>
                            </div>
                        </div>
                        <hr />
                        <div className="devices-loop">
                            <div className="device-in-loop">
                                <div className="system-info-row drive-row position-relative border-orange">
                                    <div className="drive-number align-self-center">
                                        {hardware?.battery?.isCharging ?
                                            <HiBolt /> :
                                            <HiBoltSlash />}
                                    </div>
                                    <div>
                                        <div className="install-date badge badge-orange fw-normal text-orange">
                                            {hardware?.battery?.serial}
                                        </div>
                                        <div className="drive-model">
                                            <b className="me-2">Current Status: {hardware?.battery?.percent}%</b>
                                        </div>
                                        <div className="text-orange">
                                            <b className="me-2">Model:</b>
                                            <span className="monospaced-value">{hardware?.battery?.model}</span>
                                        </div>
                                        <div className="text-orange">
                                            <b className="me-2">Manufacturer:</b>
                                            <span className="monospaced-value">{hardware?.battery?.manufacturer}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SystemOverview;