import React, {useEffect, useState} from "react";
import "./login.css";
import {Link, useLocation} from "react-router-dom";
import {GiEnvelope} from "react-icons/gi";
import {BsPhoneVibrateFill} from "react-icons/bs";
import {BASE_URL} from "../../Constants";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../../Redux/Actions/login";
import axios from "axios";
import {HandleInputs} from "../../Other/Other";
import {Spinner} from "react-bootstrap";
import axiosRetry from "axios-retry";
import {global, placeholderRandomizer} from "../../../Redux/Actions/global";

const baseURL = BASE_URL;
const phpRoute = "login/";

const loginRetries = 10;
const Login = () => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state?.global);
    const loginState = useSelector(state => state?.login);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const settingState = useSelector(state => state?.settings);
    const location = useLocation();
    const [retried, setRetried] = useState(0);

    useEffect(() => {
        let localData = JSON.parse(localStorage.getItem("loginData"));
        if (localData !== null) return;
        dispatch(login({
            currentInputs: {
                ...loginState?.currentInputs,
                remember: {
                    name: "remember",
                    value: "checked",
                    valid: true
                }
            }
        }))
    }, []); //Init Login Page
    useEffect(() => {
        //console.log(retried)
        if (retried === 5) {
            loginTimoutMiddleInfoMessage();
        }
    }, [retried]); //Middle of retried timeout
    useEffect(() => {
        dispatch(login(data))
        setLoading(false);
    }, [data]);
    useEffect(() => {
        let localData = JSON.parse(localStorage.getItem("loginData"));
        if (localData === null) return;
        axios({
            method: 'post',
            url: baseURL + phpRoute + "?lang=en",
            data: JSON.stringify({
                type: "portal-middleware",
                email: localData?.email,
                password: localData?.hash,
            }),
            headers: {'content-type': 'application/json'},
        }).then(res => {
            if (loginState?.logged_in === false) return; //Not return on undefined
            if (res?.data?.logged_in === loginState?.logged_in) return;
            if (globalState?.trigger === "logout") return;
            dispatch(login(res?.data))
        })
            .catch(err => console.log(err))
            .finally(() => {

            })
        window.scrollTo(0, 0);
    }, [location, settingState]);

    useEffect(() => {
        if (typeof loginState?.logged_in === "undefined") return;
        if (!loginState?.logged_in) {
            localStorage.removeItem("loginData");
            return;
        }
        if (!loginState?.currentInputs?.remember) return;
        if (!loginState?.currentInputs?.remember?.valid) return;
        localStorage.setItem("loginData", JSON.stringify(loginState));
    }, [loginState?.logged_in]) // Set login data into Local Stroage

    // localStorage.setItem("loginData", JSON.stringify(loginState))
    const signIn = (e) => {
        e.preventDefault();
        setLoading(true)
        axiosRetry(axios, {
            retries: loginRetries,
            shouldResetTimeout: true,
            retryCondition: (_error) => true, // retry no matter what
            retryDelay: (retryDelay) => {
                setRetried(retried => retried + 1)
                return retryDelay * 1000;
            }
        });
        axios({
                url: baseURL + phpRoute,
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                data: JSON.stringify({
                    type: "login",
                    email: loginState?.currentInputs?.email?.value,
                    password: loginState?.currentInputs?.password?.value
                })
            }
        ).then(res => {
            if (res?.data?.logged_in) {
                loginSuccessMessage();
            } else {
                loginErrorMessage();
            }
            setData(res?.data)
        }).catch((err) => {
            loginTimeoutErrorMessage();
            setLoading(false);
            //console.error("Can not login: ", err);
        })
    } //Login on with form
    const loginTimeoutErrorMessage = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "danger",
                    title: "Authentication",
                    message: "There is a problem with either the internet or the server. Please try again later...",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }
    const loginTimoutMiddleInfoMessage = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "info",
                    title: "Authentication",
                    message: "There appears to be an issue. I'm working on it, please wait...",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }
    const loginSuccessMessage = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "success",
                    title: "Authentication",
                    message: "You logged in successfully",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }
    const loginErrorMessage = () => {
        dispatch(global({
            alerts: [
                ...globalState?.alerts ?? "",
                {
                    id: globalState?.alerts ? globalState?.alerts[globalState?.alerts?.length - 1]?.id + 1 : 1,
                    type: "danger",
                    title: "Authentication",
                    message: "E-mail or password is incorrect",
                    show: true,
                    dismissible: true,
                    date: new Date(),
                }
            ]
        }))
    }
    const handleInput = (e) => {
        dispatch(login({
            currentInputs: {
                ...loginState?.currentInputs,
                [e?.target?.name]: HandleInputs(e)
            }
        }))
    }

    //if (typeof loginState?.currentInputs === "undefined") return <div></div>
    if (loginState?.logged_in) return <div className="login-empty-container"></div>

    return (
        <form onSubmit={signIn} className="login-page">
            <div className="login-page-form-wrapper">
                <div className="login-form-left" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/flux-gamess-pc-setup-image.png)`}}>
                    <div className="flux-gamess-credits">Photo by FluxGamess</div>
                </div>
                <div className="login-form-right">
                    <div className="login-form-heading d-flex justify-content-between mb-3">
                        <h2>Sign In</h2>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email-input" className="form-label">Email address</label>
                        <input type="email" name="email" className="form-control" id="email-input" aria-describedby="emailInput" onChange={e => handleInput(e)} />
                        <div id="emailInput" className="form-text">We'll never share your email with anyone else.</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password-input" className="form-label">Password</label>
                        <input type="password" name="password" className="form-control" id="password-input" onChange={e => handleInput(e)} />
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" name="remember" className="form-check-input" id="remember-me" onChange={e => handleInput(e)} checked disabled />
                        <label className="form-check-label" htmlFor="remember-me">Remember Me</label>
                        <div>
                            <small className="fst-italic text-secondary">System will automatically remember you until logout.</small>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary w-100">
                        {loading ?
                            <Spinner animation="grow" variant="light" size="sm" /> : ""} Sign In {loading ?
                        <Spinner animation="grow" variant="light" size="sm" /> : ""}
                    </button>
                    <div className="text-center my-3">
                        <b>- OR -</b>
                    </div>
                    <div className="login-contact-us d-flex gap-3">
                        <a href="https://uxd.com/contact" target="_blank" className="btn btn-warning"
                        >Contact Us by <GiEnvelope className="ms-1" size="20" />
                        </a>
                        <a href="https://uxd.com/contact" target="_blank" className="btn btn-danger">Contact Us by <BsPhoneVibrateFill className="ms-1" size="20" />
                        </a>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Login;