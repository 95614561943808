import React, {useState} from 'react';
import "./download-qr.css"
import QRCodeSVG from 'qrcode.react';
import {Button, Modal} from "react-bootstrap";
import {ImQrcode} from "react-icons/im";
import {FaDownload} from "react-icons/fa";


function DownloadQr({hash}) {
    const [modalShow, setModalShow] = React.useState(false);


    function MyVerticallyCenteredModal(props) {

        return (
            <Modal{...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  dialogClassName="qr-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"> Download a QR code </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id={`DownloadQr-${hash}`}>
                        <QRCodeSVG
                            value={`https://portal.uxd.com/public-results/${hash}/system-overview`}
                            renderAs="svg"
                            imageSettings={{
                                src: "https://uxdcloud.com/api/assets/images/logo/solid-border-light.svg",
                                width: 37,
                                height: 25,
                            }}
                            level="M"
                            bgColor="#ffffff"
                            fgColor="#444444"
                        />
                        <br />

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-success"
                        value=""
                        onClick={downloadQRCode}
                    >

                        <FaDownload /> Download
                    </button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    const downloadQRCode = () => {
        const svg = document.getElementById("DownloadQr-" + hash).getElementsByTagName('svg')[0];

        function copyStylesInline(destinationNode, sourceNode) {
            let containerElements = ["svg", "g"];
            for (let cd = 0; cd < destinationNode?.childNodes?.length; cd++) {
                let child = destinationNode?.childNodes[cd];
                if (containerElements.indexOf(child?.tagName) !== -1) {
                    copyStylesInline(child, sourceNode?.childNodes[cd]);
                    continue;
                }
                let style = sourceNode?.childNodes[cd]?.currentStyle || window?.getComputedStyle(sourceNode?.childNodes[cd]);
                if (style === "undefined" || style == null) continue;
                for (let st = 0; st < style.length; st++) {
                    child.style.setProperty(style[st], style.getPropertyValue(style[st]));
                }
            }
        }

        function triggerDownload(imgURI, fileName) {
            let evt = new MouseEvent("click", {
                view: window,
                bubbles: false,
                cancelable: true
            });
            let a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("href", imgURI);
            a.setAttribute("target", '_blank');
            a.dispatchEvent(evt);
        }

        function downloadSvg(svg, fileName) {
            console.log(svg)
            let copy = svg.cloneNode(true);
            copyStylesInline(copy, svg);
            let canvas = document.createElement("canvas");
            let bbox = svg.getBBox();
            // canvas.width = bbox.width;
            // canvas.height = bbox.height;
            canvas.width = 128;
            canvas.height = 128;
            let ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, bbox.width, bbox.height);
            let data = (new XMLSerializer()).serializeToString(copy);
            let DOMURL = window.URL || window.webkitURL || window;
            let img = new Image();
            let svgBlob = new Blob([data], {type: "image/svg+xml;charset=utf-8"});
            let url = DOMURL.createObjectURL(svgBlob);
            img.onload = function () {
                ctx.drawImage(img, 0, 0);
                DOMURL.revokeObjectURL(url);
                if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
                    let blob = canvas.msToBlob();
                    navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    let imgURI = canvas
                        .toDataURL("image/png")
                        .replace("image/png", "image/octet-stream");
                    triggerDownload(imgURI, fileName);
                }
                //document.removeChild(canvas);
            };
            img.src = url;
        }

        downloadSvg(svg, hash + ".png")
    }

    return (
        <div>
            <div className="qr-clickable" onClick={() => setModalShow(true)}>
                <ImQrcode />
            </div>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
}

export default DownloadQr;
